/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/fontawesome-free-solid"; // Added faEdit icon for the edit button
import { Entry } from "../../redux/entrySlice";
import styles from "./editCouponPopUp.module.scss";
import { TableContext } from "../../contexts/tableContext";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { Image } from "react-bootstrap";
import DateRangeFilter from "../../components/DateRangeFilter/DateRangeFilter";
import NumberInput from "../../components/NumberBox/NumberBox";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import togglestyles from "../../components/ToggleButton/ToggleButton.module.scss";

const EditCouponPopUp = React.memo(
  ({ couponId, handleClose, setIsUpdated }) => {
    const [submitting, setSubmitting] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // New state to track edit mode
    const { formthemeStyle, bodyheader } = useContext(TableContext);
    const { errormsgStyle } = useContext(TableContext);
    const [coupon, setCoupon] = useState([]);
    const [error, setError] = useState(null);
    const [roles, setRoles] = useState([]);
    const dispatch = useDispatch();
    const { files } = useContext(TableContext);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isOff, setIsOff] = useState();
    const [loading, setLoading] = useState(true);

    const RegularExp = /^[a-zA-Z0-9]*$/;

    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: "selection",
      },
    ]);

    const handleOnChange = (ranges) => {
      const { selection } = ranges;
      handleDateRangeChange(selection);
      setState([selection]);
    };

    const handleButtonClick = () => {
      setIsOff(!isOff);
    };

    const handleDateRangeChange = (selection) => {
      console.log("Selected Date Range:", selection);
      setStartDate(
        selection.startDate ? selection.startDate.toISOString() : null
      );
      setEndDate(selection.endDate ? selection.endDate.toISOString() : null);
      setOpen(false);
    };

    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      reset,
    } = useForm();

    useEffect(() => {
      setLoading(true);
      const url2 = REACT_APP_API_ROLES_DROPDOWN;
      fetch(url2)
        .then((resp) => resp.json())
        .then((resp) => {
          setRoles(resp.data.role);
        });

      const actionData = {
        actionUrl: `coupons/${couponId}`,
        actionMethod: "get",
      };
      dispatch(Entry(actionData)).then((resp) => {
        if (resp.payload && resp.payload.data) {
          setCoupon(resp.payload.data);
          console.log("data: ", resp.payload.data);
          reset(resp.payload.data);
          setIsOff(resp?.payload?.data?.oneTime);
          setStartDate(
            resp.payload.data.startDate ? resp.payload.data.startDate : null
          );
          setEndDate(
            resp.payload.data.endDate ? resp.payload.data.endDate : null
          );
          // console.log(
          //   "dateerr",
          //   new Date(resp.payload.data.startDate),
          //   "\n datenderr",
          //   new Date(resp.payload.data.endDate)
          // );
          if (resp.payload.data.startDate && resp.payload.data.endDate) {
            setState([
              {
                startDate: new Date(resp.payload.data.startDate),
                endDate: new Date(resp.payload.data.endDate),
                key: "selection",
              },
            ]);
          }
          setLoading(false);
        } else {
          console.error("coupon data not found in response:", resp);
          setCoupon(null);
        }
        setLoading(false);
      });
    }, [couponId]);

    const discountValidation = () => {
      const discount = parseFloat(getValues("discount"));
      console.log("discount", discount);
      if (discount < 0 || discount > 100) {
        return "Discount must be between 0 and 100";
      }
    };

    const onSubmitdata = (data) => {
      if (data.name.trim().length === 0) {
        document.getElementById("name").focus();
      } else {
        setSubmitting(true);
        setError(null);
        const apiData = {
          name: data.name,
          couponCode: data.couponCode.toUpperCase(),
          // description: data.description,
          startDate: startDate ? new Date(startDate).toISOString() : null,
          endDate: endDate ? new Date(endDate).toISOString() : null,
          discount: data.discount,
          oneTime: isOff,
          // limit: data.limit ? data.limit : null,
        };
        data.actionUrl = `coupons/${couponId}`;
        data.actionMethod = "patch";
        data.apiData = apiData;
        console.log("apiData: " + JSON.stringify(data.apiData));
        dispatch(Entry(data)).then((resp) => {
          setSubmitting(false);
          if (resp.payload.code === 200) {
            toast.success("coupon updated successfully");
            setIsEditMode(false);
            handleClose();
            setIsUpdated(true);
          } else {
            setError(resp.payload.message);
            toast.error("Error updating coupon");
          }
        });
      }
    };

    console.log("start datee---", startDate, endDate);

    return (
      <div className={styles.popupbox}>
        <div className={styles.box} id={styles.box}>
          {/* Flex container for close button, heading, and edit button */}
          <div className="d-flex align-items-center gap-3">
            <button
              className={styles.closeicon}
              aria-label="Close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {/* Conditionally render the heading based on isEditMode */}
            <h2 className={styles.heading}>
              {isEditMode ? "Edit Coupon Details" : "Coupon Details"}
            </h2>

            {/* Show edit button only when not in edit mode */}
            {!isEditMode && (
              <button
                className={`${styles.formbtnedit}`} // Add your custom class for styling
                onClick={() => setIsEditMode(true)}
                aria-label=""
              >
                <FontAwesomeIcon icon={faEdit} /> {/* Edit icon */}
                Edit
              </button>
            )}
          </div>

          <div className="container-fluid mt-5">
            {open && (
              <div className="datepicker-wrapper position-relative">
                <button
                  className="btn-close custom-close"
                  onClick={() => setOpen(false)}
                  aria-label="Close"
                ></button>
                <div className="datepicker-container">
                  <DateRangePicker
                    onChange={handleOnChange}
                    showSelectionPreview
                    moveRangeOnFirstSelection={true}
                    months={2}
                    ranges={state}
                    minDate={new Date()}
                    direction="horizontal"
                    dateDisplayFormat="yyyy-MM-dd"
                    staticRanges={[]}
                    inputRanges={[]}
                  />
                </div>
              </div>
            )}

            {loading ? (
              <div className="text-center mt-5">
                <SpinnerDotted size={50} color="#39979d" />
                <p>Loading user data...</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Coupon Name"
                      maximLength={30}
                      register={register}
                      errors={errors}
                      readOnly={!isEditMode}
                      isRequired
                      mandatory
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Coupon Code"
                      fieldName="couponCode"
                      placeHolder="Enter Coupon Code"
                      register={register}
                      maximLength={20}
                      RegularExp={RegularExp}
                      errors={errors}
                      readOnly={!isEditMode}
                      isRequired
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <NumberInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Discount(%)"
                      placeHolder="Enter Discount"
                      fieldName="discount"
                      validation={discountValidation}
                      register={register}
                      errors={errors}
                      readOnly={!isEditMode}
                      isRequired
                      // mandatory
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label className={styles.formlabel}>Single Use</label>
                    <div className="col">
                      <label className={togglestyles.switch}>
                        <input
                          type="checkbox"
                          checked={isOff}
                          onClick={handleButtonClick}
                          disabled={!isEditMode}
                        />
                        <span className={togglestyles.slider} />
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Discount Limit"
                    placeHolder="Enter Limit"
                    fieldName="limit"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                    // isRequired
                    // mandatory
                  />
                </div> */}
                </div>

                <div className="row mt-3">
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                  <label
                    htmlFor="coupondescription"
                    className={styles.formlabel}
                  >
                    Description
                    <span style={{color:"red", marginLeft: '3px'}}>*</span>
                  </label>
                  <textarea
                    id="coupondescription"
                    className={`${styles.inputbox}`}
                    placeholder="Enter Description"
                    {...register("description", {
                      required: "Description is required.",
                    })}
                    rows={1}
                    readOnly={!isEditMode}
                    disabled={!isEditMode}
                    style={{ resize: "none", overflowY: "auto" }}
                    onInput={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = `${Math.min(
                        e.target.scrollHeight,
                        200
                      )}px`;
                    }}
                  />
                {errors.description?.message && (
                  <span className={styles.errormsg}>{errors.description.message}</span>
                )}
                </div> */}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <DateRangeFilter
                      onChange={handleDateRangeChange}
                      open={open}
                      setOpen={setOpen}
                      register={register}
                      setValue={setValue}
                      disabled={!isEditMode}
                      startDateProp={startDate}
                      endDateProp={endDate}
                    />
                  </div>
                </div>
                {isEditMode && (
                  <div className="d-flex justify-content-center mt-3">
                    <input
                      className={styles.formbtn}
                      type="submit"
                      disabled={submitting}
                      value={submitting ? "Please wait.." : "Submit"}
                    />
                    {/* <input
                      className={styles.resetbtn}
                      type="button"
                      value="Reset"
                      onClick={() => {
                        reset();
                      }}
                    /> */}
                  </div>
                )}
                <br />
                {error && (
                  <h6
                    className={styles[errormsgStyle]}
                    style={{ float: "right" }}
                  >
                    {error}
                  </h6>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default EditCouponPopUp;
