import React, { Suspense, lazy } from "react";
import { SpinnerDotted } from "spinners-react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Outerlayout from "../layouts/outerLayout";
import Innerlayout from "../layouts/innerLayout";
import { getCookies, removeCookies } from "../hooks/useCookies";
import Settings from "../pages/settings/settings";
import ViewUsers from "../pages/users/viewUser";

import ViewVendors from "../pages/vendors/viewUser";
import ViewCafes from "../pages/cafes/viewCafe";
import ViewFeedback from "../pages/feedback/viewFeedback";
import ViewBank from "../pages/approveBank/viewBank";
import ViewPayoutRequests from "../pages/payoutRequests/viewPayoutRequests";

import UserEdit from "../pages/users/editUser";
import AdminUserEdit from "../pages/adminusers/editUser";

// import UserIndex from '../pages/users/index';
import UserCreate from "../pages/users/createUser";
import AdminUserCreate from "../pages/adminusers/createUser";

import VendorCreate from "../pages/vendors/createUser";
import CafeCreate from "../pages/cafes/createCafe";

import Preferences from "../pages/settings/preferences";
import CreateRoles from "../pages/roles/createRoles";
import RolesIndex from "../pages/roles/index";
import EditRoles from "../pages/roles/editRoles";
import SettingsApi from "../pages/settings/SettingsApiPage";
import SettingsLayout from "../pages/settings/settingsLayout";
import PageNotFound from "../pages/404/pageNotFound";
import PermissionDenied from "../pages/permission/permission";
import ProfileLayout from "../pages/profile/profileLayout";

const Login = lazy(() => import("../pages/logIn/logIn"));
const UserIndex = lazy(() => import("../pages/users/index"));
const AdminUserIndex = lazy(() => import("../pages/adminusers/index"));

const UserVerification = lazy(() => import("../pages/verify/userVerfication"));
const Register = lazy(() => import("../pages/register/register"));
const Forgetpassword = lazy(() => import("../pages/passwords/forgetPassword"));
const EmployeeIndex = lazy(() => import("../pages/employees/index"));
const EmployeeCreate = lazy(() => import("../pages/employees/create"));
const EmployeeEdit = lazy(() => import("../pages/employees/edit"));
const ResetPassword = lazy(() => import("../pages/passwords/resetPassword"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
// const ChatContainer = lazy(() => import('../pages/chatContainer/chatContainer'));
const HooksTest = lazy(() => import("../components/hooksFormTest"));
const CustomHooksTest = lazy(() => import("../hooks/customHooksTest"));
const MyForm = lazy(() =>
  import("../components/PhoneNumberBox/PhoneNumberBox")
);
const AgGridTable = lazy(() => import("../components/AgTable/AgGridTable"));
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import("../pages/profile/profilepage"));
const ChangePassword = lazy(() => import("../pages/profile/changePassword"));
const ProfileEdit = lazy(() => import("../pages/profile/profileEdits"));
const Faq = lazy(() => import("../cms/faq"));
const ViewEmployee = lazy(() => import("../pages/employees/view"));
const ViewRole = lazy(() => import("../pages/roles/view"));
const MoviesIndex = lazy(() => import("../pages/movies/index"));
const MoviesCreate = lazy(() => import("../pages/movies/create"));
const MoviesEdit = lazy(() => import("../pages/movies/edit"));
const ViewMovies = lazy(() => import("../pages/movies/view"));
const NotificationsIndex = lazy(() => import("../pages/notifications/index"));
const VendorIndex = lazy(() => import("../pages/vendors/index"));
const VendorEdit = lazy(() => import("../pages/vendors/editUser"));

const CafeIndex = lazy(() => import("../pages/cafes/index"));
const ApproveBankIndex = lazy(() => import("../pages/approveBank/index"));
const PayoutRequestIndex = lazy(() => import("../pages/payoutRequests/index"));

const OrdersIndex = lazy(() => import("../pages/orders/index"));

const CafeEdit = lazy(() => import("../pages/cafes/editCafe"));
const DrinktypeIndex = lazy(() => import("../pages/drinktypes/index"));
const DrinktypeEdit = lazy(() => import("../pages/drinktypes/editDrinktype"));
const DrinktypeCreate = lazy(() =>
  import("../pages/drinktypes/createDrinktype")
);
const ViewDrinktype = lazy(() => import("../pages/drinktypes/viewDrinktype"));
const ViewMenus = lazy(() => import("../pages/menus/viewMenus"));
const MenuIndex = lazy(() => import("../pages/menus/index"));
const ClaimRequestsIndex = lazy(() => import("../pages/cafes/claimRequest"));
const ViewVendorClaimRequest = lazy(() =>
  import("../pages/cafes/viewVendorDetails")
);
const ClaimRequestsOutsideIndex = lazy(() =>
  import("../pages/claimRequests/index")
);
const MenuCreate = lazy(() => import("../pages/menus/createMenu"));
const MenuEdit = lazy(() => import("../pages/menus/editMenu"));
const ContactUsIndex = lazy(() => import("../pages/contactus/index"));
const ViewContactUs = lazy(() => import("../pages/contactus/viewContactUs"));
const FeedbackIndex = lazy(() => import("../pages/feedback/index"));
const IssuesIndex = lazy(() => import("../pages/issues/index"));

const CouponIndex = lazy(() => import("../pages/coupons/index"));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/contactus"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ContactUsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/orders"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <OrdersIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/enquiries/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewContactUs />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chat"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute> {/* <ChatContainer /> */}</ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employee"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EmployeeIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employee/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EmployeeCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/employee/edit/:empId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EmployeeEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employee/viewdetails/:empId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewEmployee />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <NotificationsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/movies"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MoviesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/movies/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MoviesCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/movies/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MoviesEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/movies/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewMovies />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/vendor/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <VendorCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/vendor/viewdetails/:vendorId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewVendors />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            path="/vendor/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <VendorEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/hooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <HooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/customhooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CustomHooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/text"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MyForm />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/table"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AgGridTable />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/adminuser"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AdminUserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/adminuser/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AdminUserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/adminuser/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AdminUserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/vendor"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <VendorIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/cafe"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CafeIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/cafe/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CafeCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/cafe/viewdetails/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewCafes />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            path="/cafe/edit/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CafeEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/*---- COUPONS ----*/}
          <Route
            exact
            path="/coupons"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CouponIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* Claim requests */}

          <Route
            exact
            path="/claim-requests/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ClaimRequestsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/claim-requests/vendor-details/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewVendorClaimRequest />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* Cafe claim requests */}
          <Route
            exact
            path="/claimrequests"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ClaimRequestsOutsideIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/claimrequests/viewdetails/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewCafes />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* Menus */}

          <Route
            exact
            path="/menu/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MenuIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/menus/create/:cafeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MenuCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            path="/menus/edit/:menuId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MenuEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/menus/viewdetails/:menuId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewMenus />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* Drink types */}

          <Route
            exact
            path="/drinktypes"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <DrinktypeIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/drinktypes/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <DrinktypeCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/drinktypes/viewdetails/:drinktypeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewDrinktype />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            path="/drinktypes/edit/:drinktypeId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <DrinktypeEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* <Route
            exact
            path="/payout-accounts"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ApproveBankIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/payoutaccounts/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewBank />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}

          <Route
            exact
            path="/payouts"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <PayoutRequestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/payouts/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewPayoutRequests />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/feedback"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <FeedbackIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/issues"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <IssuesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/feedback/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewFeedback />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: "250px", marginLeft: "300px" }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {" "}
                <PageNotFound />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {" "}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          exact
          path="/contactus"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {" "}
                <ContactUsIndex />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies("Token")) {
    if (getCookies("USERPERMISSION")) {
      return children;
    }
    removeCookies("Token");
    removeCookies("refreshToken");
    sessionStorage.setItem("cookiesCleared", true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem("cookiesCleared", true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies("Token") ? <Navigate to="/dashboard" /> : children;
}
