/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { REACT_APP_API_APPROVE_CLAIM_REQUESTS, REACT_APP_API_FETCH_CLAIM_REQUESTS, REACT_APP_API_REJECT_CLAIM_REQUESTS } from "../../redux/apiConstants";
import CustomTable from "../../components/CustomTable/CustomTable";

const ViewClaimRequestPopup = React.memo(({ claimRequestId, handleClose, setIsUpdated }) => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle, bodyheader, viewformStyle } =
    useContext(TableContext);
  const dispatch = useDispatch();
  const params = useParams();
  // let claimRequestId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [canDelete, setCanDelete] = useState(true);
  const {claimUpdate,setClaimUpdate} = useContext(TableContext);

  let statusCode;
  const getBankDetails = async () => {
    setLoading(true);
    axios
      .get(
        `${REACT_APP_API_FETCH_CLAIM_REQUESTS}/${claimRequestId}`,
        {
          method: "GET",
          headers: { Authorization: token },
        }
      )
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          console.log("res.data.payout details", res.data.result);
          setDetails(res.data.result);
          setLoading(false);
        }else if (statusCode === 404) {
          console.log("no claim request.." );
          handleClose();
          setLoading(false);
        }else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    //   claimRequestId = params.id;
    getBankDetails();
    // setDashboardHeader("Payout Details");
  }, []);

  const handleApprove = (id, cafeId) => {
    console.log("approve id", cafeId);
    let data = {};

    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to approve?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionUrl = ` ${REACT_APP_API_FETCH_CLAIM_REQUESTS}/approve/${id} `;
              const actionData = { cafeId: cafeId };
              axios
                .post(actionUrl, actionData, {
                  headers: { Authorization: token },
                })
                .then((resp) => {
                  if (resp.status === 200) {
                    console.log("response-->", resp.data);
                    toast.success("Claim Request approved successfully");
                    handleClose();
                    setIsUpdated(true);
                  } else {
                    toast.error("Error approving Claim Request");
                  }
                })
                .catch((err) => {
                  toast.error("Error approving Claim Request");
                  console.error("Error: ", err);
                });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  const handleReject = (id, cafeId) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sur, you want to reject?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionUrl = ` ${REACT_APP_API_FETCH_CLAIM_REQUESTS}/reject/${id} `;
              const actionData = { cafeId: cafeId };
              axios
                .post(actionUrl, actionData, {
                  headers: { Authorization: token },
                })
                .then((resp) => {
                  if (resp.status === 200) {
                    console.log("response-->", resp.data);
                    toast.success("Claim Request rejected successfully");
                    handleClose();
                    setIsUpdated(true);  
                  } else {
                    toast.error("Error rejecting Claim Request");
                  }
                })
                .catch((err) => {
                  toast.error("Error rejecting Claim Request");
                  console.error("Error: ", err);
                });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  console.log("details---", details)
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
        ) : (
          <Container fluid>
            {/* <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              /> */}
            <Row>
              <h5 className={styles[bodyheader]} id={styles.addheading}>
                Claim Request Details
              </h5>
              {/* <Col lg={12}>
                <div className={styles.profilepicdiv}>
                  {details.image ? (
                    <Image
                      src={details.image}
                      alt="logo"
                      className={styles.profilepic}
                    />
                  ) : (
                    <div style={{ minHeight: "20px" }}></div>
                  )}
                </div>
              </Col> */}
            </Row>
            {/* <Row className="mt-3">
              <Col lg={12} md={12} sm={12}>
                <div className={styles.detaildiv}>
                  <Row>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Vendor Name</span>
                        <p className={styles.empname}>{details?.claimRequests?.[0]?.userId?.name}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Email</span>
                        <p className={styles.empname}>
                          {details.claimRequests?.[0]?.userId?.email}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Requseted Time</span>
                        <p className={styles.empname}>
                          {DateFormatter(
                            details?.claimRequests?.[0]?.requestDate,
                            "MonthDayYear"
                          ) ?? "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Phone Number</span>
                        <p className={styles.empname}>{details.claimRequests?.[0]?.userId?.phone}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
                    <Row>
          <Col lg={12}>
            <CustomTable
              urlParam={`${REACT_APP_API_FETCH_CLAIM_REQUESTS}/${claimRequestId}?`}
              viewPopUp={true}
              paramId={claimRequestId}
              section="claimRequestDetails"
              toggleButton={{ show: true, field: "active" }}
              deleteCondition={{
                checkCondition: false,
                apiUrl: "www.google.com",
              }}
            />
          </Col>
        </Row>
            {/* {!details?.adminApproved && details.payoutStatus !== "Rejected" && (
              <Row>
                <Col lg={4}>
                  <div id={styles.empdiv}>
                    <Button
                      className={`${styles.title} btn-success`}
                      onClick={() => {
                        handleApprove(details?.claimRequests?.[0]?.userId?.id, details?.id);
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                </Col>
                <Col lg={4}>
                  <div id={styles.empdiv}>
                    <Button
                      className={`${styles.title} btn-danger`}
                      onClick={() => {
                        handleReject(details?.claimRequests?.[0]?.userId?.id, details?.id);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                </Col>
              </Row>
            )} */}
          </Container>
        )}
      </div>
    </div>
  );
});

export default ViewClaimRequestPopup;
