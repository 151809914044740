/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";

const ViewIssuePopup = React.memo(({ issueId, handleClose }) => {
  const { dashboardStyle } = useContext(TableContext);
  const { formthemeStyle, bodyheader, viewformStyle } =
    useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const dispatch = useDispatch();
  const params = useParams();
  // let issueId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const dateFormat = getCookies("dateFormat");
  const [canDelete, setCanDelete] = useState(true);
  const [cafeList, setCafesList] = useState([]);

  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/issues/${issueId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          console.log("res.data.user", res.data.issue);
          setDetails(res.data.issue);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserDetails();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to delete?",
      buttons: canDelete
        ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `cafe              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />/${id}`;
                actionData.actionMethod = "delete";
                dispatch(Entry(actionData)).then(() => {
                  navigate(-1);
                  toast.success("Cafe deleted successfully");
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
    });
  };
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
        ) : (
          <Container fluid>
            {/* <FontAwesomeIcon
              icon={faArrowLeft}
              value="Back"
              onClick={() => {
                navigate(-1);
              }}
              className={styles.arrowback}
            /> */}
            <Row>
              <h5 className={styles[bodyheader]} id={styles.addheading}>
                Reported Issue Details
              </h5>
              {/* <Col lg={12}>
                <div className={styles.profilepicdiv}>
                  {details.image ? (
                    <Image
                      src={details.image}
                      alt="logo"
                      className={styles.profilepic}
                    />
                  ) : (
                    <div>{details.name}</div>
                  )}
                </div>
              </Col> */}
            </Row>
            <Row className="mt-3">
              <Col lg={12} md={12} sm={12}>
                <div className={styles.detaildiv}>
                  <Row>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Name</span>
                        <p className={styles.empname}>{details?.user?.name}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Email</span>
                        <p className={styles.empname}>
                          {details?.user?.email ?? "NA"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Order Id</span>
                        <p className={styles.empname}>
                          {details?.order_id ?? "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Description</span>
                        <p className={styles.empname}>
                          {details?.description ?? "NA"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className={styles[viewformStyle]} id={styles.empdiv}>
                        <span className={styles.title}>Posted On</span>
                        <p className={styles.empname}>
                          {details?.createdAt
                            ? DateFormatter(
                                details?.createdAt,
                                dateFormat || "MonthDayYear"
                              )
                            : "NA"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
});

export default ViewIssuePopup;
