/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import styles from "./createVendorPopup.module.scss";
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { useNavigate } from "react-router-dom";
import { Label } from "reactstrap";
import PasswordField from "../../components/PasswordField/PasswordField";

const CreateVendorPopup = React.memo(({ handleClose, setIsUpdated }) => {
  const [submiting, setSubmitting] = useState(false);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setDashboardHeader("Add Vendor Details");
    const url = REACT_APP_API_ROLES_DROPDOWN;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp.data.role);
        setRoles(resp.data.role.selectdata);
      }); // setting response to state roles
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    if (data?.name?.trim().length === 0) {
      toast.error("Name cannot be empty");
      return;
    }
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      role: data.role,
      password: data.password,
      isVerified: true,
      File: files,
    };
    data.actionUrl = "adminuser";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        //   navigate(-1);
        toast.success("User creation successful");
        handleClose();
        setIsUpdated(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError("Something went wrong!");
      }
    });
  }

  return (
    <div className={styles.popupbox}>
      <div className={styles.box} id={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Create User</h2>
        <div className="container-fluid mt-5">
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Name"
                  fieldName="name"
                  placeHolder="Enter Name"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <EmailInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Email"
                  placeHolder="Enter Email Address"
                  fieldName="email"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <PasswordField
                  fieldName="password"
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Password"
                  register={register}
                  errors={errors}
                  placeHolder="Enter Password"
                  isRequired
                  minimLength={8}
                  eyeiconstyle={styles.eyeicon}
                  mandatory
                />
                {perror && <p className={styles[errormsgStyle]}>{perror}</p>}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <SelectBox
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  name="role"
                  label="Role"
                  labelstyle={styles.formlabel}
                  control={control}
                  register={register}
                  values={roles}
                  errors={errors}
                  placeholder="Choose Role"
                  mandatory
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <MultipleFileUpload register={register} errors={errors} />
              </div>
            </div>
            <div className="d-flex">
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? "Please wait.." : "Submit"}
              />
              {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
            </div>
            <br />
            {error && (
              <h6 className={styles[errormsgStyle]} style={{ float: "right" }}>
                {error}
              </h6>
            )}
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateVendorPopup;
