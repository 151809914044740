/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/fontawesome-free-solid"; // Edit icon added
import { Entry } from "../../redux/entrySlice";
import TextInput from "../../components/TextField/TextField";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import styles from "./editDrinktypePopup.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { TableContext } from "../../contexts/tableContext";
import { Image } from "react-bootstrap";
import { HexColorPicker } from "react-colorful";
import togglestyles from "../../components/ToggleButton/ToggleButton.module.scss";

const EditDrinkTypePopup = React.memo(
  ({ drinkTypeId, handleClose, setIsUpdated, edit = true }) => {
    const dispatch = useDispatch();
    const { files } = useContext(TableContext);
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();

    const [user, setUser] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // State for edit/view mode
    const [color, setColor] = useState();
    const [isOff, setIsOff] = useState();

    useEffect(() => {
      const actionData = {
        actionUrl: `drinktypes/${drinkTypeId}`,
        actionMethod: "get",
      };

      dispatch(Entry(actionData)).then((resp) => {
        if (resp.payload.data.drinkType) {
          setUser(resp.payload.data.drinkType);
          setColor(resp.payload.data?.drinkType?.color);
          setIsOff(resp.payload.data?.drinkType?.foodItem);
          reset(resp.payload.data.drinkType);
        }
      });
    }, [drinkTypeId, dispatch, reset]);

    const onSubmitData = (data) => {
      setSubmitting(true);
      setError(null);
      const apiData = {
        type: data.type,
        taxcode: data.taxcode ? data.taxcode : null,
        order: parseInt(data?.order, 10),
        color: color,
        foodItem: isOff,
        File: files,
      };
      data.actionUrl = `drinktypes/${drinkTypeId}`;
      data.actionMethod = "patch";
      data.apiData = apiData;

      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("Drink type updated successfully");
          setIsEditing(false); // Switch back to view mode after update
          handleClose();
          setIsUpdated(true);
        } else {
          setError(resp.payload.message);
          toast.error(resp.payload.message);
        }
      });
    };

    const toggleEditMode = () => {
      setIsEditing(!isEditing); // Toggle between edit and view modes
    };

    const handleButtonClick = () => {
      setIsOff(!isOff);
    };

    return (
      <div className={styles.popupbox}>
        <div className={styles.box}>
          {/* Flex container for close button, heading, and edit button */}
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center gap-3">
              <button
                className={styles.closeicon}
                aria-label="Close"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

              {user?.image && (
                <Image
                  src={user?.image}
                  alt="logo"
                  className={styles.profilepic}
                />
              )}
              <h2 className={styles.heading}>
                {isEditing ? "Edit Drink Type Details" : "Drink Type Details"}
              </h2>
            </div>

            {/* Edit Button (Only when not in edit mode) */}
            {!isEditing && edit && (
              <button
                type="button"
                className={`${styles.formbtnedit} d-flex align-items-center`}
                onClick={toggleEditMode}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: "white", marginRight: "5px" }}
                />
                <span style={{ color: "white" }}>Edit</span>
              </button>
            )}
          </div>

          {/* Form Section */}
          <div className="container-fluid editdrinktypeform">
            {user ? (
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="row">
                  {/* Left Column */}
                  <div className="col-md-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Drink Type"
                      fieldName="type"
                      placeHolder="Enter Drink Type"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      maxLength="50"
                      defaultValue={user.type}
                      readOnly={!isEditing}
                    />
                    <TextInput
                      className={styles.inputbox}
                      label="Tax Code"
                      fieldName="taxcode"
                      placeHolder="Enter Tax Code"
                      register={register}
                      errors={errors}
                      maxLength="50"
                      defaultValue={" "}
                      readOnly={!isEditing}
                    />
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Order"
                      fieldName="order"
                      placeHolder="Enter Order (e.g., 1 for highest)"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      type="number"
                      min="1"
                    />
                    <label className={styles.formlabel}>Food Item</label>
                    <div className="d-flex align-items-center">
                      <label className={togglestyles.switch}>
                        <input
                          type="checkbox"
                          checked={isOff}
                          onClick={handleButtonClick}
                          disabled={!isEditing}
                        />
                        <span className={togglestyles.slider} />
                      </label>
                    </div>
                  </div>

                  {/* Color Picker and File Upload */}
                  <div className="col-md-6">
                    {isEditing ? (
                      <>
                        <span>Color:</span>
                        <input
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                        <div style={{ margin: "5px" }}>
                          <HexColorPicker color={color} onChange={setColor} />
                        </div>
                      </>
                    ) : (
                      <TextInput
                        className={styles.inputbox}
                        label="Color"
                        fieldName="color"
                        placeHolder="Choose a color"
                        register={register}
                        errors={errors}
                        isRequired
                        mandatory
                        readOnly={!isEditing}
                      />
                    )}
                  </div>

                  {/* File Upload (Only in edit mode) */}
                  {isEditing && (
                    <div className="col-md-6">
                      <MultipleFileUpload
                        editFiles={[user?.image]}
                        section="drinktype"
                        disabled={!isEditing}
                        register={register}
                        errors={errors}
                      />
                    </div>
                  )}
                </div>

                {/* Update Button (Only in edit mode) */}
                {isEditing && (
                  <div className="row mt-3">
                    <div className="col text-center">
                      <input
                        className={styles.formbtn}
                        type="submit"
                        disabled={submitting}
                        value={submitting ? "Please wait.." : "Update"}
                      />
                    </div>
                  </div>
                )}

                {error && <h6 className={styles.errormsg}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default EditDrinkTypePopup;
