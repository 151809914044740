/* eslint-disable react/button-has-type */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import { addDays, format, subDays } from 'date-fns';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { TableContext } from '../../contexts/tableContext';
import styles from './DateRangeFilter.module.scss';

function DateRangeFilter({ onChange, open, setOpen,register,disabled, startDateProp, endDateProp }) {
  const { bodyheader } = useContext(TableContext);
  const { searchboxStyle } = useContext(TableContext);
  function parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate:addDays(new Date(), 1),
      key: 'selection',
    },
  ]);

  let EndDate, startDate;
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };
  // console.log("dates", state[0].startDate, state[0].endDate);
  const formatDate = (date) => format(date,'MM/dd/yyyy');
  EndDate = endDateProp? formatDate(parseISOString(endDateProp)) : '';
  startDate = startDateProp? formatDate(parseISOString(startDateProp)) : '';
  return (
    <div className={styles.container} >
      <p id={styles.chooseheading}>
        Choose Date Range
        <span style={{color:"red" , marginLeft:"3px"}}>*</span>
      </p>

      <div className="d-flex flex-start justify-content-start gap-2">
      <div className={`${styles.inputbox} ${disabled ? styles.disabledText : ''}`}>
        {state[0].startDate !== '' && state[0].endDate !== '' && (
          <span
          >
            {startDate} {'- '}
            {EndDate}
          </span>
        )}
      </div>
      {!disabled&&(
        <button type="button" onClick={() => setOpen(!open)} className={styles.openbtn}>
          <FontAwesomeIcon
            onClick={() => setOpen(!open)}
            icon={faCalendarDays}
            className={styles.calendaricon}
            />
        </button>)
        }
        </div>

    </div>
  );
}

DateRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};
DateRangeFilter.defaultProps = {
  open: false,
};

export default DateRangeFilter;
