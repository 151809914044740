import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import TextInput from "../../components/TextField/TextField";
import NumberInput from "../../components/NumberBox/NumberBox";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import { TableContext } from "../../contexts/tableContext";
import "react-toastify/dist/ReactToastify.css";
import styles from "./editMenuPopUp.module.scss";
import { REACT_APP_API_DRINKTYPES } from "../../redux/apiConstants";
import SelectBox from "../../components/SelectBox/SelectBox";
import { REACT_APP_API_ADDONS } from "../../redux/apiConstants";
import { faEdit, faPen } from "@fortawesome/free-solid-svg-icons";

const MenuEdit = React.memo(({ menuId, handleClose, setIsUpdated }) => {
  const { setDashboardHeader } = useContext(TableContext);
  const { formthemeStyle, bodyheader, viewformStyle } =
    useContext(TableContext);
  const [menu, setMenu] = useState(null);
  const [error, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [drinkTypes, setDrinkTypes] = useState([]);
  const [unit, setUnit] = useState("ml");
  const [isEditMode, setIsEditMode] = useState(false);
  const [addons, setAddons] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [addOnData, setAddOnData] = useState([]);
  const [reload, setReload] = useState(false);
  const [description, setDescription] = useState("");
  // const { files } = useContext(TableContext);
  const [files, setMenuFiles] = useState([]);
  const [image,setImage] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const getDrinkTypes = async () => {
    const drinktypes = await axios.get(REACT_APP_API_DRINKTYPES);
    // console.log("drinktypes-->", drinktypes.data?.data);
    setDrinkTypes(drinktypes.data?.data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Edit Menu Details");

    const actionData = {
      actionUrl: `menus/menu-details/${menuId}`,
      actionMethod: "get",
    };
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.menu) {
        const fetchedMenu = resp.payload.data.menu;
        console.log("data", fetchedMenu);
        if (!fetchedMenu.image || fetchedMenu.image.length === 0) {
          setImage(null);
        } else {  
          setImage(fetchedMenu.image);
        }
        setUnit(fetchedMenu.sizeOptions[0].unit);
        // if (fetchedMenu.drinkType && fetchedMenu.drinkType.type) {
        //   fetchedMenu.drinkType = fetchedMenu.drinkType.type;
        // }
        setMenu(fetchedMenu);
        console.log(resp.payload.data.menu);
        reset(resp.payload.data.menu); // Reset form with fetched data
      } else {
        setErrors("Menu data not found");
      }
    });
    getDrinkTypes();

    // Fetch add-ons based on drink type (menuId) similar to the menu fetch
    const addonsActionData = {
      actionUrl: `addons/addon-bydrink/${menuId}`,
      actionMethod: "get",
    };
    dispatch(Entry(addonsActionData))
      .then((response) => {
        if (response.payload && response.payload.data) {
          setAddons(response.payload.data);
          console.log("Here", addons);
        } else {
          setAddons([]); // No addons found
        }
      })
      .catch((error) => {
        console.error("Error fetching add-ons:", error);
        setAddons([]); // Handle error by showing no addons
      });
  }, [dispatch, menuId, reset, setDashboardHeader, reload]);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleAddonChange = (index, field, value) => {
    const updatedAddons = [...addons];
    updatedAddons[index][field] = value; // Update either name or price in the specific add-on
    setAddons(updatedAddons);
  };

  const handleAddAddon = async () => {
    const addOnName = getValues("addOnName");
    const addOnPrice = parseFloat(getValues("addOnPrice"));

    if (!addOnName || isNaN(addOnPrice)) {
      toast.error("Please enter a valid add-on name and price.");
      return;
    }

    const newAddon = {
      name: addOnName,
      drink: menuId,
      addedBy: "admin",
      price: addOnPrice,
      required: isRequired,
    };

    try {
      // Send the newAddon object to the API endpoint
      const response = await fetch(`${REACT_APP_API_ADDONS}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newAddon),
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Failed to add the new add-on.");
      }

      const result = await response.json();
      setReload(!reload);

      // Reset form fields
      reset({ addOnName: "", addOnPrice: "" });

      // Show success toast
      toast.success("Add-on created successfully!");
    } catch (error) {
      console.error("Error creating add-on:", error);
      toast.error("Error creating add-on. Please try again.");
    }
  };
  const onSubmitData = (data) => {
    setSubmitting(true);
    setErrors(null);
    const sizeOptions = data.sizeOptions.map((sizeOption, index) => {
      return {
        size: sizeOption.size || ["Small", "Medium", "Large"][index],  
        price: isNaN(sizeOption.price) ? 0 : Number(sizeOption.price),
        quantity: sizeOption.quantity,
        unit: unit,
        note: sizeOption.note,
      };
    });
    console.log("drinktype",data.drinkType);
    const apiData = {
      name: data.name,
      description: data.description,
      preparationTime: data.preparationTime,
      drinkType: data.drinkType ? data.drinkType._id: menu.drinkType._id,
      taxcode: data.taxcode? data.taxcode : null,
      // sizeOptions: [
      //   {
      //     size: "Small",
      //     price: data.sizeOptions[0].price === "" ? data.sizeOptions[0].price: 0,
      //     quantity: data.sizeOptions[0].quantity,
      //     unit: unit,
      //     note: data.sizeOptions[0].note,
      //   },
      //   {
      //     size: "Medium",
      //     price: data.sizeOptions[1].price === "" ? data.sizeOptions[1].price: 0,
      //     quantity: data.sizeOptions[1].quantity,
      //     unit: unit,
      //     note: data.sizeOptions[1].note,
      //   },
      //   {
      //     size: "Large",
      //     price: data.sizeOptions[2].price === "" ? data.sizeOptions[2].price: 0,
      //     quantity: data.sizeOptions[2].quantity,
      //     unit: unit,
      //     note: data.sizeOptions[2].note,
      //   },
      // ],
      sizeOptions:sizeOptions,
      File: files,
      // Addons: addons,
    };

    data.actionUrl = `menus/${menuId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    console.log("menudata--->\n", data);
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Menu updated successfully");
        handleClose();
        setIsUpdated(true); 
      } else {
        setErrors(resp.payload.message);
        toast.error("Error updating menu");
      }
    });
  };

  // Function to handle deleting an add-on
  const handleDeleteAddon = async (addonId) => {
    console.log(addonId);
    if (!addonId) {
      toast.error("Invalid add-on ID.");
      return;
    }

    try {
      // Send a DELETE request to the API endpoint
      // const response = await fetch(`${REACT_APP_API_ADDONS}/${addonId}`, {
      //   method: "DELETE",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      // if (!response.ok) {
      //   throw new Error("Failed to delete the add-on.");
      // }

      // const result = await response.json();

      // console.log("Add-on deleted successfully:", result);

      // setAddons((prevAddons) =>
      //   prevAddons.filter((addon) => addon.id !== addonId)
      // );

      // // Show success toast
      // toast.success("Add-on deleted successfully!");
      // let data;
      // data.actionUrl = `${REACT_APP_API_ADDONS}/${addonId}`;
      // data.actionMethod = "delete";
      // data.apiData = apiData;
      const data = {
        actionUrl: `addons/${addonId}`,
        actionMethod: "delete",
      };
      dispatch(Entry(data)).then((resp) => {
        // setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("Add-on deleted successfully");
          setAddons((prevAddons) =>
            prevAddons.filter((addon) => addon.id !== addonId)
          );
          // handleClose();
          // setIsUpdated(true);
        } else {
          setErrors(resp.payload.message);
          toast.error("Error deleting addon");
        }
      });
    } catch (error) {
      console.error("Error deleting add-on:", error);
      toast.error("Error deleting add-on. Please try again.");
    }
  };
  
  const handleError = (err) => {
    console.log("error", err,"type:",typeof err);
  }

  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.closeicon}
            aria-label="Close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {image && (<Image
            src={image}
            alt="logo"
            className={styles.profilepic}
          />
          )}
          <h2 className={styles.heading}>
            {!isEditMode ? "Menu Details" : "Edit Menu Details"}
          </h2>
          {!isEditMode && (
            <button
              className={`${styles.formbtnedit}`}
              onClick={() => setIsEditMode(true)}
              aria-label="Edit"
            >
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
              Edit
            </button>
          )}
        </div>
        <div className="container-fluid mt-5 editMenuForm">
          {menu ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Menu Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  {!isEditMode ? (
                    <>
                      <label htmlFor="description">Description</label>
                      <textarea
                        className={`${styles.inputbox} ${styles.textareaInput}`}
                        value={menu?.description}
                        readOnly
                      />
                    </>
                  ) : (
                    <TextInput
                      as="input"
                      type="text"
                      className={`${styles.inputbox}`}
                      label="Description"
                      fieldName="description"
                      placeHolder="Enter Menu Description"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <NumberInput
                    className={styles.inputbox}
                    label="Preparation Time (in mins)"
                    fieldName="preparationTime"
                    placeHolder="Preparation Time"
                    register={register}
                    errors={errors}
                    setErrors={setErrors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="drinkType"
                    label="Drink Type"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={drinkTypes}
                    errors={errors}
                    placeholder={menu.drinkType.type}
                    mandatory
                    isRequired
                    Disabled={!isEditMode}
                    readOnly={!isEditMode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Tax Code"
                    fieldName="taxcode"
                    placeHolder="Enter Tax Code"
                    register={register}
                    errors={errors}
                    maxLength="50"
                    defaultValue={" "}
                  />
                </div>
                </div>
              <div className="row">
                <Form.Group className={`${styles.biggerRadio} mt-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="unit"
                    value="ml"
                    checked={unit === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="unit"
                    value="oz"
                    checked={unit === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    disabled={!isEditMode}
                  />
                </Form.Group>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Small Size Price"
                    fieldName="sizeOptions[0].price"
                    placeHolder="Price"
                    maximLength={6}
                    register={register}
                    errors={errors}
                    isRequired
                    readOnly={!isEditMode}
                  />
                  <NumberInput
                    className={styles.inputbox}
                    label="Small Size Quantity"
                    fieldName="sizeOptions[0].quantity"
                    placeHolder={`In ${unit}`} // ml or oz based on toggle
                    register={register}
                    maximLength = {6}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Medium Size Price"
                    fieldName="sizeOptions[1].price"
                    placeHolder="Price"
                    maximLength = {6}
                    register={register}
                    errors={errors}
                    isRequired
                    readOnly={!isEditMode}
                  />
                  <NumberInput
                    className={styles.inputbox}
                    label="Medium Size Quantity"
                    fieldName="sizeOptions[1].quantity"
                    maximLength = {6}
                    placeHolder={`In ${unit}`} // ml or oz based on toggle
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Large Size Price"
                    fieldName="sizeOptions[2].price"
                    maximLength = {6}
                    placeHolder="Price"
                    register={register}
                    errors={errors}
                    isRequired
                    readOnly={!isEditMode}
                  />
                  <NumberInput
                    className={styles.inputbox}
                    label="Large Size Quantity"
                    fieldName="sizeOptions[2].quantity"
                    maximLength = {6}
                    placeHolder={`In ${unit}`} // ml or oz based on toggle
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="note"
                    fieldName="sizeOptions[0].note"
                    placeHolder="Enter Note for Small Sizes"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Medium Sizes"
                    fieldName="sizeOptions[1].note"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Large Sizes"
                    fieldName="sizeOptions[2].note"
                    register={register}
                    errors={errors}
                    readOnly={!isEditMode}
                    // isRequired
                    // mandatory
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <h4>Add-ons</h4>
                  {addons.length > 0 ? (
                    addons.map((addon, index) => (
                      <div
                        key={index}
                        className={`${styles.addonRow} row mb-3`}
                      >
                        <div className="col-md-6 col-sm-6">
                          <span>{addon.name}</span>
                        </div>
                        <div className="col-md-3 col-sm-3">
                          <span>${addon.price.toFixed(2)}</span>
                        </div>
                        <div className="col-md-3 col-sm-3 text-right">
                          {isEditMode && (
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteAddon(addon.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No add-ons available</p>
                  )}
                </div>
              </div>
              {isEditMode && (
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Name"
                      fieldName="addOnName"
                      placeHolder="Name"
                      register={register}
                      errors={errors}
                      // mandatory
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <NumberInput
                      className={styles.inputbox}
                      name="drinkType"
                      label="Price"
                      labelstyle={styles.formlabel}
                      fieldName="addOnPrice"
                      control={control}
                      register={register}
                      // values={[]}
                      errors={errors}
                      placeHolder="Enter Price"
                      // mandatory
                    />
                  </div>
                  <div className={`col-md-3 ${styles.required}`}>
                    <Form.Check
                      className={`${styles.inputboxrequired}`}
                      type="checkbox"
                      label={
                        <span style={{ marginLeft: "5px" }}>Required</span>
                      }
                      checked={isRequired}
                      onChange={() => setIsRequired(!isRequired)} // Toggle the state
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  {isEditMode && (
                    <Button variant="primary" onClick={handleAddAddon}>
                      + Add New Addon
                    </Button>
                  )}
                </div>
              </div>
                  {errors && Object.keys(errors).map((key) => {
                        const value = errors[key];
    
                        if (key === "sizeOptions" && Array.isArray(value) && value.every((item) => typeof item === 'object' && item !== null)
                        ) {
                          return (
                            <div key={key}>
                              {value.map((sizeOptionsObject, index) => (
                                <div key={`${key}-${index}`}>
                                  {/* Optional header for clarity */}
                                  {/* <strong className={styles.errormsg}>{key} - Object {index + 1}:</strong> */}
                                  {Object.keys(sizeOptionsObject).map((nestedKey) => (
                                    <p key={nestedKey} className={styles.errormsg}>
                                      {sizeOptionsObject[nestedKey]?.message || "No message available"}
                                    </p>
                                  ))}
                                </div>
                              ))}
                            </div>
                          );
                        }
    
                        if (typeof value === 'object' && value !== null) {
                          return (
                            <div key={key}>
                              {/* <strong className={styles.errormsg}>{key}:</strong> */}
                              {value.message ? (
                                <p className={styles.errormsg}>{value.message}</p>
                              ) : (
                                Object.keys(value).map((nestedKey) => (
                                  <p key={nestedKey} className={styles.errormsg}>
                                    {nestedKey}: {value[nestedKey]}
                                  </p>
                                ))
                              )}
                            </div>
                          );
                        }
                    
                        return (
                          <p key={key} style={{ color: "red" }}>
                            {value}
                          </p>
                        );
                  })}
              
              <div className="row mt-3">
                <div className="col-md-12 col-sm-12">
                  <MultipleFileUpload section="menu" disabled={!isEditMode} setMenuFiles={setMenuFiles}                      
                     register={register}
                      errors={errors} />
                </div>
              </div>
              {isEditMode && (
                <div className={styles.btnContainer}>
                  <input
                    className={`${styles.formbtn} btn-sm`}
                    type="submit"
                    disabled={submitting}
                    value={submitting ? "Please wait.." : "Update"}
                  />
                </div>
              )}
              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "130px",
              }}
            >
              <SpinnerDotted
                size={90}
                thickness={180}
                speed={100}
                color="#f4ae01"
              />
              <h4 className="mt-3">Fetching data, please wait...</h4>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
});

export default MenuEdit;
