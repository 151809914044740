/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import styles from "./createCouponPopup.module.scss";
import { TableContext } from "../../contexts/tableContext";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { useNavigate } from "react-router-dom";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
import DateRangeFilter from "../../components/DateRangeFilter/DateRangeFilter";
import NumberInput from "../../components/NumberBox/NumberBox";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";

const CreateCouponPopup = React.memo(({ handleClose, setIsUpdated }) => {
  const [submiting, setSubmitting] = useState(false);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const RegularExp = /^[a-zA-Z0-9]*$/;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    handleDateRangeChange(selection);
    setState([selection]);
  };

  const handleDateRangeChange = (selection) => {
    console.log("Selected Date Range:", selection);
    setStartDate(
      selection.startDate ? selection.startDate.toISOString() : null
    );
    setEndDate(selection.endDate ? selection.endDate.toISOString() : null);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // setDashboardHeader("Add Vendor Details");
    const url = REACT_APP_API_ROLES_DROPDOWN;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp.data.role);
        setRoles(resp.data.role.selectdata);
      }); // setting response to state roles
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const discountValidation = () => {
    const discount = parseFloat(getValues("discount"));
    console.log("discount", discount);
    if (discount < 0 || discount > 100) {
      return "Discount must be between 0 and 100"
    }
  }

  function onSubmitdata(data) {
    console.log("Hello world lll")
    console.log(data)
    console.log("Coupon Data",data)
    setSubmitting(true);
    setError(null);
    // if (!(startDate && endDate)) {
    //   setDateError("Start and end date should be selected");
    //   setSubmitting(false);
    //   return
    // }
    const apiData = {
      name: data.name,
      couponCode: data.couponCode.toUpperCase(),
      // description: data.description,
      // startDate: startDate ? new Date(startDate).toISOString() : null,
      // endDate: endDate ? new Date(endDate).toISOString() : null,
      discount: data.discount,
      // limit: data.limit ? data.limit : null,
    };
    console.log("data", apiData);
    data.actionUrl = "coupons";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        //   navigate(-1);
        toast.success("coupon creation successful");
        handleClose();
        setIsUpdated(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        console.log("Not successful.")
      } else {
        setError("Something went wrong horribly!");
      }
    });
  }

  return (
    <div className={styles.popupbox}>
      <div className={styles.box} id={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Create Coupon</h2>
        <div className="container-fluid mt-5">
          {open && (
            <div className="datepicker-wrapper position-relative">
              <button
                className="btn-close custom-close"
                onClick={() => setOpen(false)}
                aria-label="Close"
              ></button>
              <div className="datepicker-container">
                <DateRangePicker
                  onChange={handleOnChange}
                  showSelectionPreview
                  moveRangeOnFirstSelection={true}
                  months={2}
                  ranges={state}
                  minDate={new Date()}
                  direction="horizontal"
                  dateDisplayFormat="yyyy-MM-dd"
                  staticRanges={[]}
                  inputRanges={[]}
                />
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Name"
                  fieldName="name"
                  maximLength={30}
                  placeHolder="Enter Coupon Name"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Coupon Code"
                  fieldName="couponCode"
                  placeHolder="Enter Coupon Code"
                  register={register}
                  maximLength={20}
                  errors={errors}
                  RegularExp={RegularExp}
                  isRequired
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Discount(%)"
                  placeHolder="Enter Discount"
                  fieldName="discount"
                  validation={discountValidation}
                  register={register}
                  errors={errors}
                  isRequired
                  // mandatory
                />
              </div>
              {/* <div className="col-lg-6 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Discount Limit"
                  placeHolder="Enter Limit"
                  fieldName="limit"
                  register={register}
                  errors={errors}
                  // isRequired
                  // mandatory
                />
              </div> */}
            </div>
            {/* <div className="row mt-3">
            <div className="col-lg-6 col-md-6 col-sm-6">
                <label htmlFor="coupondescription" className={styles.formlabel}>
                  Description
                  <span style={{ color: "red",marginLeft: '3px' }}>*</span>
                </label>
                <textarea
                  id="coupondescription"
                  className={`${styles.inputbox}`}
                  placeholder="Enter Description"
                  {...register("description", {
                    required: "Description is required.",
                  })}
                  rows={1}
                  style={{ resize: "none", overflowY: "auto" }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;
                  }}
                />
                {errors.description?.message && (
                  <span className={styles.errormsg}>{errors.description.message}</span>
                )}
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <DateRangeFilter
                  onChange={handleDateRangeChange}
                  open={open}
                  setOpen={setOpen}
                  register={register}
                  errors={errors}
                  disabled={false}
                  startDateProp={startDate}
                  endDateProp={endDate}
                />
                {dateError && 
                  <span style={{color:"red" , marginTop:"10px"}}>{dateError}</span>
                }
              </div>
            </div> */}
            <div className="d-flex justify-content-center mt-3">
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? "Please wait.." : "Submit"}
              />
              {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
            </div>
            <br />
            {error && (
              <h6 className={styles[errormsgStyle]} style={{ float: "right" }}>
                {error}
              </h6>
            )}
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateCouponPopup;
