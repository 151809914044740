import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-time-picker';
import { Controller } from 'react-hook-form';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "./TimePickerComponent.css";

function TimePickerComponent({
  control,
  name,
  isRequired,
  errors,
  label,
  onTimeChange,
  readOnly, // New prop for read-only mode
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: isRequired,
            message: `${label} is required`,
          },
        }}
        render={({ field }) => (
          <div className="time-picker-container">
            <label className="time-picker-label">{label}</label>
            <TimePicker
              className="time-picker"
              onChange={(value) => {
                field.onChange(value);
                if (onTimeChange) {
                  onTimeChange(value);
                }
              }}
              value={field.value}
              disableClock={true}
              format="hh:mm a"
              disabled={readOnly} // Disables input when readOnly is true
            />
          </div>
        )}
      />
      {errors[name] && (
        <p className="error-message">
          {errors[name].message}
        </p>
      )}
    </>
  );
}

TimePickerComponent.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  onTimeChange: PropTypes.func,
  readOnly: PropTypes.bool, // Prop for read-only mode
};

TimePickerComponent.defaultProps = {
  isRequired: false,
  readOnly: false, // Default is not read-only
};

export default TimePickerComponent;
