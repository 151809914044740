/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";

const ViewPayoutPopup = React.memo(
  ({ payoutId, handleClose, setIsUpdated }) => {
    const { dashboardStyle } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { bodyStyle } = useContext(TableContext);
    const { formthemeStyle, bodyheader, viewformStyle } =
      useContext(TableContext);
    const dispatch = useDispatch();
    const params = useParams();
    // let payoutId;
    const token = `Bearer ${getCookies("Token")}`;
    const [loading, setLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [canDelete, setCanDelete] = useState(true);

    let statusCode;
    const getBankDetails = async () => {
      setLoading(true);
      axios
        .get(
          `${envValues.REACT_APP_API_ENDPOINT}/payouts/payout-details/${payoutId}`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        )
        .then((resp) => {
          statusCode = resp.status;
          return resp.data;
        })
        .then((res) => {
          if (statusCode === 200) {
            console.log("res.data.payout details", res.data);
            setDetails(res.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          return err;
        });
    };
    const navigate = useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0);
      //   payoutId = params.id;
      getBankDetails();
      // setDashboardHeader("Payout Details");
    }, []);

    const handleApprove = (id) => {
      console.log("approve id", id);

      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to approve?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  setAcceptLoading(true);
                  const actionData = {};
                  actionData.actionUrl = `payouts/initiate-payout/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData))
                    .then(() => {
                      handleClose();
                      setIsUpdated(true);
                      setAcceptLoading(false);
                      toast.success("Payout approved successfully");
                    })
                    .catch((err) => {
                      setAcceptLoading(false);
                      toast.error("Error approving payout request");
                      console.error("Error: ", err);
                    });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };

    const handleReject = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to reject?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  setRejectLoading(true);
                  const actionData = {};
                  actionData.actionUrl = `payouts/reject-payout/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData))
                    .then(() => {
                      handleClose();
                      setIsUpdated(true);
                      toast.success("Payout rejected successfully");
                      setRejectLoading(false);
                    })
                    .catch((err) => {
                      toast.error("Error rejecting payout request");
                      setRejectLoading(false);
                      console.error("Error: ", err);
                    });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
    return (
      <div className={styles.popupbox}>
        <div className={styles[formthemeStyle]} id={styles.box}>
          <span
            className={styles.closeicon}
            role="button"
            tabIndex={0}
            onClick={handleClose}
            onKeyPress={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          {loading ? (
            <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
          ) : (
            <Container fluid>
              {/* <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              /> */}
              <Row>
                <h5 className={styles[bodyheader]} id={styles.addheading}>
                  Payout Request Details
                </h5>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details.image ? (
                      <Image
                        src={details.image}
                        alt="logo"
                        className={styles.profilepic}
                      />
                    ) : (
                      <div style={{ minHeight: "20px" }}></div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Vendor Name</span>
                          <p className={styles.empname}>
                            {details.userId?.name}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Payout Status</span>
                          <p className={styles.empname}>
                            {details?.payoutStatus}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Requested Time</span>
                          <p className={styles.empname}>
                            {DateFormatter(
                              details?.requestedTime,
                              "MonthDayYear"
                            ) ?? "NA"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Requested Amount</span>
                          <p className={styles.empname}>{`$${
                            details?.amount || 0
                          }`}</p>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Stripe Fee</span>
                          <p className={styles.empname}>{`$${
                            details?.stripeFee || 0
                          }`}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Baristi Fee</span>
                          <p className={styles.empname}>{`$${
                            details?.baristiFee ?? 0
                          }`}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className={styles[viewformStyle]} id={styles.empdiv}>
                    <span className={styles.title}>Final Payout Amount</span>
                    <p className={styles.empname}>{`$${
                      details?.finalPayoutAmount ?? 0
                    }`}</p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className={styles[viewformStyle]} id={styles.empdiv}>
                    <span className={styles.title}>Notes</span>
                    <p className={styles.empname}>
                      {details?.additionalNotes !== ""
                        ? details?.additionalNotes
                        : "N/A"}
                    </p>
                  </div>
                </Col>
              </Row>

              {!details?.adminApproved && details.payoutStatus !== "Rejected" && (
                <Row>
                  <Col lg={4}>
                    <div id={styles.empdiv}>
                      {/* className={styles[viewformStyle]}  */}
                      <Button
                        className={`${styles.title} btn-success`}
                        onClick={() => {
                          handleApprove(details?.id);
                        }}
                      >
                        {acceptLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Accept"
                        )}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div id={styles.empdiv}>
                      {/* className={styles[viewformStyle]}  */}
                      <Button
                        className={`${styles.title} btn-danger`}
                        onClick={() => {
                          handleReject(details?.id);
                        }}
                      >
                        {rejectLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Reject"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </div>
      </div>
    );
  }
);

export default ViewPayoutPopup;
