/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
function TextInput({
  as,
  onInput,
  fieldName,
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  register,
  errors,
  placeHolder,
  isRequired,
  maximLength = 200,
  minimLength,
  RegularExp,
  label,
  mandatory,
  charactersOnly,
  readOnly,
  resetCount,
  id,
  defaultValue,
  maxLength
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const { form } = e.target;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  };
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  useEffect(() => {
    if (resetCount) {
      ChangeTextAreaCount(0);
    }
  }, [resetCount]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}
      </label>
      {mandatory || isRequired ? (
        <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
      ) : (
        <span />
      )}
      <input
        type="text"
        onInput={(event) => {
          event.preventDefault();
          const regex = RegularExp || /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]*$/;
          const allowedChars = regex.toString().slice(1, -1); // Extract characters inside `/ /`
          const dynamicRegex = new RegExp(`[^${allowedChars}]`, 'g'); 
          let value = event.currentTarget.value // Convert to uppercase
          console.log("regExp: " + dynamicRegex);
          if (fieldName === 'couponCode') {
            value = value.toUpperCase();
            value = value.replace(dynamicRegex, ''); // Remove disallowed characters
            value = value.replace(/\s+/g, ''); // Remove all whitespace
            event.currentTarget.value = value; // Update the input value
          }
          if (!regex.test(event.currentTarget.value)) {
              event.currentTarget.value = event.currentTarget.value.replace(/[^A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]/g, '');
          }
        }}
        placeholder={placeHolder}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={readOnly}
        className={classNamess ?? className}
        id={id}
        defaultValue={defaultValue}
        {...register(fieldName, {
          onChange: (e) => {
            // Update character count or perform additional logic
            ChangeTextAreaCount(e.target.value.length);
          },
          onBlur: (event) => {
            // Trim whitespace after the user leaves the field
            event.target.value = event.target.value.trim();
          },
          pattern: {
            value: RegularExp || /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]*$/, // Fallback regex for letters and spaces
            message: fieldName === 'couponCode'?'special characters and spaces not allowed':'invalid characters in input!',
          },
          required: {
            value: isRequired,
            message: `${label} is required.`,
          },
          maxLength: maximLength && {
            value: maximLength,
            message: `${label} must not exceed ${maximLength} characters.`,
          },
          minLength: minimLength && {
            value: minimLength,
            message: `${label} must be at least ${minimLength} characters.`,
          },
        })}
      />

      {/* {textAreaCount !== 0 && (
        <p style={{ float: 'right', fontSize: '12px' }}>
          {'Characters Left : '}
          {maximLength - textAreaCount}
        </p>
      )} */}
      {/* {maximLength - textAreaCount === 0 ? (
        <span style={{ color: 'red' }}>Maximum length accepted is {maximLength}</span>
      ) : (
        <span />
      )} */}
      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
          fontFamily: 'SFUIDisplayRegular',
        }}
      >
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

TextInput.propTypes = {
  as:PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  maximLength: PropTypes.number,
  minimLength: PropTypes.number,
  label: PropTypes.string.isRequired,
  RegularExp: PropTypes.bool,
  labelstyle: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  charactersOnly: PropTypes.bool,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  readOnly: PropTypes.bool,
  resetCount: PropTypes.bool,
  id: PropTypes.string,
};
TextInput.defaultProps = {
  mandatory: false,
  charactersOnly: false,
  RegularExp: false,
  isRequired: false,
  minimLength: 1,
  maximLength: 200,
  classNamedark: null,
  labeldarkstyle: null,
  readOnly: false,
  className: 'default-input-class',
  placeHolder: 'Enter Value',
  resetCount: false,
  id: '',
};

export default TextInput;
