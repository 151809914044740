import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import EmailInput from "../../components/EmailBox/EmailBox";
import TextInput from "../../components/TextField/TextField";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import { Entry } from "../../redux/entrySlice";
import PasswordField from "../../components/PasswordField/PasswordField";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { TableContext } from "../../contexts/tableContext";
import styles from "./createUser.module.scss";
import { Container,Row, Col } from "react-bootstrap";
import {
  REACT_APP_API_ROLES_DROPDOWN,
  REACT_APP_API_FETCH_VENDOR,
  SERVER_URL,
  REACT_APP_API_FETCH_ZIPCODES,
  REACT_APP_API_VENDORS_LIST,
} from "../../redux/apiConstants";
import axios from "axios";
import LocationSelectBox from "../../components/LocationSelectBox/LocationSelectBox";
import TimePickerComponent from "../../components/TimePicker/TimePicker";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function UserCreate() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);

  const [text, setText] = useState();
  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);

  const [timings, setTimings] = useState([
    { dayOfWeek: "Monday", startTime: "", endTime: "" },
    { dayOfWeek: "Tuesday", startTime: "", endTime: "" },
    { dayOfWeek: "Wednesday", startTime: "", endTime: "" },
    { dayOfWeek: "Thursday", startTime: "", endTime: "" },
    { dayOfWeek: "Friday", startTime: "", endTime: "" },
    { dayOfWeek: "Saturday", startTime: "", endTime: "" },
    { dayOfWeek: "Sunday", startTime: "", endTime: "" },
  ]);

  const handleTimingsChange = (index, type, value) => {
    const { startTime, endTime } = timings[index] || {};
    
    if (type === "endTime" && value && startTime && value === startTime) {
      alert("Start time and End time cannot be the same.");
      return;
    }
    console.log("timings:",timings[index]);
    setTimings((prevTimings) =>
      prevTimings.map((timing, i) => 
        i === index ? { ...timing, [type]: value } : timing
      )
    );
  };

  const getVendors = async () => {
    const vendorsList = await axios.get(REACT_APP_API_VENDORS_LIST);
    console.log("vendor list---", vendorsList.data?.data?.result);
    // const transformedVendors = vendorsList.data?.data?.result?.results?.map(
    //   (vendor) => ({
    //     value: vendor.id,
    //     label: vendor.name,
    //   })
    // );
    setVendors(vendorsList.data?.data?.result);
  };

  // const getZipCodes = async () => {
  //   const zipCodeList = await axios.get(REACT_APP_API_FETCH_ZIPCODES);
  //   console.log("zip code list", zipCodeList.data?.data?.result);
  //   const transformedZipCodes = zipCodeList.data?.data?.result?.map(
  //     (zipcode) => ({
  //       value: zipcode.zipCode,
  //       label: zipcode.zipCode,
  //     })
  //   );
  //   console.log(transformedZipCodes, "transformed zipcodes");
  //   setZipCodes(transformedZipCodes);
  // };

  const getLocation = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/location?location=${text}`
      );
      console.log("location-----", response.data);
      setLocations(response.data.data);
    } catch (error) {
      console.log("erroorrr--", error);
      console.error("Error fetching location:", error);
    }
  };
  useEffect(() => {
    getLocation();
  }, [text]);
  console.log(text, "text");

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Add Cafe Details");
    const url = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setRoles(resp.data.role.selectdata)); // setting response to state roles
    getVendors();
    getLocation();
    // getZipCodes();
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);

  function convertToAMPM(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":").map(Number);

    // Create a Date object with the given time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const formattedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    console.log("dataa--", data);

    setSubmitting(true);
    setError(null);

    // Format timings into an array
    const apiData = {
      name: data.name,
      description: data.description,
      // timings: JSON.stringify(timings),
      address: data.address,
      location_name: data.location_name.formatted_address,
      location: JSON.stringify(data.location_name.location),
      facebook: data.facebook,
      instagram: data.instagram,
      // vendor: data.Vendor,
      // zipCode: data.zipCode,
      File: files,
      timings:timings,
    };
    console.log("api data", apiData);
    data.actionUrl = "cafe";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError(resp?.payload?.message);
      }
    });
  }

  const handleLocationChange = async (value) => {
    setText(value);
  };

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />

          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="Description"
                    placeHolder="Enter Description"
                    fieldName="description"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                  <TimePickerComponent
                    control={control}
                    name="startTime"
                    isRequired={true}
                    errors={errors}
                    label="Start Time"
                    className="custom-timepicker"
                    mandatory
                  />
                  <TimePickerComponent
                    control={control}
                    name="endTime"
                    isRequired={true}
                    errors={errors}
                    label="End Time"
                    className="custom-timepicker"
                    mandatory
                  />
                </div> */}

                {/* {daysOfWeek.map((day) => (
                  <div className="col-lg-6 col-md-12 col-sm-12" key={day}>
                    <div className="form-group">
                      <label>{`${day} Start Time`}</label>
                      <TimePickerComponent
                        {...register(`${day.toLowerCase()}StartTime`, {
                          required: true,
                        })}
                        label={`${day} Start Time`}
                        errors={errors}
                        className="custom-timepicker"
                        // isRequired
                        control={control}
                      />
                      {errors[`${day.toLowerCase()}StartTime`] && (
                        <span>This field is required</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{`${day} End Time`}</label>
                      <TimePickerComponent
                        {...register(`${day.toLowerCase()}EndTime`, {
                          required: true,
                        })}
                        label={`${day} End Time`}
                        errors={errors}
                        className="custom-timepicker"
                        // isRequired
                        control={control}
                      />
                      {errors[`${day.toLowerCase()}EndTime`] && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                ))} */}

                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="Vendor"
                    label="Vendor"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={vendors}
                    errors={errors}
                    placeholder="Choose Vendor"
                    mandatory
                    isRequired
                  />
                </div> */}

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <LocationSelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="location_name"
                    label="Location (For GPS)"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={locations}
                    errors={errors}
                    placeholder="Choose Location"
                    mandatory
                    isRequired
                    setText
                    handleLocationChange={handleLocationChange}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Instagram"
                    placeHolder="Instagram"
                    fieldName="instagram"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Address"
                    placeHolder="Enter Address"
                    fieldName="address"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                  {/* <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Zip Code"
                    placeHolder="Enter Zip Code"
                    fieldName="zipCode"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    minimLength={5}
                    maximLength={5} /> */}

                  {/* <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="zipCode"
                    label="Zip Code"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={zipCodes}
                    errors={errors}
                    placeholder="Choose ZipCode"
                    mandatory
                    isRequired
                  /> */}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Facebook"
                    placeHolder="Facebook"
                    fieldName="facebook"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
              </div>
              {/* cafe hours */}
              <div className="row">
                <h6 className={styles.heading}>Cafe Hours</h6>
                {timings.map((timing, index) => (
                  <Container key={timing.dayOfWeek}>
                    <Row className="mb-3 align-items-center">
                      <Col md={3} className="text-center">
                        {timing.dayOfWeek.charAt(0).toUpperCase() +
                          timing.dayOfWeek.slice(1)}
                      </Col>

                      <Col md={4} className="text-center">
                        <TimePickerComponent
                          control={control}
                          name={`timings[${index}].startTime`} // Access by index
                          errors={errors}
                          onTimeChange={(value) => {
                            handleTimingsChange(index, "startTime", value); // Use index to update timings
                          }}
                        />
                      </Col>

                      <Col md={1} className="text-center">
                        {" "}
                        -{" "}
                      </Col>

                      <Col md={4} className="text-center">
                        <TimePickerComponent
                          control={control}
                          name={`timings[${index}].endTime`} 
                          errors={errors}
                          onTimeChange={(value) => {
                            handleTimingsChange(index, "endTime", value); 
                          }}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p className={styles.formlabel}>Cover Image</p>
                  <MultipleFileUpload />
                </div>
              </div>
              {/* <div className="row mt-3">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <MultipleFileUpload />
                  </div>
                </div> */}
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Submit"}
                />
                {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
              </div>
              <br />
              {error && (
                <h6
                  className={styles[errormsgStyle]}
                  style={{ float: "right" }}
                >
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCreate;
