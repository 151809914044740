import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Image, Row, Button, Modal } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import TimePickerComponent from "../../components/TimePicker/TimePicker";
import CustomTable from "../../components/CustomTable/CustomTable";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import SelectBox from "../../components/SelectBox/SelectBox";

import { REACT_APP_API_FETCH_MENUS, SERVER_URL } from "../../redux/apiConstants";
import styles from "./editCafePopUp.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { faEdit } from "@fortawesome/fontawesome-free-solid";
import { TableContext } from "../../contexts/tableContext";
import { useNavigate, useParams } from "react-router-dom";
import { getCookies } from "../../hooks/useCookies";
import { confirmAlert } from "react-confirm-alert";
import LocationSelectBox from "../../components/LocationSelectBox/LocationSelectBox";
import axios from "axios";

function SuspendCafeModal({ show, handleClose, cafeId }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const periods = [
    { value: "1week", label: "1 Week" },
    { value: "1month", label: "1 Month" },
    { value: "6month", label: "6 Month" },
  ];

  const { setSuspend } = useContext(TableContext);
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      reason: '',
      period: ''
    }
  });

  const handleSuspendCafe = (data) => {
    setSubmitting(true);
    setError(null);

    if (!data.reason || !data.period) {
      setError('Please provide both reason and suspension period');
      setSubmitting(false);
      return;
    }

    const apiData = {
      suspendReason: data.reason,
      suspendPeriod: data.period,
    };

    const actionData = {
      actionUrl: `cafe/suspend-cafe/${cafeId}`,
      actionMethod: "post",
      apiData: apiData
    };

    dispatch(Entry(actionData))
      .then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("Cafe suspended successfully");
          setSuspend(true);
          handleClose();
          reset(); // Reset form after successful submission
        } else {
          setError(resp.payload.message || "Error suspending cafe");
          toast.error("Failed to suspend cafe");
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError("Network error. Please try again.");
        toast.error("Network error suspending cafe");
        console.error("Suspend Cafe Error:", error);
      });
  };

  const handleClickSuspend = () => {
    handleSubmit(handleSuspendCafe)(); // Trigger the form validation and submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Suspend Cafe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <TextInput
            className={styles.inputbox}
            labelstyle={styles.formlabel}
            label="Reason"
            fieldName="reason"
            placeHolder="Enter reason for suspension"
            register={register}
            errors={errors}
            isRequired
            mandatory
          />
          <SelectBox
            className={styles.inputbox}
            name="period"
            label="Suspension Period"
            labelstyle={styles.formlabel}
            control={control}
            register={register}
            values={periods}
            errors={errors}
            placeholder="Select Suspension Period"
            mandatory
            isRequired
          />
          <div className="d-flex justify-content-between mt-3">
            <Button
              color="primary"
              onClick={handleClickSuspend}
              disabled={submitting}
            >
              {submitting ? "Suspending..." : "Suspend Cafe"}
            </Button>
            <Button
              color="secondary"
              onClick={handleClose}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
          {error && (
            <div className="text-danger mt-2">
              {error}
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

const EditCafePopup = React.memo(({ cafeId, setTableData, handleClose, initialAction = "view", setIsUpdated }) => {

  const {   suspend, setSuspend } = useContext(TableContext);
  const params = useParams();
  // let payoutId;
  const token = `Bearer ${getCookies("Token")}`;
  const [details, setDetails] = useState([]);
  const { setDashUpdate } = useContext(TableContext);
  const dateFormat = getCookies("dateFormat");
  const [canDelete, setCanDelete] = useState(true);
  const [cafeList, setCafesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cafe, setCafe] = useState(null);
  const [error, setError] = useState(null);
  const [action, setAction] = useState(initialAction);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { files } = useContext(TableContext);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const [text, setText] = useState();
  const [locations, setLocations] = useState([]);
  const [locationDefault, setLocationDefault] = useState([])
  const [claimRequestCount, setClaimCount] = useState(0);
  const [timeInvalid, setTimeInvalid] = useState(false);
  let defaultLocation;
  const [timings, setTimings] = useState([
    { dayOfWeek: "Monday", startTime: "", endTime: "" },
    { dayOfWeek: "Tuesday", startTime: "", endTime: "" },
    { dayOfWeek: "Wednesday", startTime: "", endTime: "" },
    { dayOfWeek: "Thursday", startTime: "", endTime: "" },
    { dayOfWeek: "Friday", startTime: "", endTime: "" },
    { dayOfWeek: "Saturday", startTime: "", endTime: "" },
    { dayOfWeek: "Sunday", startTime: "", endTime: "" },
  ]);

  const getLocation = async (text) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/location?location=${text}`
      );
      console.log("location-----", response.data);
      setLocations(response.data.data);
    } catch (error) {
      console.log("erroorrr--", error);
      console.error("Error fetching location:", error);
    }
  };
  useEffect(() => {
    if(text?.length >0)
       getLocation(text);
  //   setLocationDefault([{
  //     formatted_address: locations[0].geometry.location,
  //     location: locations[0].geometry.location,
  // }])
  }, [text]);
  console.log(text, "text");


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const handleApprove = async (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to approve the cafe request ?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/approve-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                // navigate(-1);
                handleClose()
                setDashUpdate(prevDashUpdate => !prevDashUpdate);
                setIsUpdated(true);
                setSuspend(false);
                toast.success("Cafe request approved successfully");
              })
                .catch((err) => {
                  toast.error("Error approving cafe request");
                  console.error("Error: ", err);
                });;
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  const handleLocationChange = async (value) => {
    setText(value);
  };

  const handleReject = async (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to reject the cafe request ?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/reject-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData))
                .then(() => {
                  // navigate(-1);
                  handleClose()
                  setIsUpdated(true);
                  setSuspend(false);
                  toast.success("Cafe request rejected successfully");
                })
                .catch((err) => {
                  toast.error("Error rejecting cafe request");
                  console.error("Error: ", err);
                });;
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };


  const handleDelete = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to delete?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/${id}`;
              actionData.actionMethod = "delete";
              dispatch(Entry(actionData)).then(() => {
                navigate('/cafe');
                handleClose();
                setIsUpdated(true);
                toast.success("Cafe deleted successfully");
                setTableData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  const removeSuspension = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to remove suspension?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/un-suspend-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                // navigate(-1);
                setSuspend(false);
                toast.success("Cafe unsuspended successfully");
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  function convertTo24Hour(time12) {
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    const actionData = { actionUrl: `cafe/${cafeId}`, actionMethod: "get" };
    setTimeInvalid(false)
    setLoading(true);
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.cafe) {
        const cafeData = resp.payload.data.cafe;
        const cafeTimings = cafeData.timings;
        const convertedTimings = cafeTimings.map(({ dayOfWeek, startTime, endTime }) => ({
          dayOfWeek,
          startTime: startTime ? convertTo24Hour(startTime) : '',
          endTime: endTime ? convertTo24Hour(endTime) : '',
        }));
        cafeData.timings = convertedTimings;
        console.log("cafe data", cafeData.timings)
        setClaimCount(cafeData?.claimRequestsCount || 0);
        console.log("count", cafeData?.claimRequestsCount);
        setCafe(resp.payload.data.cafe);
        console.log("cafe", resp.payload.data.cafe);
        if (!resp.payload.data.cafe.image || resp.payload.data.cafe.image.length === 0) {
          setImage(null);
        } else {
          setImage(resp.payload.data.cafe.image);
        }
        setLoading(false);
        setSuspend(resp.payload.data.cafe.isSuspended)
        reset({
          ...resp.payload.data.cafe,
          phone: resp.payload.data.cafe.vendor?.phone,
          email: resp.payload.data.cafe.vendor?.email,
        });
        setText(resp.payload.data.cafe.location_name);
        getLocation(resp.payload.data.cafe.location_name);
        // setLocationDefault([{
        //     formatted_address: resp.payload.data.cafe.location_name,
        //     location: resp.payload.data.cafe.location,
        // }])

        const textarea = document.getElementById('description');
        if (textarea) {
          adjustTextareaHeight(textarea);
        }
        setTimings(cafeData.timings);
        const isAnyTimingFilled = cafeTimings.some(
          (timing) => (timing.startTime && !timing.endTime) || (!timing.startTime && timing.endTime) 
        );
        console.log("first", isAnyTimingFilled);
        setTimeInvalid(isAnyTimingFilled);
      } else {
        console.error("Cafe data not found in response:", resp);
        setCafe(null);
      }
    });
  }, [cafeId, dispatch, reset]);

  function convertTo12Hour(time24) {
    const [hours, minutes] = time24.split(':').map(Number);
    if (time24.includes('AM') || time24.includes('PM')) {
      return time24;
    }
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  const onSubmitData = (data) => {
    setSubmitting(true);
    setError(null);
    // console.log("image-->", files)
    const convertedTimings = timings.map(({ dayOfWeek, startTime, endTime }) => ({
      dayOfWeek,
      startTime: startTime ? convertTo12Hour(startTime) : '',
      endTime: endTime ? convertTo12Hour(endTime) : '',
    }));
    const apiData = {
      name: data.name,
      address: data.address,
      description: data.description,
      location_name: data.location_name?.formatted_address ?? locations[0].formatted_address,
      location: JSON.stringify(data.location_name?.location ?? locations[0].geometry.location),
      timings: convertedTimings,
      facebook: data.facebook ?? '',
      instagram: data.instagram ?? '',
      File: files,
    };

    console.log("apiData,", apiData)
    data.actionUrl = `cafe/${cafeId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Cafe updated successfully");
        handleClose();
        setIsUpdated(true);
        setAction("view");
      } else {
        setError(resp.payload.message);
        toast.error("Error updating cafe");
      }
    });
  };

  const parseTime = (time) => {
    const [hourMinute, modifier] = time.split(" ");
    const [hour, minutes] = hourMinute.split(":").map(Number);
    return [hour, minutes, modifier];
  };

  function is12HourFormat(time) {
    const regex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
    return regex.test(time);
  }
  
  const handleTimingsChange = (index, type, value) => {
    const { startTime, endTime } = timings[index]|| {};
    console.log("sdhf",value,type)
    let convertTimeStart;
    const convertedValue = value ? convertTo12Hour(value) : '';
    if (startTime) {
      // Check if startTime is in 12-hour format
      convertTimeStart = is12HourFormat(startTime) ? startTime : convertTo12Hour(startTime);
    } else {
      convertTimeStart = '';
    }
    console.log("start",startTime)
    if (type === "endTime" && convertedValue && convertTimeStart && convertedValue === convertTimeStart) {
      alert("Start time and End time cannot be the same.");
      setTimings((prevTimings) =>
        prevTimings.map((timing, i) =>
          i === index ? { ...timing, [type]: '' } : timing
        )
      );
      console.log("some error 1")
      setTimeInvalid(true);
      return false;
    }

    if (type === "endTime" && value && !startTime) {
      alert("Please enter the start time");
      setTimings((prevTimings) =>
        prevTimings.map((timing, i) =>
          i === index ? { ...timing, [type]: '' } : timing
        )
      );
      console.log("some error 2")
      setTimeInvalid(true);
      return false;
    }
    
  
    console.log("null date:", convertedValue);
    let convertedStart 
    if(startTime !== '') convertedStart = convertTo12Hour(startTime);
    if (type === "endTime" && value) {
      const [endHour, endMinutes, endModifier] = parseTime(convertedValue);
      const [startHour, startMinutes, startModifier] = parseTime(convertedStart);
  
      // Prevent end time from going past 12:00 AM
      if (endHour === 12 && endModifier === "AM") {
        alert("End time cannot go past 12:00 AM.");
        setTimings((prevTimings) =>
          prevTimings.map((timing, i) =>
            i === index ? { ...timing, [type]: '' } : timing
          )
        );
        console.log("some error 3")
        setTimeInvalid(true);
        return false;
      }
  
      // Prevent end time from being earlier than the start time
      const startTotalMinutes =
        (startHour % 12) * 60 +
        startMinutes +
        (startModifier === "PM" ? 12 * 60 : 0);
      const endTotalMinutes =
        (endHour % 12) * 60 +
        endMinutes +
        (endModifier === "PM" ? 12 * 60 : 0);
  
      if (endTotalMinutes < startTotalMinutes) {
        alert("End time cannot be earlier than start time.");
        setTimings((prevTimings) =>
          prevTimings.map((timing, i) =>
            i === index ? { ...timing, [type]: '' } : timing
          )
        );
        console.log("some error 4")
        setTimeInvalid(true);
        return false;
      }
    }


    setTimeInvalid(false);
    setTimings((prevTimings) => {
      const updatedTimings = prevTimings.map((timing, i) =>
        i === index ? { ...timing, [type]: convertedValue } : timing
      );
    
      // Calculate isAnyTimingFilled using the updated state
      const isAnyTimingFilled = updatedTimings.some(
        (timing) => (timing.startTime && !timing.endTime) || (!timing.startTime && timing.endTime)
      );
      
      console.log("final", isAnyTimingFilled);
      setTimeInvalid(isAnyTimingFilled);

      return updatedTimings; 
    });
    console.log("why?")
    return true;
  };
  

  const adjustTextareaHeight = (element) => {
    if (element) {
      element.style.height = 'auto'; // Reset height to default
      element.style.height = `${Math.min(element.scrollHeight, 200)}px`; // Set new height with max limit of 200px
    }
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box}>
        {/* Flex container for header and button */}
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.closeicon}
            aria-label="Close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {image && (<Image
            src={image}
            alt="logo"
            className={styles.profilepic}
          />
          )}
          <h2 className={styles.heading}>
            {isEditMode ? "Edit Cafe Details" : "Cafe Details"}
          </h2>
          {!isEditMode && (
            <button
              className={`${styles.formbtnedit} `}
              onClick={() => setIsEditMode(true)}
              aria-label="Edit"
            >
              <FontAwesomeIcon icon={faEdit} /> {/* Pen icon */}
              Edit
            </button>
          )}

        </div>

        <div className="container-fluid mt-5 editcafeform">
          {cafe ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Cafe Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <label htmlFor="description" className={styles.formlabel}>
                    Description
                  </label>
                  <textarea
                    id="description"
                    className={`${styles.inputbox}`}
                    placeholder="Enter Description"
                    {...register('description', { required: true })}
                    rows={1}
                    // readOnly={!isEditMode}
                    disabled={!isEditMode}
                    style={{ resize: 'none', overflowY: 'auto' }}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = `${Math.min(e.target.scrollHeight, 80)}px`;
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Address"
                    fieldName="address"
                    placeHolder="Enter Cafe Address"
                    register={register}
                    errors={errors}
                    maximLength={100}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <LocationSelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="location_name"
                    label="Location (For GPS)"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={locations}
                    errors={errors}
                    placeholder={text}
                    defaultValue={text}
                    disabled={!isEditMode}
                    mandatory
                    isRequired
                    setText
                    handleLocationChange={handleLocationChange}
                  />
                </div>
              </div>
              {!isEditMode && (<div className="row">

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Phone Number"
                    fieldName="phone"
                    placeHolder="Enter Phone Number"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>

                <div className="col-md-6 col-sm-6">
                  <EmailInput
                    className={styles.inputbox}
                    label="Email"
                    fieldName="email"
                    register={register}
                    errors={errors}
                    placeHolder="Email"
                    readOnly={!isEditMode}
                  />
                </div>
                </div>)}
                <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Facebook"
                  placeHolder="Facebook"
                  fieldName="facebook"
                  register={register}
                  errors={errors}
                  readOnly={!isEditMode}

                // isRequired
                // mandatory
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {" "}
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Instagram"
                  placeHolder="Instagram"
                  fieldName="instagram"
                  register={register}
                  errors={errors}
                  readOnly={!isEditMode}

                // isRequired
                // mandatory
                />
              </div>
                </div>
              
               

             

              <Row>
                {cafe?.cafeStatus === "Pending" && (
                  <>
                    <Col lg={2}>
                      <div id={styles.empdiv}>
                        <Button
                          className={`${styles.title} btn-success`}
                          onClick={() => handleApprove(cafeId)}
                        >
                          Approve Cafe
                        </Button>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div id={styles.empdiv}>
                        <Button
                          className={`${styles.title} btn-danger`}
                          onClick={() => handleReject(cafeId)}
                        >
                          Reject Cafe
                        </Button>
                      </div>
                    </Col>
                  </>
                )}

                <Col lg={2}>
                  <div
                    // className={styles[viewformStyle]}
                    id={styles.empdiv}
                  >
                    {suspend ? (
                      <Button
                        className={styles.title}
                        onClick={() => removeSuspension(cafeId)}
                      >
                        Remove Suspension
                      </Button>
                    ) : (
                      <Button
                        className={`${styles.title} btn-secondary`}
                        onClick={() => setShowModal(true)}
                      >
                        Suspend Cafe
                      </Button>
                    )}

                    <SuspendCafeModal
                      show={showModal}
                      handleClose={handleCloseModal}
                      cafeId={cafeId}
                    />
                  </div>
                </Col>
                <Col lg={2}>
                  <div
                    // className={styles[viewformStyle]}
                    id={styles.empdiv}
                  >
                    <Button
                      className={`${styles.title} btn-danger`}
                      onClick={() => {
                        handleDelete(cafeId);
                      }}
                    >
                      Delete Cafe
                    </Button>
                  </div>
                </Col>
                {details?.cafeStatus === "Pending" && <Row></Row>}
                {!details?.isClaimed && !details.isClaimed && (
                  // <Row>
                  <Col lg={4}>
                    <div
                      // className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <Button
                        className={`${styles.title} btn-primary`}
                        disabled={details?.claimRequests?.length === 0}
                        onClick={() =>
                          navigate(`/claimrequests`)
                        }
                      >
                        Claim Requests: {claimRequestCount}
                      </Button>
                    </div>
                  </Col>
                  // </Row>
                )}
              </Row>
              <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-md-8 col-lg-8 col-sm-6">
                  <h6 className={styles.headinghours}>Cafe Hours</h6>
                  {timings.map((timing, index) => (
                    <Container key={timing.dayOfWeek}>
                      <Row className="mb-3 align-items-center">
                        <Col md={3} className="text-center">
                          {timing.dayOfWeek.charAt(0).toUpperCase() +
                            timing.dayOfWeek.slice(1)}
                        </Col>

                        <Col md={4} className="text-center">
                          <TimePickerComponent
                            control={control}
                            name={`timings[${index}].startTime`}
                            timings={timings}
                            index={index}
                            type={"startTime"}
                            errors={errors}
                            onTimeChange={(value) => handleTimingsChange(index, "startTime", value)}
                            readOnly={!isEditMode}
                          />
                        </Col>

                        <Col md={1} className="text-center">
                          {" "}
                          -{" "}
                        </Col>

                        <Col md={4} className="text-center">
                          <TimePickerComponent
                            control={control}
                            name={`timings[${index}].endTime`}
                            timings={timings}
                            index={index}
                            type={"endTime"}
                            errors={errors}
                            onTimeChange={(value) => handleTimingsChange(index, "endTime", value)}
                            readOnly={!isEditMode}
                          />
                        </Col>
                      </Row>
                    </Container>
                  ))}
                </div>
                {isEditMode && (
                  <div className={`col-md-4 col-lg-4 col-sm-6 ${styles.fileupload}`}>
                    <MultipleFileUpload section="cafe" disabled={!isEditMode}
                      register={register}
                      errors={errors} />
                    {/* <MultipleFileUpload section="menus" disabled={!isEditMode} /> */}
                    {error && <h6 className={styles.errormsg}>{error}</h6>}
                  </div>
                )
                }
              </div>

              {(isEditMode && timeInvalid )&& (
                  <span className={styles.errormsg}>
                    Please ensure all cafe timings are valid. Complete any missing <strong>start time</strong> or <strong>end time</strong>.
                  </span>
                )}

              {/* Update Button at the Bottom */}
              {isEditMode && (
                <div className="row mt-4">
                  <div className="col text-center">
                    <input
                      className={`${styles.formbtn} btn-sm`}
                      type="submit"
                      style={{ marginBottom: "100px" }}
                      disabled={submitting || timeInvalid}
                      value={submitting ? "Please wait.." : "Update"}
                    />
                  </div>
                </div>
              )}
            </form>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "130px",
                color: "#39979d",
              }}
            >
              <SpinnerDotted />
            </div>
          )}
        </div>

        <Row>
          <h3 className={styles.heading}>Menu</h3>
        </Row>

        <Row>
          <Col lg={12}>
            <CustomTable
              urlParam={`${REACT_APP_API_FETCH_MENUS}/${cafeId}?`}
              viewPopUp={true}
              paramId={cafeId}
              section="Menus"
              toggleButton={{ show: true, field: "active" }}
              deleteCondition={{
                checkCondition: false,
                apiUrl: "www.google.com",
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
);

export default EditCafePopup;
