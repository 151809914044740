/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button ,Card} from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./createMenuPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { REACT_APP_API_DRINKTYPES ,REACT_APP_API_ADDONS} from "../../redux/apiConstants";
import { useForm } from "react-hook-form";
import TextInput from "../../components/TextField/TextField";
import NumberInput from "../../components/NumberBox/NumberBox";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import { Form } from "react-bootstrap";
import SelectBox from "../../components/SelectBox/SelectBox";

const CreateMenuPopup = React.memo(({ cafeId, handleClose,setIsUpdated  }) => {
  const [submiting, setSubmitting] = useState(false);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const [drinkTypes, setDrinkTypes] = useState([]);
  const [unit, setUnit] = useState("ml");
  const [isRequired, setIsRequired] = useState(false);
  const [reload, setReload] = useState(false);
  const [addOnData, setAddOnData] = useState([]);
  const [menuFiles, setMenuFiles] = useState();
  // const handleInputChange = (index, field, value) => {
  //   const newSizeOptions = [...sizeOptions];
  //   newSizeOptions[index][field] = value;
  //   setSizeOptions(newSizeOptions);
  // };

  const params = useParams();

  const getDrinkTypes = async () => {
    const drinktypes = await axios.get(REACT_APP_API_DRINKTYPES);
    console.log("drinktypes.data?.data?", drinktypes.data?.data);
    setDrinkTypes(drinktypes.data?.data);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Add Menu Details");
    getDrinkTypes();
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);

    console.log("testing---", addOnData);

    const apiData = {
      name: data.name,
      description: data.description,
      preparationTime: data.preparationTime,
      drinkType: data.drinkType,
      taxcode: data.taxcode? data.taxcode : null,
      cafe: cafeId,
      sizeOptions:[
        {
          size: "Small",
          price: isNaN(data.smallPrice) ? null : Number(data.smallPrice),
          quantity: data.smallQuantity,
          unit: unit,
          note:data.smallNote
        },
        {
          size: "Medium",
          price: isNaN(data.mediumPrice) ? null : Number(data.mediumPrice),
          quantity: data.mediumQuantity ,
          unit: unit,
          note:data.mediumNote && data.mediumNote !== "" ? data.mediumNote : null,
        },
        {
          size: "Large",
          price: isNaN(data.largePrice) ? null : Number(data.largePrice),
          quantity: data.largeQuantity,
          unit: unit,
          note:data.largeNote
        },
      ],
      File: files,
      addOns:addOnData,
    };

    console.log("api data", apiData);
    data.actionUrl = `menus/${cafeId}`;
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("menu added successfully"); 
        handleClose();
        setIsUpdated(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        setAddOnData([]);
        reset();
      } else {
        setError(resp?.payload?.message);
        setAddOnData([]);
        reset();
      }
    });
  }

  const handleAddAddon = async () => {
    const addOnName = getValues("addOnName");
    const addOnPrice = parseFloat(getValues("addOnPrice"));
  
    if (!addOnName || isNaN(addOnPrice)) {
      toast.error("Please enter a valid add-on name and price.");
      return;
    }
  
    const newAddon = {
      name: addOnName,
      addedBy: "admin",
      price: addOnPrice,
      required: isRequired,
    };
    console.log("newAddon:\n", newAddon, "\n addOnName:\n", addOnName, "\n addOnPrice:\n", addOnPrice);
    setAddOnData([...addOnData, newAddon]);
    reset({ addOnName: "", addOnPrice: "" });
  
    // try {
    //   const response = await fetch(`${REACT_APP_API_ADDONS}/`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(newAddon),
    //   });

    //   if (!response.ok) {
    //     throw new Error("Failed to add the new add-on.");
    //   }
  
    //   const result = await response.json();
    //   setReload(!reload)

    //   reset({ addOnName: "", addOnPrice: "" });
  
    //   toast.success("Add-on created successfully!");
    // } catch (error) {
    //   console.error("Error creating add-on:", error);
    //   toast.error("Error creating add-on. Please try again.");
    // }
  };
  const handleDeleteAddon = async (addOnIndex) => {
    console.log(addOnIndex);
    // if (!addOnIndex) {
    //   toast.error("Invalid add-on Name.");
    //   return;
    // }

    setAddOnData((prevAddons) => prevAddons.filter((_, index) => index !== addOnIndex));
    toast.success("deleted add-on");
  };
  

  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h2 className={styles.heading}>Menu Details</h2>
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="Description"
                    placeHolder="Enter Description"
                    fieldName="description"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="drinkType"
                    label="Drink Type"
                    labelstyle={styles.formlabel}
                    // control={control}
                    register={register}
                    values={drinkTypes}
                    errors={errors}
                    placeholder="Choose Drink Type"
                    mandatory
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Preparation time"
                    placeHolder="Preparation time"
                    fieldName="preparationTime"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>
            <div className="row">
            <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Tax Code"
                    fieldName="taxcode"
                    placeHolder="Enter Tax Code"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                    maxLength="50"
                    defaultValue={" "}
                    // readOnly={!isEditing}  

                  />
                </div>
             </div>
              <div className="row mt-3">
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Price for Small"
                    placeHolder="Price for Small"
                    fieldName="smallPrice"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Price for medium"
                    placeHolder="Price for Medium"
                    fieldName="mediumPrice"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Price for Large"
                    placeHolder="Price for Large"
                    fieldName="largePrice"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className={`${styles.biggerRadio} mb-3`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="unit"
                    value="ml"
                    checked={unit === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="unit"
                    value="oz"
                    checked={unit === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                  />
                </Form.Group>

                <div className="col-lg-3 col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Small Size Quantity"
                    fieldName="smallQuantity"
                    placeHolder={`In ${unit}`}
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Medium Size Quantity"
                    fieldName="mediumQuantity"
                    placeHolder={`In ${unit}`}  
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-4">
                  <NumberInput
                    className={styles.inputbox}
                    label="Large Size Quantity"
                    fieldName="largeQuantity"
                    placeHolder={`In ${unit}`} 
                    register={register}
                    errors={errors}
                  />
                </div>
            </div>
              <div className="row">
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="note"
                    fieldName="smallNote"
                    placeHolder="Enter Note for Small Sizes"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Medium Sizes"
                    fieldName="mediumNote"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    maximLength={100}
                    label="note"
                    placeHolder="Enter Note for Large Sizes"
                    fieldName="largeNote"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                  />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                  <h4>Add-ons</h4>
                  {addOnData.length > 0 ? (
                    addOnData.map((addon, index) => (
                      <div
                        key={index}
                        className={`${styles.addonRow} row mb-3`}
                      >
                        <div className="col-md-6 col-sm-6">
                          <span>{addon.name}</span>
                        </div>
                        <div className="col-md-3 col-sm-3">
                          <span>${addon.price.toFixed(2)}</span>
                        </div>
                        <div className="col-md-3 col-sm-3 text-right">
                          <Button
                            variant="danger"
                            size="sm"
                            type="button"
                            onClick={() => handleDeleteAddon(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No add-ons available</p>
                  )}
                </div>
              </div>
            <div className="row">
                <div className="col-md-4 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="addOnName"
                    placeHolder="Name"
                    register={register}
                    errors={errors}
                  // mandatory
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <NumberInput
                    className={styles.inputbox}
                    name="addOnPrice"
                    label="Price"
                    labelstyle={styles.formlabel}
                    fieldName="addOnPrice"
                    control={control}
                    register={register}
                    // values={[]}
                    errors={errors}
                    placeHolder="Enter Price"
                    // mandatory
                  />
                </div>
                <div className={`col-md-3 ${styles.required}`} >
                  <Form.Check
                  className={`${styles.inputboxrequired}`}
                    type="checkbox"
                    label={<span style={{ marginLeft: '5px' }}>Required</span>}
                    checked={isRequired}
                    onChange={() => setIsRequired(!isRequired)} // Toggle the state
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Button variant="primary" type="button" onClick={handleAddAddon}>
                    + Add New Addon
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* <p className={styles.formlabel}>Cover Image</p> */}
                <MultipleFileUpload section={"menus"} disabled={disabled} setMenuFiles={setMenuFiles}
                                      register={register}
                                      errors={errors} />
                {/* <MultipleFileUpload section={"menus"} disabled={!disabled} setMenuFiles={setMenuFiles} /> */}
                </div>
              </div>
              {/* <div className="row mt-3">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <MultipleFileUpload />
                  </div>
                </div> */}
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Submit"}
                />
                {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
              </div>
              <br />
              {error && (
                <h6
                  className={styles[errormsgStyle]}
                  style={{ float: "right" }}
                >
                  {error}
                </h6>
              )}
            </form>
          </div>
      </div>
    </div>
  );
});

export default CreateMenuPopup;
