/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button ,Card} from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewMenuPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const ViewMenuPopup = React.memo(({ menuId, handleClose }) => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle, suspend, setSuspend } = useContext(TableContext);
  const dispatch = useDispatch();
  const params = useParams();
  // let menuId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const dateFormat = getCookies("dateFormat");
  const [canDelete, setCanDelete] = useState(true);
  const [cafeList, setCafesList] = useState([]);

  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/menus/menu-details/${menuId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          console.log("res.data.user", res.data.menu);
          setDetails(res.data.menu);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserDetails();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to delete?",
      buttons: canDelete
        ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `cafe/${id}`;
                actionData.actionMethod = "delete";
                dispatch(Entry(actionData)).then(() => {
                  navigate(-1);
                  toast.success("Cafe deleted successfully");
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: "Ok",
              // onClick: () => alert("Click No")
            },
          ],
    });
  };


  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h2 className={styles.heading}>Menu Details</h2>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
        ) : (
          <Container fluid>
          {/* <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          /> */}
          <Row>
            <Col lg={8}>
              <div className={styles.profilepicdiv}>
                {details.image ? (
                  <Image
                    src={details.image}
                    alt="logo"
                    className={styles.profilepic}
                  />
                ) : (
                  <div>{details.name}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12} md={12} sm={12}>
              <div className={styles.detaildiv}>
                <Row>
                  <Col lg={4}>
                    <div
                      className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <span className={styles.title}>Name</span>
                      <p className={styles.empname}>{details?.name}</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <span className={styles.title}>Description</span>
                      <p className={styles.empname}>
                        {details.description ?? "NA"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div
                      className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <span className={styles.title}>Drink Type</span>
                      <p className={styles.empname}>
                        {details?.drinkType?.type ?? "NA"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <span className={styles.title}>Preparation time</span>
                      <p className={styles.empname}>
                        {details?.preparationTime} mins
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div
                      className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <span className={styles.title}>Drink Options</span>

                      {details?.sizeOptions &&
                      details.sizeOptions.length > 0 ? (
                        <Row>
                          {details.sizeOptions.map((sizeOption, index) => (
                            <Col md={3} key={index} className="m-3">
                              <Card>
                                <Card.Body>
                                  <Card.Title className="fs-5 ">
                                    {sizeOption.size}
                                  </Card.Title>
                                  <Card.Text className={styles.title}>
                                    <strong>Price:</strong> $
                                    {sizeOption.price}
                                  </Card.Text>

                                  {sizeOption.quantity && (
                                    <Card.Text className={styles.title}>
                                      <strong>Quantity:</strong>{" "}
                                      {sizeOption.quantity} {sizeOption.unit}
                                    </Card.Text>  
                                  )}

                                  {/* {sizeOption.note && ( */}
                                    <Card.Text className={styles.title}>
                                      <strong>Note:</strong>{" "}
                                      {sizeOption.note}
                                    </Card.Text>
                                  {/* )} */}
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <p>No size options available</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <Link>Go to menu page</Link> */}
          </Row>
        </Container>
        )}
      </div>
    </div>
  );
});

export default ViewMenuPopup;
