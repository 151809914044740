/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";

const ViewDrinkTypePopup = React.memo(({ drinkTypeId, handleClose }) => {
    const { dashboardStyle } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { bodyStyle } = useContext(TableContext);
    const { formthemeStyle, bodyheader, viewformStyle } = useContext(TableContext);
    const dispatch = useDispatch();
    const params = useParams();
    // let payoutId;
    const token = `Bearer ${getCookies("Token")}`;
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [canDelete, setCanDelete] = useState(true);
    const [showModal, setShowModal] = useState(false);
  
    let statusCode;
    const getUserDetails = async () => {
      setLoading(true);
      axios
        .get(`${envValues.REACT_APP_API_ENDPOINT}/drinktypes/${drinkTypeId}`, {
          method: "GET",
          headers: { Authorization: token },
        })
        .then((resp) => {
          statusCode = resp.status;
          return resp.data;
        })
        .then((res) => {
          if (statusCode === 200) {
            console.log("res.data.user", res.data);
            setDetails(res.data.drinkType);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          return err;
        });
    };
    const navigate = useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0);
    //   drinkTypeId = params.drinkTypeId;
      getUserDetails();
      console.log(drinkTypeId)
      setDashboardHeader("Drinktype Details");
    }, []);
  
   
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
        ) : (
            <Container fluid>
            {/* <FontAwesomeIcon
              icon={faArrowLeft}
              value="Back"
              onClick={() => {
                navigate(-1);
              }}
              className={styles.arrowback}
            /> */}
                          <Row>
                          <h5 className={styles[bodyheader]} id={styles.addheading}>
                 Drink Details
              </h5>
              <Col lg={6}>
                <div className={styles.profilepicdiv}>
                  {details?.image ? (
                    <Image
                      src={details.image}
                      alt="logo"
                      className={styles.profilepic}
                    />
                  ) : (
                        // <div>{details.name}</div>
                        null
                  )}
                </div>
                </Col>
                <Col lg={6}>
                      <div
                        className={styles[viewformStyle]}
                        id={styles.empdiv}
                      >
                        <span className={styles.title}>Type</span>
                        <p className={styles.empname}>{details.type}</p>
                      </div>
                    </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12} md={12} sm={12}>
                <div className={styles.detaildiv}>
                  <Row>
                    {/* <Col lg={4}>
                      <div
                        className={styles[viewformStyle]}
                        id={styles.empdiv}
                      >
                        <span className={styles.title}>Email</span>
                        <p className={styles.empname}>{details.email}</p>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
});

export default ViewDrinkTypePopup;
