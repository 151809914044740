/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";

const ViewVendorPopup = React.memo(({ vendorId, handleClose }) => {
  const { formthemeStyle, bodyheader, viewformStyle } = useContext(TableContext);
  const dispatch = useDispatch();
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [roles, setRole] = useState([]);
  const dateFormat = getCookies("dateFormat");
  const [cafeList, setCafesList] = useState([]);

  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/vendor/${vendorId}`, {
        headers: { Authorization: token },
      })
      .then((resp) => resp.data)
      .then((res) => {
        setDetails(res.data.vendor);
        setCafesList(res.data.vendor?.cafes);
        setRole(res.data.vendor.role);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, [vendorId]);

  const handleDelete = (id) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this vendor?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const actionData = { actionUrl: `vendor/${id}`, actionMethod: "delete" };
            dispatch(Entry(actionData)).then(() => {
              toast.success("Vendor deleted successfully");
              handleClose(); // Close the popup after deletion
            });
          },
        },
        { label: "No" },
      ],
    });
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {loading ? (
          <SpinnerDotted style={{ color: "#39979d", marginLeft: "50%" }} />
        ) : (
          <Container fluid>
            <div>
              <h5 className={styles[bodyheader]} id={styles.addheading}>
                Vendor Details
              </h5>
              <Row>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details.image ? (
                      <Image src={details.image} alt="logo" className={styles.profilepic} />
                    ) : (
                          // <div>{details.name}</div>
                          null
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Name</span>
                          <p className={styles.empname}>{details.name}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>{details.email}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Phone Number</span>
                          <p className={styles.empname}>{details.phone ?? "NA"}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>User Type</span>
                          <p className={styles.empname}>{details.userType}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Location</span>
                          <p className={styles.empname}>{details.location_name ?? "NA"}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Button className="btn-danger" onClick={() => handleDelete(details?._id)}>
                          Delete Vendor
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {cafeList.length > 0 && (
                <Row>
                  <Col lg={12}>
                    <h5>Cafes</h5>
                    <ol>
                      {cafeList?.map((cafe) => (
                        <li key={cafe.id}>
                          <span>{cafe.name}</span>
                          <span> {cafe?.location_name}</span>
                        </li>
                      ))}
                    </ol>
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        )}
      </div>
    </div>
  );
});

export default ViewVendorPopup;
