/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/free-solid-svg-icons"; // Added faEdit for edit button
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import styles from "./editUserPopUp.module.scss";
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { Image } from "react-bootstrap";
import PasswordField from "../../components/PasswordField/PasswordField";

const EditUserPopup = React.memo(
  ({ userId, handleClose, setIsUpdated, edit = true }) => {
    const [submitting, setSubmitting] = useState(false);
    const { formthemeStyle, bodyheader, viewformStyle } =
      useContext(TableContext);
    const [user, setUser] = useState([]);
    const [error, setError] = useState(null);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false); // New state for edit mode
    const dispatch = useDispatch();
    const { files } = useContext(TableContext);
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();

    useEffect(() => {
      setLoading(true);
      const url2 = REACT_APP_API_ROLES_DROPDOWN; // api url
      fetch(url2)
        .then((resp) => resp.json())
        .then((resp) => {
          setRoles(resp.data.role.selectdata);
        });

      const actionData = {
        actionUrl: `adminuser/${userId}`,
        actionMethod: "get",
      };
      dispatch(Entry(actionData)).then((resp) => {
        if (resp.payload && resp.payload.data && resp.payload.data.user) {
          const userData = resp.payload.data.user;
          console.log("userData==", userData);
          setUser(userData); // Set user data including userType
          reset(userData); // Reset the form with user data
          setLoading(false);
        } else {
          console.error("User data not found in response:", resp);
          setUser(null);
        }
      });
    }, [userId]);

    const onSubmitdata = (data) => {
      if (data.name.trim().length === 0) {
        document.getElementById("name").focus();
      } else {
        setSubmitting(true);
        setError(null);
        const apiData = {
          name: data.name,
          email: data.email,
          File: files,
        };

        if (typeof data.role === "string" || typeof data.role === "number") {
          apiData.role = data.role;
        }

        console.log("api data---", apiData);
        if (data.password) {
          apiData.password = data.password;
        }
        data.actionUrl = `adminuser/${userId}`;
        data.actionMethod = "patch";
        data.apiData = apiData;
        dispatch(Entry(data)).then((resp) => {
          setSubmitting(false);
          if (resp.payload.code === 200) {
            toast.success("User details updated successfully");
            setIsEditMode(false);
            handleClose();
            setIsUpdated(true);
          } else {
            setError(resp.payload.message);
            toast.error("Error updating user details");
          }
        });
      }
    };

    return (
      <div className={styles.popupbox}>
        <div className={styles.box} id={styles.box}>
          {/* Flex container for close button, heading, and edit button */}
          <div className="d-flex align-items-center gap-3">
            <button
              className={styles.closeicon}
              aria-label="Close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {/* Conditionally render the heading based on isEditMode */}
            {user.image && !isEditMode && (
              <Image
                src={user.image}
                alt="logo"
                className={styles.profilepic}
              />
            )}
            <h2 className={styles.heading}>
              {isEditMode ? "Edit User Details" : "User Details"}
            </h2>

            {/* Show edit button only when not in edit mode */}
            {!isEditMode && edit && (
              <button
                className={`${styles.formbtnedit}`} // Add your custom class for styling
                onClick={() => setIsEditMode(true)}
                aria-label="Edit"
              >
                <FontAwesomeIcon icon={faEdit} /> {/* Edit icon */}
                Edit
              </button>
            )}
          </div>

          <div className="container-fluid mt-5 edituserform">
            {loading ? (
              <div className="text-center mt-5">
                <SpinnerDotted size={50} color="#39979d" />
                <p>Loading user data...</p>
              </div>
            ) : user ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className={`row ${styles.profilepicdiv}`}></div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={
                        isEditMode
                          ? styles.inputboxedit
                          : styles.inputboxnotedit
                      }
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      maximLength="50"
                      readOnly={!isEditMode} // Disable input when not in edit mode
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <EmailInput
                      className={
                        isEditMode
                          ? styles.inputboxedit
                          : styles.inputboxnotedit
                      }
                      label="Email"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      placeHolder="Email"
                      readOnly={!isEditMode} // Disable input when not in edit mode
                    />
                  </div>
                </div>
                <div className="row">
                  {/* Phone Number Input */}
                  <div className="col-md-6 col-sm-6">
                    {isEditMode ? (
                      <SelectBox
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        name="role"
                        label="Role"
                        labelstyle={styles.formlabel}
                        defaultvalue={user?.role?.id}
                        control={control}
                        register={register}
                        values={roles}
                        errors={errors}
                        placeholder="Choose Role"
                        mandatory
                        Disabled={!isEditMode}
                      />
                    ) : (
                      <>
                        <label>Role</label>
                        <input
                          className={
                            isEditMode
                              ? styles.inputboxedit
                              : styles.inputboxnotedit
                          }
                          label="Role"
                          fieldName="role"
                          defaultValue={user?.role?.name}
                          // placeHolder="Enter Name"
                          // register={register}
                          // errors={errors}
                          // isRequired
                          // mandatory
                          maximLength="50"
                          readOnly={!isEditMode} // Disable input when not in edit mode
                        />
                      </>
                    )}
                  </div>

                  {isEditMode && (
                    <div className="col-md-6 col-sm-6">
                      <PasswordField
                        fieldName="password"
                        classNamedark={styles.inputbox1}
                        // labelstyle={styles.formlabel}
                        label="Password"
                        register={register}
                        errors={errors}
                        placeHolder="Enter Password"
                        // isRequired
                        minimLength={8}
                        eyeiconstyle={styles.eyeicon}
                        className={styles.emailbox}
                        labelstyle={styles.labeltext}
                        // mandatory
                      />
                    </div>
                  )}

                  {/* User Type */}
                  {/* <div className="col-md-6 col-sm-6">
                  <label>User Type</label>
                  <div className={`${styles.inputbox} ${isEditMode ? `${styles.boxDisabled}` : ''}`}>
                    <div className={`${styles.userTypeValue}`}>
                      <span>{user.userType || "N/A"}</span>
                    </div>
                  </div>
                </div> */}
                </div>
                {isEditMode && (
                  <div className={`row ${styles.fileContainer}`}>
                    <div className="col-md-6 col-sm-6">
                      <MultipleFileUpload
                        editFiles={user.image}
                        section="user"
                        disabled={!isEditMode}
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </div>
                )}
                {/* Submit button visible only in edit mode */}
                {isEditMode && (
                  <div className={styles.btnContainer}>
                    <input
                      className={styles.formbtn}
                      type="submit"
                      disabled={submitting}
                      value={submitting ? "Please wait.." : "Update"}
                    />
                  </div>
                )}

                {error && <h6 className={styles.errormsg}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
                <p>No user data found</p>
              </div>
            )}

            {/* <ToastContainer autoClose={2000} /> */}
          </div>
        </div>
      </div>
    );
  }
);

export default EditUserPopup;
