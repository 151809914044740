/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import styles from "./createCafePopup.module.scss";
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import { REACT_APP_API_ROLES_DROPDOWN, REACT_APP_API_VENDORS_LIST, SERVER_URL } from "../../redux/apiConstants";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import LocationSelectBox from "../../components/LocationSelectBox/LocationSelectBox";
import TimePickerComponent from "../../components/TimePicker/TimePicker";

const CreateCafePopup = React.memo(({ setTableData, handleClose, setIsUpdated }) => {

  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const { dashUpdate,setDashUpdate } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [text, setText] = useState();
  const [locations, setLocations] = useState([]);
  const [timeInvalid, setTimeInvalid] = useState(false);

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);

  const [timings, setTimings] = useState([
    { dayOfWeek: "Monday", startTime: "", endTime: "" },
    { dayOfWeek: "Tuesday", startTime: "", endTime: "" },
    { dayOfWeek: "Wednesday", startTime: "", endTime: "" },
    { dayOfWeek: "Thursday", startTime: "", endTime: "" },
    { dayOfWeek: "Friday", startTime: "", endTime: "" },
    { dayOfWeek: "Saturday", startTime: "", endTime: "" },
    { dayOfWeek: "Sunday", startTime: "", endTime: "" },
  ]);

  function convertTo12Hour(time24) {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  const parseTime = (time) => {
    const [hourMinute, modifier] = time.split(" ");
    const [hour, minutes] = hourMinute.split(":").map(Number);
    return [hour, minutes, modifier];
  };

  const handleTimingsChange = (index, type, value) => {
    const { startTime, endTime } = timings[index]|| {};
    console.log("sdhf", value, type)
    console.log("start", startTime)
    const convertedValue = value ? convertTo12Hour(value) : '';
    if (type === "endTime" && convertedValue && startTime && convertedValue === startTime) {
      alert("Start time and End time cannot be the same.");
      setTimings((prevTimings) =>
        prevTimings.map((timing, i) =>
          i === index ? { ...timing, [type]: '' } : timing
        )
      );
      console.log("some error 1")
      setTimeInvalid(true);
      return false;
    }

    if (type === "endTime" && value && !startTime) {
      alert("Please enter the start time");
      setTimings((prevTimings) =>
        prevTimings.map((timing, i) =>
          i === index ? { ...timing, [type]: '' } : timing
        )
      );
      console.log("some error 2")
      setTimeInvalid(true);
      return false;
    }
    
    console.log("null date:", convertedValue);
    let convertedStart = startTime;
      // if(startTime !== '') convertedStart = convertTo12Hour(startTime);
      if (type === "endTime" && value) {
        const [endHour, endMinutes, endModifier] = parseTime(convertedValue);
        const [startHour, startMinutes, startModifier] = parseTime(convertedStart);
        console.log("first", startHour, startMinutes, startModifier);
        // Prevent end time from going past 12:00 AM
        if (endHour === 12 && endModifier === "AM") {
          alert("End time cannot go past 12:00 AM.");
          setTimings((prevTimings) =>
            prevTimings.map((timing, i) =>
              i === index ? { ...timing, [type]: '' } : timing
            )
          );
          console.log("some error 3")
          setTimeInvalid(true);
          return false;
        }
    
        // Prevent end time from being earlier than the start time
        const startTotalMinutes =
          (startHour % 12) * 60 +
          startMinutes +
          (startModifier === "PM" ? 12 * 60 : 0);
        const endTotalMinutes =
          (endHour % 12) * 60 +
          endMinutes +
          (endModifier === "PM" ? 12 * 60 : 0);
        console.log("second", startTotalMinutes, endTotalMinutes);
        if (endTotalMinutes < startTotalMinutes) {
          alert("End time cannot be earlier than start time.");
          console.log("some error 4")
          setTimeInvalid(true);
          return false;
        }
      }


    setTimeInvalid(false);
    setTimings((prevTimings) => {
      const updatedTimings = prevTimings.map((timing, i) =>
        i === index ? { ...timing, [type]: convertedValue } : timing
      );
    
      // Calculate isAnyTimingFilled using the updated state
      const isAnyTimingFilled = updatedTimings.some(
        (timing) => (timing.startTime && !timing.endTime) || (!timing.startTime && timing.endTime)
      );
      
      console.log("final", isAnyTimingFilled);
      setTimeInvalid(isAnyTimingFilled);

      return updatedTimings; 
    });
    console.log("why?")
    return true;
  };

  const getVendors = async () => {
    const vendorsList = await axios.get(REACT_APP_API_VENDORS_LIST);
    console.log("vendor list---", vendorsList.data?.data?.result);
    // const transformedVendors = vendorsList.data?.data?.result?.results?.map(
    //   (vendor) => ({
    //     value: vendor.id,
    //     label: vendor.name,
    //   })
    // );
    setVendors(vendorsList.data?.data?.result);
  };

  // const getZipCodes = async () => {
  //   const zipCodeList = await axios.get(REACT_APP_API_FETCH_ZIPCODES);
  //   console.log("zip code list", zipCodeList.data?.data?.result);
  //   const transformedZipCodes = zipCodeList.data?.data?.result?.map(
  //     (zipcode) => ({
  //       value: zipcode.zipCode,
  //       label: zipcode.zipCode,
  //     })
  //   );
  //   console.log(transformedZipCodes, "transformed zipcodes");
  //   setZipCodes(transformedZipCodes);
  // };

  const getLocation = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/location?location=${text}`
      );
      console.log("location-----", response.data);
      setLocations(response.data.data);
    } catch (error) {
      console.log("erroorrr--", error);
      console.error("Error fetching location:", error);
    }
  };
  useEffect(() => {
    getLocation();
  }, [text]);
  console.log(text, "text");

  useEffect(() => {
    window.scrollTo(0, 0);
    // setDashboardHeader("Add Cafe Details");
    const url = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setRoles(resp.data.role.selectdata)); // setting response to state roles
    getVendors();
    getLocation();
    // getZipCodes();
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);

  

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    console.log("dataa--", data);

    setSubmitting(true);
    setError(null);

    // Format timings into an array
    const apiData = {
      name: data.name,
      description: data.description,
      // timings: JSON.stringify(timings),
      address: data.address,
      location_name: data.location_name.formatted_address,
      location: JSON.stringify(data.location_name.location),
      facebook: data.facebook ?? '',
      instagram: data.instagram ?? '',
      // vendor: data.Vendor,
      // zipCode: data.zipCode,
      File: files,
      timings: JSON.stringify(timings),
    };
    console.log("api data", apiData);
    data.actionUrl = "cafe";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        console.log(resp.payload.data.result);
        toast.success("Cafe Creation Successful")
        handleClose();
        setIsUpdated(true);
        if (!dashUpdate) {
          setDashUpdate(true);
        }
        setTableData((prevData) => [
          ...prevData,
          ...apiData
        ]);
        toast.success("Cafe added successfully.");
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        toast.error("Cafe creation failed due to the error:", resp.payload.message)
      } else {
        setError(resp?.payload?.message);
      }
    });
  }

  const handleLocationChange = async (value) => {
    setText(value);
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box} id={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Create Cafe</h2>
        <div className="container-fluid mt-5">
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Name"
                  fieldName="name"
                  placeHolder="Enter Name"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
              <label htmlFor="description" className={styles.formlabel}>
                  Description
                  <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                id="description"
                className={`${styles.inputbox}`}
                placeholder="Enter Description"
                {...register('description', { required: true })}
                rows={1} 
                style={{ resize: 'none', overflowY: 'auto' }}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${Math.min(e.target.scrollHeight, 80)}px`; 
                }}
              />
              {errors.description && <span className="error">Description is required</span>}
            </div>

            </div>

            <div className="row">
              {/* <div className="col-lg-6 col-md-12 col-sm-12">
                  <TimePickerComponent
                    control={control}
                    name="startTime"
                    isRequired={true}
                    errors={errors}
                    label="Start Time"
                    className="custom-timepicker"
                    mandatory
                  />
                  <TimePickerComponent
                    control={control}
                    name="endTime"
                    isRequired={true}
                    errors={errors}
                    label="End Time"
                    className="custom-timepicker"
                    mandatory
                  />
                </div> */}

              {/* {daysOfWeek.map((day) => (
                  <div className="col-lg-6 col-md-12 col-sm-12" key={day}>
                    <div className="form-group">
                      <label>{`${day} Start Time`}</label>
                      <TimePickerComponent
                        {...register(`${day.toLowerCase()}StartTime`, {
                          required: true,
                        })}
                        label={`${day} Start Time`}
                        errors={errors}
                        className="custom-timepicker"
                        // isRequired
                        control={control}
                      />
                      {errors[`${day.toLowerCase()}StartTime`] && (
                        <span>This field is required</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{`${day} End Time`}</label>
                      <TimePickerComponent
                        {...register(`${day.toLowerCase()}EndTime`, {
                          required: true,
                        })}
                        label={`${day} End Time`}
                        errors={errors}
                        className="custom-timepicker"
                        // isRequired
                        control={control}
                      />
                      {errors[`${day.toLowerCase()}EndTime`] && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                ))} */}

              {/* <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="Vendor"
                    label="Vendor"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={vendors}
                    errors={errors}
                    placeholder="Choose Vendor"
                    mandatory
                    isRequired
                  />
                </div> */}

              <div className="col-lg-6 col-md-12 col-sm-12">
                <LocationSelectBox
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  name="location_name"
                  label="Location (For GPS)"
                  labelstyle={styles.formlabel}
                  control={control}
                  register={register}
                  values={locations}
                  errors={errors}
                  placeholder="Choose Location"
                  mandatory
                  isRequired
                  setText
                  handleLocationChange={handleLocationChange}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {" "}
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Instagram"
                  placeHolder="Instagram"
                  fieldName="instagram"
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Address"
                  placeHolder="Enter Address"
                  fieldName="address"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
                {/* <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Zip Code"
                    placeHolder="Enter Zip Code"
                    fieldName="zipCode"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    minimLength={5}
                    maximLength={5} /> */}

                {/* <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="zipCode"
                    label="Zip Code"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={zipCodes}
                    errors={errors}
                    placeholder="Choose ZipCode"
                    mandatory
                    isRequired
                  /> */}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Facebook"
                  placeHolder="Facebook"
                  fieldName="facebook"
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
            </div>
            {/* cafe hours */}
            <div className="row">
              <Col lg={8} md={12} sm={12}>
                {/* Cafe Hours */}
                <h6 className={styles.heading}>Cafe Hours</h6>
                {timings.map((timing, index) => (
                  <Container key={timing.dayOfWeek}>
                    <Row className="mb-3 align-items-center">
                      <Col md={3} className="text-center">
                        {timing.dayOfWeek.charAt(0).toUpperCase() + timing.dayOfWeek.slice(1)}
                      </Col>
                      <Col md={4} className="text-center">
                      <TimePickerComponent
                            control={control}
                            name={`timings[${index}].startTime`}
                            timings={timings}
                            index={index}
                            type={"startTime"}
                            errors={errors}
                            onTimeChange={(value) => handleTimingsChange(index, "startTime", value)}
                            // readOnly={!isEditMode}
                          />
                      </Col>
                      <Col md={1} className="text-center">
                        {" - "}
                      </Col>
                      <Col md={4} className="text-center">
                      <TimePickerComponent
                            control={control}
                            name={`timings[${index}].endTime`}
                            timings={timings}
                            index={index}
                            type={"end Time"}
                            errors={errors}
                            onTimeChange={(value) => handleTimingsChange(index, "endTime", value)}
                            // readOnly={!isEditMode}
                          />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Col>

              <Col lg={4} md={12} sm={12} className={styles.formbox}>
                {/* File Upload */}
                <MultipleFileUpload                      
                      register={register}
                      errors={errors} />
              </Col>
            </div>


            {/* <div className="">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <p className={styles.formlabel}>Cover Image</p>
                <MultipleFileUpload />
              </div>
            </div> */}
            {/* <div className="row mt-3">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <MultipleFileUpload />
                  </div>
                </div> */}
              {timeInvalid && (
                  <span className={styles.errormsg}>
                    Please ensure all cafe timings are valid. Complete any missing <strong>start time</strong> or <strong>end time</strong>.
                  </span>
                )}
            <div className="d-flex justify-content-center mt-4">
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting || timeInvalid}
                value={submiting ? "Please wait.." : "Submit"}
              />
              {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
            </div>
            <br />
            {error && (
              <h6
                className={styles[errormsgStyle]}
                style={{ float: "right" }}
              >
                {error}
              </h6>
            )}
          </form>
          {/* <ToastContainer autoClose={2000} /> */}
        </div>
      </div>
    </div>
  );
});

export default CreateCafePopup;
