/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./viewPayoutRequests.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import TextInput from "../../components/TextField/TextField";
import SelectBox from "../../components/SelectBox/SelectBox";

function ViewPayoutRequests() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const dispatch = useDispatch();
  const params = useParams();
  let payoutId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [canDelete, setCanDelete] = useState(true);

  let statusCode;
  const getBankDetails = async () => {
    setLoading(true);
    axios
      .get(
        `${envValues.REACT_APP_API_ENDPOINT}/payoutaccounts/payout-details/${payoutId}`,
        {
          method: "GET",
          headers: { Authorization: token },
        }
      )
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          console.log("res.data.payout details", res.data);
          setDetails(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    payoutId = params.id;
    getBankDetails();
    setDashboardHeader("Payout Details");
  }, []);

  const handleApprove = (id) => {
    console.log("approve id", id);

    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to approve?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `payoutaccounts/initiate-payout/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                navigate(-1);
                toast.success("Payout approved successfully");
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  const handleReject = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to reject?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `payoutaccounts/reject-payout/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                navigate(-1);
                toast.success("Payout rejected successfully");
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details.image ? (
                      <Image
                        src={details.image}
                        alt="logo"
                        className={styles.profilepic}
                      />
                    ) : (
                      <div style={{ minHeight: "20px" }}></div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Vendor Name</span>
                          <p className={styles.empname}>
                            {details.userId?.name}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Payout Status</span>
                          <p className={styles.empname}>
                            {details?.payoutStatus}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Requseted Time</span>
                          <p className={styles.empname}>
                            {DateFormatter(
                              details?.requestedTime,
                              "toDateString"
                            ) ?? "NA"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Amount</span>
                          <p className={styles.empname}>
                            {details?.amount ?? 0}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {!details?.adminApproved && details.payoutStatus !== "Rejected" && (
                <Row>
                  <Col lg={4}>
                      <div id={styles.empdiv}>
                        {/* className={styles[viewformStyle]}  */}
                        <Button
                           className={`${styles.title} btn-success`}
                        onClick={() => {
                          handleApprove(details?.id);
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                      <div id={styles.empdiv}>
                      {/* className={styles[viewformStyle]}  */}
                        <Button
                           className={`${styles.title} btn-danger`}
                        onClick={() => {
                          handleReject(details?.id);
                        }}
                      >
                        Reject
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewPayoutRequests;
