/* eslint-disable global-require */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container, Button, Card } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./createMenuPopup.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import { Entry } from "../../redux/entrySlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  REACT_APP_API_DRINKTYPES,
  REACT_APP_API_ADDONS,
} from "../../redux/apiConstants";
import { useForm } from "react-hook-form";
import TextInput from "../../components/TextField/TextField";
import NumberInput from "../../components/NumberBox/NumberBox";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import { Form } from "react-bootstrap";
import SelectBox from "../../components/SelectBox/SelectBox";

const CreateMenuPopup = React.memo(({ cafeId, handleClose, setIsUpdated }) => {
  const [loadingDrinkTypes, setLoadingDrinkTypes] = useState(true);

  const [submiting, setSubmitting] = useState(false);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const [drinkTypes, setDrinkTypes] = useState([]);
  const [unit, setUnit] = useState({
    0: "ml",
    1: "ml",
    2: "ml", 
  });
  const [isRequired, setIsRequired] = useState(false);
  const [reload, setReload] = useState(false);
  const [addOnData, setAddOnData] = useState([]);
  const [menuFiles, setMenuFiles] = useState();
  const [priceErrors, setPriceErrors] = useState("");
  const [qtyErrors, setQtyErrors] = useState("");
  // const handleInputChange = (index, field, value) => {
  //   const newSizeOptions = [...sizeOptions];
  //   newSizeOptions[index][field] = value;
  //   setSizeOptions(newSizeOptions);
  // };

  const params = useParams();

  const getDrinkTypes = async () => {
    try {
      const data = {
        actionUrl: "drinktypes/list-drinktypes",
        actionMethod: "get",
      };

      const response = await dispatch(Entry(data));

      if (response.payload.code === 200) {
        setDrinkTypes(response.payload.data);
        console.log("Drink types fetched:", response.payload.data);
      } else {
        toast.error("Failed to fetch drink types: " + response.payload.message);
      }
    } catch (error) {
      console.error("Error fetching drink types:", error);
      toast.error("An unexpected error occurred while fetching drink types.");
    }
    finally {
      setLoadingDrinkTypes(false)
    }
  };

  const handleUnitChange = (event) => {
    const { name, value } = event.target; 
    const index = name.match(/\d+/)[0];
  
    setUnit((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDrinkTypes();
    setPriceErrors("");
    setDashboardHeader("Add Menu Details");
  }, []);


  const dispatch = useDispatch();
  const { files } = useContext(TableContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();


  const addOnName = watch("addOnName")
  const addOnPrice = watch("addOnPrice")

  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);

    console.log("testing---", addOnData);

    const apiData = {
      name: data.name,
      description: data.description,
      preparationTime: data.preparationTime,
      drinkType: data.drinkType,
      taxcode: data.taxcode ? data.taxcode : null,
      cafe: cafeId,
      sizeOptions: [
        {
          size: "Small",
          price: isNaN(data.smallPrice) ? null : Number(data.smallPrice),
          quantity: data.smallQuantity,
          unit: unit[0],
          note: data.smallNote,
        },
        {
          size: "Medium",
          price: isNaN(data.mediumPrice) ? null : Number(data.mediumPrice),
          quantity: data.mediumQuantity,
          unit: unit[1],
          note:
            data.mediumNote && data.mediumNote !== "" ? data.mediumNote : null,
        },
        {
          size: "Large",
          price: isNaN(data.largePrice) ? null : Number(data.largePrice),
          quantity: data.largeQuantity,
          unit: unit[2],
          note: data.largeNote,
        },
      ],
      File: files,
      addOns: addOnData,
    };

    console.log("api data", apiData);
    data.actionUrl = `menus/${cafeId}`;
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("menu added successfully");
        handleClose();
        setIsUpdated(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        setAddOnData([]);
        reset();
      } else {
        setError(resp?.payload?.message);
        setAddOnData([]);
        reset();
      }
    });
  }

  function validation(fieldIndex) {
    return (value) => {
      
      const smallPrice = parseFloat(getValues("smallPrice"));
      const mediumPrice = parseFloat(getValues("mediumPrice"));
      const largePrice = parseFloat(getValues("largePrice"));

      
      console.log("first",smallPrice,mediumPrice,largePrice);
      if (fieldIndex === 1 && mediumPrice && mediumPrice < smallPrice) {
        setPriceErrors("Medium size price cannot be less than Small size price.");
        return "Medium size price cannot be less than Small size price.";
      }
      
      if (fieldIndex === 2 && largePrice && (largePrice < mediumPrice || largePrice < smallPrice)) {
        setPriceErrors("Large size price cannot be less than Medium or Small size price.");
        return "Large size price cannot be less than Medium  or Small size price.";
      }
      
      // Remove the current field's value from the list of otherValues
      const otherValues = [
        getValues("smallPrice"),
        getValues("mediumPrice"),
        getValues("largePrice"),
      ];
      otherValues.splice(fieldIndex, 1);

      // Check if any other field has a value
      if (value || otherValues.some((val) => val)) {
        return true; // Validation passed
      }


      if (fieldIndex === 0 || fieldIndex === 1 || fieldIndex === 2) {
        setPriceErrors("At least one size price is required.");
        return "At least one size price is required.";
      }
      return true;
    };
  };

  function quantityValidation(fieldIndex) {
    return (value) => {
      const smallPrice = parseFloat(getValues("smallPrice"));
      const mediumPrice = parseFloat(getValues("mediumPrice"));
      const largePrice = parseFloat(getValues("largePrice"));
      const smallQuantity = parseFloat(getValues("smallQuantity"));
      const mediumQuantity = parseFloat(getValues("mediumQuantity"));
      const largeQuantity = parseFloat(getValues("largeQuantity"));
      // If price is provided but quantity is empty, return error
      if ((smallPrice && !smallQuantity)||(mediumPrice && !mediumQuantity)||(largePrice && !largeQuantity)) {
        setQtyErrors("Quantity is required if a price is set.");
        return "Quantity is required if a price is set.";
      }
      
      if ((!smallPrice && smallQuantity)||(!mediumPrice && mediumQuantity)||(!largePrice && largeQuantity)) {
        setQtyErrors("Price is required if quantity is specified.");
        return "Price is required if quantity is specified.";
      }
      
      if (fieldIndex === 1 && mediumQuantity && mediumQuantity < smallQuantity ) {
        setQtyErrors("Medium size quantity cannot be less than Small size quantity.");
        return "Medium size quantity cannot be less than Small size quantity.";
      }

      if (fieldIndex === 2  && largeQuantity && (largeQuantity < mediumQuantity|| largeQuantity < smallQuantity)) {
        setQtyErrors("Large size quantity cannot be less than Medium size quantity or small size quantity.");
        return "Large size quantity cannot be less than Medium size quantity or small size quantity.";
      }
  
  
      return true; // Validation passed
    };
  }

  const handleAddAddon = async () => {
    const addOnName = getValues("addOnName");
    const addOnPrice = parseFloat(getValues("addOnPrice"));

    if (!addOnName || isNaN(addOnPrice)) {
      toast.error("Please enter a valid add-on name and price.");
      return;
    }

    const newAddon = {
      name: addOnName,
      addedBy: "admin",
      price: addOnPrice,
      required: isRequired,
    };
    console.log(
      "newAddon:\n",
      newAddon,
      "\n addOnName:\n",
      addOnName,
      "\n addOnPrice:\n",
      addOnPrice
    );
    setAddOnData([...addOnData, newAddon]);
    // reset({ addOnName: "", addOnPrice: "" });
    setValue("addOnName", "");
    setValue("addOnPrice", "");

    // try {
    //   const response = await fetch(`${REACT_APP_API_ADDONS}/`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(newAddon),
    //   });

    //   if (!response.ok) {
    //     throw new Error("Failed to add the new add-on.");
    //   }

    //   const result = await response.json();
    //   setReload(!reload)

    //   reset({ addOnName: "", addOnPrice: "" });

    //   toast.success("Add-on created successfully!");
    // } catch (error) {
    //   console.error("Error creating add-on:", error);
    //   toast.error("Error creating add-on. Please try again.");
    // }
  };
  const handleDeleteAddon = async (addOnIndex) => {
    console.log(addOnIndex);
    // if (!addOnIndex) {
    //   toast.error("Invalid add-on Name.");
    //   return;
    // }

    setAddOnData((prevAddons) =>
      prevAddons.filter((_, index) => index !== addOnIndex)
    );
    toast.success("deleted add-on");
  };


  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h2 className={styles.heading}>Menu Details</h2>
        <div className="container-fluid mt-5">
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  maximLength={30}
                  label="Name"
                  fieldName="name"
                  placeHolder="Enter Name"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  maximLength={100}
                  label="Description"
                  placeHolder="Enter Description"
                  fieldName="description"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <SelectBox
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  name="drinkType"
                  label="Drink Type"
                  labelstyle={styles.formlabel}
                  control={control}
                  register={register}
                  values={loadingDrinkTypes ? [{ label: "Loading...", value: "" }] : drinkTypes} // Show loading state inside the dropdown
                  errors={errors}
                  placeholder="Choose Drink Type"
                  mandatory
                  isRequired
                />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                {" "}
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Preparation time"
                  placeHolder="Preparation time"
                  fieldName="preparationTime"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <TextInput
                  className={styles.inputbox}
                  label="Tax Code"
                  fieldName="taxcode"
                  placeHolder="Enter Tax Code"
                  register={register}
                  errors={errors}
                  // isRequired
                  // mandatory
                  maxLength="50"
                  defaultValue={" "}
                // readOnly={!isEditing}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Price for Small"
                  placeHolder="Price for Small"
                  fieldName="smallPrice"
                  validation={validation(0)}
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Price for medium"
                  placeHolder="Price for Medium"
                  fieldName="mediumPrice"
                  validation={validation(1)}
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Price for Large"
                  placeHolder="Price for Large"
                  fieldName="largePrice"
                  register={register}
                  validation={validation(2)}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
              {priceErrors && (
                <p className={styles.errormsg}>{priceErrors}</p>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-4 col-sm-4">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Small Size Quantity"
                  fieldName="smallQuantity"
                  placeHolder={`In ${unit[0]}`}
                  validation={quantityValidation(0)}
                  register={register}
                  errors={errors}
                />
              <Form.Group className={`d-flex ${styles.biggerRadio}`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[0].unit"
                    value="ml"
                    checked={unit[0] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[0].unit"
                    value="oz"
                    checked={unit[0] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    
                  />
                </Form.Group>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-4">
                <NumberInput
                  className={styles.inputbox}
                  label="Medium Size Quantity"
                  fieldName="mediumQuantity"
                  placeHolder={`In ${unit[1]}`}
                  validation={quantityValidation(1)}
                  register={register}
                  errors={errors}
                />
              <Form.Group className={`d-flex ${styles.biggerRadio}`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[1].unit"
                    value="ml"
                    checked={unit[1] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[1].unit"
                    value="oz"
                    checked={unit[1] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    
                  />
                </Form.Group>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-4">
                <NumberInput
                  className={styles.inputbox}
                  label="Large Size Quantity"
                  fieldName="largeQuantity"
                  validation={quantityValidation(2)}
                  placeHolder={`In ${unit[2]}`}
                  register={register}
                  errors={errors}
                />
              <Form.Group className={`d-flex ${styles.biggerRadio}`}>
                  <Form.Check
                    inline
                    type="radio"
                    label="ml"
                    name="sizeOptions[2].unit"
                    value="ml"
                    checked={unit[2] === "ml"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg me-2"
                    
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="oz"
                    name="sizeOptions[2].unit"
                    value="oz"
                    checked={unit[2] === "oz"}
                    onChange={handleUnitChange}
                    className="form-check-input-lg"
                    
                  />
                </Form.Group>
              </div>
              {qtyErrors && (
                <p className={styles.errormsg}>{qtyErrors}</p>
              )}
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="note"
                  fieldName="smallNote"
                  placeHolder="Enter Note for Small Sizes"
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  maximLength={100}
                  label="note"
                  placeHolder="Enter Note for Medium Sizes"
                  fieldName="mediumNote"
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  maximLength={100}
                  label="note"
                  placeHolder="Enter Note for Large Sizes"
                  fieldName="largeNote"
                  register={register}
                  errors={errors}
                // isRequired
                // mandatory
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <h4>Add-ons</h4>
                {addOnData.length > 0 ? (
                  addOnData.map((addon, index) => (
                    <div key={index} className={`${styles.addonRow} row mb-3`}>
                      <div className="col-md-3 col-sm-6">
                        <span>{addon.name}</span>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <span>${addon.price.toFixed(2)}</span>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <span>{addon.required ? "Required" : "Not Required"}</span>
                      </div>
                      <div className="col-md-3 col-sm-3 text-right">
                        <Button
                          variant="danger"
                          size="sm"
                          type="button"
                          onClick={() => handleDeleteAddon(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No add-ons available</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <TextInput
                  className={styles.inputbox}
                  label="Name"
                  fieldName="addOnName"
                  placeHolder="Name"
                  maxLength={10}
                  register={register}
                  errors={errors}
                // mandatory
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <NumberInput
                  className={styles.inputbox}
                  name="addOnPrice"
                  maximLength={2}
                  maxLength={2}
                  label="Price"
                  labelstyle={styles.formlabel}
                  fieldName="addOnPrice"
                  control={control}
                  register={register}
                  // values={[]}
                  errors={errors}
                  placeHolder="Enter Price"
                // mandatory
                />
              </div>
              <div className={`col-md-3 ${styles.required}`}>
                <Form.Check
                  className={`${styles.inputboxrequired}`}
                  type="checkbox"
                  label={<span style={{ marginLeft: "5px" }}>Required</span>}
                  checked={isRequired}
                  onChange={() => setIsRequired(!isRequired)} // Toggle the state
                />
              </div>

              <div className= {`col-md-3 ${styles.addonbutton}`}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleAddAddon}
                >
                  + Add
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* <p className={styles.formlabel}>Cover Image</p> */}
                <MultipleFileUpload
                  section={"menus"}
                  disabled={disabled}
                  setMenuFiles={setMenuFiles}
                  register={register}
                  errors={errors}
                />
                {/* <MultipleFileUpload section={"menus"} disabled={!disabled} setMenuFiles={setMenuFiles} /> */}
              </div>
            </div>
            <div className={`d-flex ${styles.submitbutton}`}>
              <input
                className={`${styles.formbtnupdate}`}
                type="submit"
                disabled={submiting || addOnName || addOnPrice}
                value={submiting ? "Please wait.." : "Submit"}
              />
              {/* <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                /> */}
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateMenuPopup;
