/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/fontawesome-free-solid"; // Edit icon added
import { Entry } from "../../redux/entrySlice";
import TextInput from "../../components/TextField/TextField";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import styles from "./editDrinktypePopup.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { TableContext } from "../../contexts/tableContext";

const EditDrinkTypePopup = React.memo(({ drinkTypeId, handleClose , setIsUpdated}) => {
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const { control, register, handleSubmit, formState: { errors }, reset } = useForm();

  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State for edit/view mode

  useEffect(() => {
    const actionData = {
      actionUrl: `drinktypes/${drinkTypeId}`,
      actionMethod: "get",
    };

    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload.data.drinkType) {
        setUser(resp.payload.data.drinkType);
        reset(resp.payload.data.drinkType);
      }
    });
  }, [drinkTypeId, dispatch, reset]);

  const onSubmitData = (data) => {
    setSubmitting(true);
    setError(null);
    const apiData = {
      type: data.type,
      taxcode: data.taxcode? data.taxcode :null,
      File: files,
    };
    data.actionUrl = `drinktypes/${drinkTypeId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;

    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Drink type updated successfully");
        setIsEditing(false); // Switch back to view mode after update
        handleClose();
        setIsUpdated(true);
      } else {
        setError(resp.payload.message);
        toast.error("Error updating drink type");
      }
    });
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing); // Toggle between edit and view modes
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box}>
        {/* Flex container for close button, heading, and edit button */}
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.closeicon}
            aria-label="Close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {/* Conditionally render the heading based on isEditing */}
          <h2 className={styles.heading}>
            {isEditing ? "Edit Drink Type Details" : "Drink Type Details"}
          </h2>

          {/* Show edit button only when not in edit mode */}
          {!isEditing && (
            <button
              type="button"
              className={`${styles.formbtnedit} d-flex align-items-center`} // Add alignment for text and icon
              onClick={toggleEditMode}
            >
              <FontAwesomeIcon icon={faEdit} style={{ color: 'white', marginRight: '5px' }} />
              <span style={{ color: 'white' }}>Edit</span> {/* Add white "Edit" text */}
            </button>
          )}
        </div>

        <div className="container-fluid mt-5 editdrinktypeform">
          {user ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Drink Type"
                    fieldName="type"
                    placeHolder="Enter Drink Type"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maxLength="50"
                    defaultValue={user.type}
                    readOnly={!isEditing}  
                  />
                    <TextInput
                    className={styles.inputbox}
                    label="Tax Code"
                    fieldName="taxcode"
                    placeHolder="Enter Tax Code"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                    maxLength="50"
                    defaultValue={" "}
                    readOnly={!isEditing}  

                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <MultipleFileUpload
                    editFiles={user?.image}
                    section="drinktype"
                    disabled={!isEditing}
                    register={register}
                    errors={errors}
                  />
                </div>
               
              </div>
              <div className="row mt-3">
              <div className="col-md-6 col-sm-6">
                
                </div>
              </div>

              {/* Show Update button only when in edit mode */}
              {isEditing && (
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submitting}
                  value={submitting ? "Please wait.." : "Update"}
                />
              )}

              {error && <h6 className={styles.errormsg}>{error}</h6>}
            </form>
          ) : (
            <div style={{ textAlign: "center", marginTop: "130px", color: "#39979d" }}>
              <SpinnerDotted />
            </div>
          )}
          <ToastContainer autoClose={2000} />
        </div>
      </div>
    </div>
  );
});

export default EditDrinkTypePopup;
