import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Image, Row, Button, Modal } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import TimePickerComponent from "../../components/TimePicker/TimePicker";
import CustomTable from "../../components/CustomTable/CustomTable";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import SelectBox from "../../components/SelectBox/SelectBox";

import { REACT_APP_API_FETCH_MENUS } from "../../redux/apiConstants";
import styles from "./editCafePopUp.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { faEdit } from "@fortawesome/fontawesome-free-solid";
import { TableContext } from "../../contexts/tableContext";
import { useNavigate, useParams } from "react-router-dom";
import { getCookies } from "../../hooks/useCookies";
import { confirmAlert } from "react-confirm-alert";

function SuspendCafeModal({ show, handleClose, cafeId }) {
  const [submiting, setSubmitting] = useState(false);

  const periods = [
    {
      value: "1week",
      label: "1 Week",
    },
    {
      value: "1month",
      label: "1 Month",
    },
    {
      value: "6month",
      label: "6 Month",
    },
  ];

  const { errormsgStyle, setSuspend } = useContext(TableContext);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmitdata(data) {

    setSubmitting(true);
    const apiData = {
      suspendReason: data.reason,
      suspendPeriod: data.period,
    };
    console.log("ANU", cafeId);
    data.actionUrl = `cafe/suspend-cafe/${cafeId}`;
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      console.log("responsee-----", resp);
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Cafe suspended successfully");
        setSuspend(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        toast.success("Cafe suspended successfully");
      } else {
        setError(resp.payload.message);
      }
    });
  }

  const handleSuspend = () => {
    // Validate reason and period if needed
    // suspendCafe(reason, period);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Suspend Cafe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmitdata)}>
          <div>
            <TextInput
              className={styles.inputbox}
              classNamedark={styles.inputbox1}
              labelstyle={styles.formlabel}
              label="Reason"
              fieldName="reason"
              placeHolder="Enter reason"
              register={register}
              errors={errors}
              isRequired
              mandatory
            />
          </div>
          <div>
            <SelectBox
              className={styles.inputbox}
              classNamedark={styles.inputbox1}
              name="period"
              label="Period"
              labelstyle={styles.formlabel}
              control={control}
              register={register}
              values={periods}
              errors={errors}
              placeholder="Select Period"
              mandatory
              isRequired
            />
          </div>
          <Button color="primary" type="submit">
            Suspend
          </Button>{" "}
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {error && (
            <h6 className={styles[errormsgStyle]} style={{ float: "right" }}>
              {error}
            </h6>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

const EditCafePopup = React.memo(({ cafeId, setTableData, handleClose, initialAction = "view", setIsUpdated }) => {


  const { suspend, setSuspend } = useContext(TableContext);
  const params = useParams();
  // let payoutId;
  const token = `Bearer ${getCookies("Token")}`;
  const [details, setDetails] = useState([]);
  const { setDashUpdate } = useContext(TableContext);
  const dateFormat = getCookies("dateFormat");
  const [canDelete, setCanDelete] = useState(true);
  const [cafeList, setCafesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cafe, setCafe] = useState(null);
  const [error, setError] = useState(null);
  const [action, setAction] = useState(initialAction);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { files } = useContext(TableContext);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const handleApprove = async (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure, you want to approve the cafe request ?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/approve-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                // navigate(-1);
                handleClose()
                setDashUpdate(prevDashUpdate => !prevDashUpdate);
                setIsUpdated(true);
                setSuspend(false);
                toast.success("Cafe request approved successfully");
              })
              .catch((err) => {
                toast.error("Error approving cafe request");
                console.error("Error: ", err);
              });;
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };
  const handleReject = async (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure, you want to reject the cafe request ?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/reject-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData))
                .then(() => {
                // navigate(-1);
                handleClose()
                setIsUpdated(true);
                setSuspend(false);
                toast.success("Cafe request rejected successfully");
                })
                .catch((err) => {
                  toast.error("Error rejecting cafe request");
                  console.error("Error: ", err);
                });;
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };


  const handleDelete = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to delete?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/${id}`;
              actionData.actionMethod = "delete";
              dispatch(Entry(actionData)).then(() => {
                navigate('/cafe');
                handleClose();
                setIsUpdated(true);
                toast.success("Cafe deleted successfully");
                setTableData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  const removeSuspension = (id) => {
    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: "Are you sure you want to remove suspension?",
      buttons: canDelete
        ? [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `cafe/un-suspend-cafe/${id}`;
              actionData.actionMethod = "post";
              dispatch(Entry(actionData)).then(() => {
                // navigate(-1);
                setSuspend(false);
                toast.success("Cafe unsuspended successfully");
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ]
        : [
          {
            label: "Ok",
            // onClick: () => alert("Click No")
          },
        ],
    });
  };

  useEffect(() => {
    const actionData = { actionUrl: `cafe/${cafeId}`, actionMethod: "get" };
    setLoading(true);
    dispatch(Entry(actionData)).then((resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.cafe) {
        setCafe(resp.payload.data.cafe);
        // console.log(resp.payload.data.cafe);
        if (!resp.payload.data.cafe.image || resp.payload.data.cafe.image.length === 0) {
          setImage(null);
        } else {
          setImage(resp.payload.data.cafe.image);
        }
        console.log("image-->", resp.payload.data.cafe.image);
        setLoading(false);
        reset({
          ...resp.payload.data.cafe,
          phone: resp.payload.data.cafe.vendor?.phone,
          email: resp.payload.data.cafe.vendor?.email,
        });
        setTimings(resp.payload.data.cafe.timings);
      } else {
        console.error("Cafe data not found in response:", resp);
        setCafe(null);
      }
    });
  }, [cafeId, dispatch, reset]);

  const onSubmitData = (data) => {
    setSubmitting(true);
    setError(null);
    console.log("image-->", files[0])
    const apiData = {
      name: data.name,
      address: data.address,
      timings: timings,
      File: files,
    };

    console.log("apiData,", apiData)
    data.actionUrl = `cafe/${cafeId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success("Cafe updated successfully");
        handleClose();
        setIsUpdated(true);
        setAction("view");
      } else {
        setError(resp.payload.message);
        toast.error("Error updating cafe");
      }
    });
  };

  const [timings, setTimings] = useState([
    { dayOfWeek: "Monday", startTime: "", endTime: "" },
    { dayOfWeek: "Tuesday", startTime: "", endTime: "" },
    { dayOfWeek: "Wednesday", startTime: "", endTime: "" },
    { dayOfWeek: "Thursday", startTime: "", endTime: "" },
    { dayOfWeek: "Friday", startTime: "", endTime: "" },
    { dayOfWeek: "Saturday", startTime: "", endTime: "" },
    { dayOfWeek: "Sunday", startTime: "", endTime: "" },
  ]);

  const handleTimingsChange = (index, type, value) => {
    const { startTime, endTime } = timings[index] || {};

    if (type === "endTime" && value && startTime && value === startTime) {
      alert("Start time and End time cannot be the same.");
      return;
    }

    setTimings((prevTimings) =>
      prevTimings.map((timing, i) =>
        i === index ? { ...timing, [type]: value } : timing
      )
    );
  };

  return (
    <div className={styles.popupbox}>
      <div className={styles.box}>
        {/* Flex container for header and button */}
        <div className="d-flex align-items-center gap-3">
          <button
            className={styles.closeicon}
            aria-label="Close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {image && (<Image
            src={image}
            alt="logo"
            className={styles.profilepic}
          />
          )}
          <h2 className={styles.heading}>
            {isEditMode ? "Edit Cafe Details" : "Cafe Details"}
          </h2>
          {!isEditMode && (
            <button
              className={`${styles.formbtnedit} `}
              onClick={() => setIsEditMode(true)}
              aria-label="Edit"
            >
              <FontAwesomeIcon icon={faEdit} /> {/* Pen icon */}
              Edit
            </button>
          )}

        </div>

        <div className="container-fluid mt-5 editcafeform">
          {cafe ? (
            <form onSubmit={handleSubmit(onSubmitData)}>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Cafe Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Address"
                    fieldName="address"
                    placeHolder="Enter Cafe Address"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Location (For GPS)"
                    fieldName="location_name"
                    placeHolder="Enter Location"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    readOnly={!isEditMode}
                  />
                </div>

                {!isEditMode && (<>

                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Phone Number"
                      fieldName="phone"
                      placeHolder="Enter Phone Number"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      readOnly={!isEditMode}
                    />
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <EmailInput
                      className={styles.inputbox}
                      label="Email"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      placeHolder="Email"
                      readOnly={!isEditMode}
                    />
                  </div>



                </>)}

              </div>

              <Row>
                {cafe?.cafeStatus === "Pending" && (
                  <>
                    <Col lg={2}>
                      <div id={styles.empdiv}>
                        <Button
                          className={`${styles.title} btn-success`}
                          onClick={() => handleApprove(cafeId)}
                        >
                          Approve Cafe
                        </Button>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div id={styles.empdiv}>
                        <Button
                          className={`${styles.title} btn-danger`}
                          onClick={() => handleReject(cafeId)}
                        >
                          Reject Cafe
                        </Button>
                      </div>
                    </Col>
                  </>
                )}

                <Col lg={2}>
                  <div
                    // className={styles[viewformStyle]}
                    id={styles.empdiv}
                  >
                    {suspend ? (
                      <Button
                        className={styles.title}
                        onClick={() => removeSuspension(cafeId)}
                      >
                        Remove Suspension
                      </Button>
                    ) : (
                      <Button
                        className={`${styles.title} btn-secondary`}
                        onClick={() => setShowModal(true)}
                      >
                        Suspend Cafe
                      </Button>
                    )}

                    <SuspendCafeModal
                      show={showModal}
                      handleClose={handleCloseModal}
                      cafeId={cafeId}
                    />
                  </div>
                </Col>
                <Col lg={2}>
                  <div
                    // className={styles[viewformStyle]}
                    id={styles.empdiv}
                  >
                    <Button
                      className={`${styles.title} btn-danger`}
                      onClick={() => {
                        handleDelete(cafeId);
                      }}
                    >
                      Delete Cafe
                    </Button>
                  </div>
                </Col>
                {details?.cafeStatus === "Pending" && <Row></Row>}
                {!details?.isClaimed && !details.isClaimed && (
                  // <Row>
                  <Col lg={4}>
                    <div
                      // className={styles[viewformStyle]}
                      id={styles.empdiv}
                    >
                      <Button
                        className={`${styles.title} btn-primary`}
                        disabled={details?.claimRequests?.length === 0}
                        onClick={() =>
                          navigate(`/claim-requests/${params.cafeId}`)
                        }
                      >
                        Claim Requests: {details?.claimRequests?.length || 0}
                      </Button>
                    </div>
                  </Col>
                  // </Row>
                )}
              </Row>
              <div className="row">
                <h6 className={styles.headinghours}>Cafe Hours</h6>
                {timings.map((timing, index) => (
                  <Container key={timing.dayOfWeek}>
                    <Row className="mb-3 align-items-center">
                      <Col md={3} className="text-center">
                        {timing.dayOfWeek.charAt(0).toUpperCase() +
                          timing.dayOfWeek.slice(1)}
                      </Col>

                      <Col md={4} className="text-center">
                        <TimePickerComponent
                          control={control}
                          name={`timings[${index}].startTime`}
                          errors={errors}
                          onTimeChange={(value) => handleTimingsChange(index, "startTime", value)}
                          readOnly={!isEditMode}
                        />
                      </Col>

                      <Col md={1} className="text-center">
                        {" "}
                        -{" "}
                      </Col>

                      <Col md={4} className="text-center">
                        <TimePickerComponent
                          control={control}
                          name={`timings[${index}].endTime`}
                          errors={errors}
                          onTimeChange={(value) => handleTimingsChange(index, "endTime", value)}
                          readOnly={!isEditMode}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </div>
              {isEditMode && (<div className="row mt-3">
                <div className="col-md-6 col-sm-6">
                  <MultipleFileUpload section="cafe" disabled={!isEditMode} 
                                        register={register}
                                        errors={errors}/>
                  {/* <MultipleFileUpload section="menus" disabled={!isEditMode} /> */}
                </div>



                {error && <h6 className={styles.errormsg}>{error}</h6>}
              </div>)}

              {/* Update Button at the Bottom */}
              {isEditMode && (
                <div className="row mt-4">
                  <div className="col text-center">
                    <input
                      className={`${styles.formbtn} btn-sm`}
                      type="submit"
                      style={{ marginBottom: "100px" }}
                      disabled={submitting}
                      value={submitting ? "Please wait.." : "Update"}
                    />
                  </div>
                </div>
              )}
            </form>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "130px",
                color: "#39979d",
              }}
            >
              <SpinnerDotted />
            </div>
          )}
          <ToastContainer autoClose={2000} />
        </div>

        <Row>
          <h3 className={styles.heading}>Menu</h3>
        </Row>

        <Row>
          <Col lg={12}>
            <CustomTable
              urlParam={`${REACT_APP_API_FETCH_MENUS}/${cafeId}?`}
              viewPopUp={true}
              paramId={cafeId}
              section="Menus"
              toggleButton={{ show: true, field: "active" }}
              deleteCondition={{
                checkCondition: false,
                apiUrl: "www.google.com",
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
);

export default EditCafePopup;
