import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Image, Row, Button, Modal } from "react-bootstrap";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import TimePickerComponent from "../../components/TimePicker/TimePicker";
import CustomTable from "../../components/CustomTable/CustomTable";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import SelectBox from "../../components/SelectBox/SelectBox";

import {
  REACT_APP_API_FETCH_MENUS,
  SERVER_URL,
} from "../../redux/apiConstants";
import styles from "./editCafePopUp.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { faEdit } from "@fortawesome/fontawesome-free-solid";
import { TableContext } from "../../contexts/tableContext";
import { useNavigate, useParams } from "react-router-dom";
import { getCookies } from "../../hooks/useCookies";
import { confirmAlert } from "react-confirm-alert";
import LocationSelectBox from "../../components/LocationSelectBox/LocationSelectBox";
import axios from "axios";
import envValues from "../../enviornment";

function SuspendCafeModal({ show, handleClose, cafeId }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const periods = [
    { value: "1week", label: "1 Week" },
    { value: "1month", label: "1 Month" },
    { value: "6month", label: "6 Month" },
  ];

  const { setSuspend } = useContext(TableContext);
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      reason: "",
      period: "",
    },
  });

  const handleSuspendCafe = (data) => {
    setSubmitting(true);
    setError(null);

    if (!data.reason || !data.period) {
      setError("Please provide both reason and suspension period");
      setSubmitting(false);
      return;
    }

    const apiData = {
      suspendReason: data.reason,
      suspendPeriod: data.period,
    };

    const actionData = {
      actionUrl: `cafe/suspend-cafe/${cafeId}`,
      actionMethod: "post",
      apiData: apiData,
    };

    dispatch(Entry(actionData))
      .then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("Cafe suspended successfully");
          setSuspend(true);
          handleClose();
          reset(); // Reset form after successful submission
        } else {
          setError(resp.payload.message || "Error suspending cafe");
          toast.error("Failed to suspend cafe");
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError("Network error. Please try again.");
        toast.error("Network error suspending cafe");
        console.error("Suspend Cafe Error:", error);
      });
  };

  const handleClickSuspend = () => {
    handleSubmit(handleSuspendCafe)(); // Trigger the form validation and submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Suspend Cafe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <TextInput
            className={styles.inputbox}
            labelstyle={styles.formlabel}
            label="Reason"
            fieldName="reason"
            placeHolder="Enter reason for suspension"
            register={register}
            errors={errors}
            isRequired
            mandatory
          />
          <SelectBox
            className={styles.inputbox}
            name="period"
            label="Suspension Period"
            labelstyle={styles.formlabel}
            control={control}
            register={register}
            values={periods}
            errors={errors}
            placeholder="Select Suspension Period"
            mandatory
            isRequired
          />
          <div className="d-flex justify-content-between mt-3">
            <Button
              color="primary"
              onClick={handleClickSuspend}
              disabled={submitting}
            >
              {submitting ? "Suspending..." : "Suspend Cafe"}
            </Button>
            <Button
              color="secondary"
              onClick={handleClose}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
          {error && <div className="text-danger mt-2">{error}</div>}
        </form>
      </Modal.Body>
    </Modal>
  );
}

const AddOnModal = ({ show, handleClose, cafeId, fetchAddons }) => {
  const [addonSet, setAddonSet] = useState({
    name: "",
    addOns: [{ name: "", price: "" }],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // Handle changes in addOn fields
  const handleAddonChange = (index, field, value) => {
    const updatedAddOns = [...addonSet.addOns];
    updatedAddOns[index][field] = value;

    // Clear error message on typing
    setErrors((prev) => ({ ...prev, [`addon-${index}-${field}`]: "" }));

    setAddonSet({ ...addonSet, addOns: updatedAddOns });
  };

  // Add new addon field
  const addNewAddon = () => {
    setAddonSet({
      ...addonSet,
      addOns: [...addonSet.addOns, { name: "", price: "" }],
    });
  };

  // Remove addon field (prevent deleting all)
  const removeAddon = (index) => {
    if (addonSet.addOns.length > 1) {
      const updatedAddOns = addonSet.addOns.filter((_, i) => i !== index);
      setAddonSet({ ...addonSet, addOns: updatedAddOns });
    }
  };

  // Validate input fields
  const validate = () => {
    let tempErrors = {};
    if (!addonSet.name.trim()) {
      tempErrors.name = "Addon Set Name is required";
    }

    addonSet.addOns.forEach((addon, index) => {
      if (!addon.name.trim()) {
        tempErrors[`addon-${index}-name`] = "Addon name is required";
      }
      if (!addon.price.trim() || isNaN(addon.price) || addon.price <= 0) {
        tempErrors[`addon-${index}-price`] = "Valid price is required";
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Save function
  const onSave = async () => {
    if (validate()) {
      setLoading(true);
      // Perform save operation (API call, etc.)
      console.log("Saving Addons:", addonSet);

      const actionData = {
        actionUrl: `cafe/add-addons/${cafeId}`,
        actionMethod: "post",
        apiData: addonSet,
      };

      dispatch(Entry(actionData))
        .then((resp) => {
          setLoading(false);
          if (resp.payload.code === 200) {
            toast.success("Addon created successfully");
            setAddonSet({
              name: "",
              addOns: [{ name: "", price: "" }],
            });
            if (fetchAddons) {
              fetchAddons();
            }
            handleClose();
          } else {
            toast.error("Failed to create addon");
          }
        })
        .catch((error) => {
          console.error("Suspend Cafe Error:", error);
        });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Addon Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          {/* Addon Set Name */}
          <input
            type="text"
            placeholder="Addon Set Name"
            value={addonSet.name}
            onChange={(e) => {
              setAddonSet({ ...addonSet, name: e.target.value });
              setErrors((prev) => ({ ...prev, name: "" }));
            }}
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "5px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          {errors.name && (
            <div
              style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}
            >
              {errors.name}
            </div>
          )}

          <h5>Addons:</h5>
          {addonSet.addOns.map((addon, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {/* Addon Name Input */}
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  placeholder="Addon Name"
                  value={addon.name}
                  onChange={(e) =>
                    handleAddonChange(index, "name", e.target.value)
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                {errors[`addon-${index}-name`] && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors[`addon-${index}-name`]}
                  </div>
                )}
              </div>

              {/* Addon Price Input */}
              <div style={{ flex: 1 }}>
                <input
                  type="number"
                  placeholder="Price"
                  value={addon.price}
                  onChange={(e) =>
                    handleAddonChange(index, "price", e.target.value)
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                {errors[`addon-${index}-price`] && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors[`addon-${index}-price`]}
                  </div>
                )}
              </div>

              {/* Remove Button (Disabled if only one addon remains) */}
              <button
                onClick={() => removeAddon(index)}
                disabled={addonSet.addOns.length === 1}
                style={{
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  border: "none",
                  padding: "5px 10px",
                  cursor:
                    addonSet.addOns.length === 1 ? "not-allowed" : "pointer",
                  borderRadius: "5px",
                  opacity: addonSet.addOns.length === 1 ? 0.6 : 1,
                }}
              >
                X
              </button>
            </div>
          ))}

          {/* Add More Button */}
          <button
            onClick={addNewAddon}
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              padding: "8px 12px",
              cursor: "pointer",
              borderRadius: "5px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            + Add More
          </button>

          {/* Action Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              onClick={onSave}
              disabled={loading}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                padding: "8px 12px",
                cursor: "pointer",
                borderRadius: "5px",
                flex: 1,
                marginRight: "5px",
              }}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#dc3545",
                color: "#fff",
                border: "none",
                padding: "8px 12px",
                cursor: "pointer",
                borderRadius: "5px",
                flex: 1,
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const OptionsModal = ({ show, handleClose, cafeId, fetchOptions }) => {
  const [optionsSet, setOptionsSet] = useState({
    name: "",
    values: [{ name: "" }],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // Handle changes in options values
  const handleOptionChange = (index, field, value) => {
    const updatedValues = [...optionsSet.values];
    updatedValues[index][field] = value;

    setErrors((prev) => ({ ...prev, [`value-${index}-${field}`]: "" }));

    setOptionsSet({ ...optionsSet, values: updatedValues });
  };

  // Add new option value
  const addNewValue = () => {
    setOptionsSet({
      ...optionsSet,
      values: [...optionsSet.values, { name: "" }],
    });
  };

  // Remove option value (prevent deleting all)
  const removeValue = (index) => {
    if (optionsSet.values.length > 1) {
      const updatedValues = optionsSet.values.filter((_, i) => i !== index);
      setOptionsSet({ ...optionsSet, values: updatedValues });
    }
  };

  // Validate input fields
  const validate = () => {
    let tempErrors = {};
    if (!optionsSet.name.trim()) {
      tempErrors.name = "Option Set Name is required";
    }

    optionsSet.values.forEach((value, index) => {
      if (!value.name.trim()) {
        tempErrors[`value-${index}-name`] = "Value name is required";
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Save function
  const onSave = async () => {
    if (validate()) {
      setLoading(true);

      const actionData = {
        actionUrl: `cafe/add-options/${cafeId}`,
        actionMethod: "post",
        apiData: optionsSet,
      };

      dispatch(Entry(actionData))
        .then((resp) => {
          setLoading(false);
          if (resp.payload.code === 200) {
            toast.success("Option set created successfully");
            setOptionsSet({
              name: "",
              values: [{ name: "" }],
            });

            fetchOptions();

            handleClose();
          } else {
            toast.error("Failed to create option set");
          }
        })
        .catch((error) => {
          console.error("Option Set Error:", error);
        });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Option Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          {/* Option Set Name */}
          <input
            type="text"
            placeholder="Option Set Name"
            value={optionsSet.name}
            onChange={(e) => {
              setOptionsSet({ ...optionsSet, name: e.target.value });
              setErrors((prev) => ({ ...prev, name: "" }));
            }}
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "5px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          {errors.name && (
            <div
              style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}
            >
              {errors.name}
            </div>
          )}

          <h5>Values:</h5>
          {optionsSet.values.map((value, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  placeholder="Value Name"
                  value={value.name}
                  onChange={(e) =>
                    handleOptionChange(index, "name", e.target.value)
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                {errors[`value-${index}-name`] && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors[`value-${index}-name`]}
                  </div>
                )}
              </div>
              <button
                onClick={() => removeValue(index)}
                disabled={optionsSet.values.length === 1}
                style={{
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  border: "none",
                  padding: "5px 10px",
                  cursor:
                    optionsSet.values.length === 1 ? "not-allowed" : "pointer",
                  borderRadius: "5px",
                  opacity: optionsSet.values.length === 1 ? 0.6 : 1,
                }}
              >
                X
              </button>
            </div>
          ))}

          {/* Add More Button */}
          <button
            onClick={addNewValue}
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              padding: "8px 12px",
              cursor: "pointer",
              borderRadius: "5px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            + Add More
          </button>

          {/* Action Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              onClick={onSave}
              disabled={loading}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                padding: "8px 12px",
                cursor: "pointer",
                borderRadius: "5px",
                flex: 1,
                marginRight: "5px",
              }}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#dc3545",
                color: "#fff",
                border: "none",
                padding: "8px 12px",
                cursor: "pointer",
                borderRadius: "5px",
                flex: 1,
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const EditCafePopup = React.memo(
  ({
    cafeId,
    setTableData,
    handleClose,
    initialAction = "view",
    setIsUpdated,
    edit = true,
  }) => {
    const { suspend, setSuspend } = useContext(TableContext);
    const params = useParams();
    // let payoutId;
    const token = `Bearer ${getCookies("Token")}`;
    const [details, setDetails] = useState([]);
    const { setDashUpdate } = useContext(TableContext);
    const dateFormat = getCookies("dateFormat");
    const [canDelete, setCanDelete] = useState(true);
    const [cafeList, setCafesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAddOnModal, setShowAddOnModal] = useState(false);
    const [showOptionsModal, setShowOptionsModal] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [cafe, setCafe] = useState(null);
    const [error, setError] = useState(null);
    const [action, setAction] = useState(initialAction);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { files } = useContext(TableContext);
    const [image, setImage] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const textareaRef = useRef(null);
    const [text, setText] = useState();
    const [locations, setLocations] = useState([]);
    const [locationDefault, setLocationDefault] = useState([]);
    const [claimRequestCount, setClaimCount] = useState(0);
    const [timeInvalid, setTimeInvalid] = useState(false);
    const [addOnSets, setAddOnSets] = useState([]);
    const [optionsSets, setOptionsSets] = useState([]);
    const [commissionValue, setCommissionValue] = useState();

    let defaultLocation;
    const [timings, setTimings] = useState([
      { dayOfWeek: "Monday", startTime: "", endTime: "" },
      { dayOfWeek: "Tuesday", startTime: "", endTime: "" },
      { dayOfWeek: "Wednesday", startTime: "", endTime: "" },
      { dayOfWeek: "Thursday", startTime: "", endTime: "" },
      { dayOfWeek: "Friday", startTime: "", endTime: "" },
      { dayOfWeek: "Saturday", startTime: "", endTime: "" },
      { dayOfWeek: "Sunday", startTime: "", endTime: "" },
    ]);

    const getLocation = async (text) => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/location?location=${text}`
        );
        console.log("location-----", response.data);
        setLocations(response.data.data);
      } catch (error) {
        console.log("erroorrr--", error);
        console.error("Error fetching location:", error);
      }
    };
    useEffect(() => {
      if (text?.length > 0) getLocation(text);
      //   setLocationDefault([{
      //     formatted_address: locations[0].geometry.location,
      //     location: locations[0].geometry.location,
      // }])
    }, [text]);
    console.log(text, "text");

    const handleCloseModal = () => {
      setShowModal(false);
    };

    const handleCloseAddOnModal = () => {
      setShowAddOnModal(false);
    };

    const handleCloseOptionsModal = () => {
      setShowOptionsModal(false);
    };

    const {
      control,
      register,
      watch,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();

    const handleApprove = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to approve the cafe request ?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/approve-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData))
                    .then(() => {
                      // navigate(-1);
                      handleClose();
                      setDashUpdate((prevDashUpdate) => !prevDashUpdate);
                      setIsUpdated(true);
                      setSuspend(false);
                      toast.success("Cafe request approved successfully");
                    })
                    .catch((err) => {
                      toast.error("Error approving cafe request");
                      console.error("Error: ", err);
                    });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };

    const handleLocationChange = async (value) => {
      setText(value);
    };

    const handleReject = async (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to reject the cafe request ?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/reject-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData))
                    .then(() => {
                      // navigate(-1);
                      handleClose();
                      setIsUpdated(true);
                      setSuspend(false);
                      toast.success("Cafe request rejected successfully");
                    })
                    .catch((err) => {
                      toast.error("Error rejecting cafe request");
                      console.error("Error: ", err);
                    });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };

    const handleDelete = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to delete?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/${id}`;
                  actionData.actionMethod = "delete";
                  dispatch(Entry(actionData)).then(() => {
                    navigate("/cafe");
                    handleClose();
                    setIsUpdated(true);
                    toast.success("Cafe deleted successfully");
                    setTableData((prevData) =>
                      prevData.filter((item) => item.id !== id)
                    );
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };

    const removeSuspension = (id) => {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: "Are you sure you want to remove suspension?",
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `cafe/un-suspend-cafe/${id}`;
                  actionData.actionMethod = "post";
                  dispatch(Entry(actionData)).then(() => {
                    // navigate(-1);
                    setSuspend(false);
                    toast.success("Cafe unsuspended successfully");
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    };

    function convertTo24Hour(time12) {
      const [time, period] = time12.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    }

    useEffect(() => {
      const actionData = { actionUrl: `cafe/${cafeId}`, actionMethod: "get" };
      setTimeInvalid(false);
      setLoading(true);
      dispatch(Entry(actionData)).then((resp) => {
        if (resp.payload && resp.payload.data && resp.payload.data.cafe) {
          const cafeData = resp.payload.data.cafe;
          const cafeTimings = cafeData.timings;
          const convertedTimings = cafeTimings.map(
            ({ dayOfWeek, startTime, endTime }) => ({
              dayOfWeek,
              startTime: startTime ? convertTo24Hour(startTime) : "",
              endTime: endTime ? convertTo24Hour(endTime) : "",
            })
          );
          cafeData.timings = convertedTimings;
          console.log("cafe data", cafeData.timings);
          setClaimCount(cafeData?.claimRequestsCount || 0);
          console.log("count", cafeData?.claimRequestsCount);
          setCafe(resp.payload.data.cafe);
          setCommissionValue(resp.payload.data.cafe?.commission || "Disable");
          console.log("cafe", resp.payload.data.cafe);
          if (
            !resp.payload.data.cafe.image ||
            resp.payload.data.cafe.image.length === 0
          ) {
            setImage(null);
          } else {
            setImage(resp.payload.data.cafe.image);
          }
          setLoading(false);
          setSuspend(resp.payload.data.cafe.isSuspended);
          reset({
            ...resp.payload.data.cafe,
            phone: resp.payload.data.cafe.vendor?.phone,
            email: resp.payload.data.cafe.vendor?.email,
          });
          setText(resp.payload.data.cafe.location_name);
          getLocation(resp.payload.data.cafe.location_name);
          // setLocationDefault([{
          //     formatted_address: resp.payload.data.cafe.location_name,
          //     location: resp.payload.data.cafe.location,
          // }])

          const textarea = document.getElementById("description");
          if (textarea) {
            adjustTextareaHeight(textarea);
          }
          setTimings(cafeData.timings);
          const isAnyTimingFilled = cafeTimings.some(
            (timing) =>
              (timing.startTime && !timing.endTime) ||
              (!timing.startTime && timing.endTime)
          );
          console.log("first", isAnyTimingFilled);
          setTimeInvalid(isAnyTimingFilled);
        } else {
          console.error("Cafe data not found in response:", resp);
          setCafe(null);
        }
      });
    }, [cafeId, dispatch, reset]);

    function convertTo12Hour(time24) {
      const [hours, minutes] = time24.split(":").map(Number);
      if (time24.includes("AM") || time24.includes("PM")) {
        return time24;
      }
      const period = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
      return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
    }

    const onSubmitData = (data) => {
      setSubmitting(true);
      setError(null);
      // console.log("image-->", files)
      const convertedTimings = timings.map(
        ({ dayOfWeek, startTime, endTime }) => ({
          dayOfWeek,
          startTime: startTime ? convertTo12Hour(startTime) : "",
          endTime: endTime ? convertTo12Hour(endTime) : "",
        })
      );
      const apiData = {
        name: data.name,
        address: data.address,
        description: data.description,
        location_name:
          data.location_name?.formatted_address ??
          locations[0].formatted_address,
        location: JSON.stringify(
          data.location_name?.location ?? locations[0].geometry.location
        ),
        timings: convertedTimings,
        facebook: data.facebook ?? "",
        instagram: data.instagram ?? "",
        commission: data.commission ?? "Disable",
        commissionCount: data.commissionCount ?? 0,
        File: files,
      };

      console.log("apiData,", apiData);
      data.actionUrl = `cafe/${cafeId}`;
      data.actionMethod = "patch";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success("Cafe updated successfully");
          handleClose();
          setIsUpdated(true);
          setAction("view");
        } else {
          setError(resp.payload.message);
          toast.error("Error updating cafe");
        }
      });
    };

    const parseTime = (time) => {
      const [hourMinute, modifier] = time.split(" ");
      const [hour, minutes] = hourMinute.split(":").map(Number);
      return [hour, minutes, modifier];
    };

    function is12HourFormat(time) {
      const regex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
      return regex.test(time);
    }

    const handleTimingsChange = (index, type, value) => {
      const { startTime, endTime } = timings[index] || {};
      console.log("sdhf", value, type);
      let convertTimeStart;
      const convertedValue = value ? convertTo12Hour(value) : "";
      if (startTime) {
        // Check if startTime is in 12-hour format
        convertTimeStart = is12HourFormat(startTime)
          ? startTime
          : convertTo12Hour(startTime);
      } else {
        convertTimeStart = "";
      }
      console.log("start", startTime);
      if (
        type === "endTime" &&
        convertedValue &&
        convertTimeStart &&
        convertedValue === convertTimeStart
      ) {
        alert("Start time and End time cannot be the same.");
        setTimings((prevTimings) =>
          prevTimings.map((timing, i) =>
            i === index ? { ...timing, [type]: "" } : timing
          )
        );
        console.log("some error 1");
        setTimeInvalid(true);
        return false;
      }

      if (type === "endTime" && value && !startTime) {
        alert("Please enter the start time");
        setTimings((prevTimings) =>
          prevTimings.map((timing, i) =>
            i === index ? { ...timing, [type]: "" } : timing
          )
        );
        console.log("some error 2");
        setTimeInvalid(true);
        return false;
      }

      console.log("null date:", convertedValue);
      let convertedStart;
      if (startTime !== "") convertedStart = convertTo12Hour(startTime);
      if (type === "endTime" && value) {
        const [endHour, endMinutes, endModifier] = parseTime(convertedValue);
        const [startHour, startMinutes, startModifier] =
          parseTime(convertedStart);

        // Prevent end time from going past 12:00 AM
        if (endHour === 12 && endModifier === "AM") {
          alert("End time cannot go past 12:00 AM.");
          setTimings((prevTimings) =>
            prevTimings.map((timing, i) =>
              i === index ? { ...timing, [type]: "" } : timing
            )
          );
          console.log("some error 3");
          setTimeInvalid(true);
          return false;
        }

        // Prevent end time from being earlier than the start time
        const startTotalMinutes =
          (startHour % 12) * 60 +
          startMinutes +
          (startModifier === "PM" ? 12 * 60 : 0);
        const endTotalMinutes =
          (endHour % 12) * 60 +
          endMinutes +
          (endModifier === "PM" ? 12 * 60 : 0);

        if (endTotalMinutes < startTotalMinutes) {
          alert("End time cannot be earlier than start time.");
          setTimings((prevTimings) =>
            prevTimings.map((timing, i) =>
              i === index ? { ...timing, [type]: "" } : timing
            )
          );
          console.log("some error 4");
          setTimeInvalid(true);
          return false;
        }
      }

      setTimeInvalid(false);
      setTimings((prevTimings) => {
        const updatedTimings = prevTimings.map((timing, i) =>
          i === index ? { ...timing, [type]: convertedValue } : timing
        );

        // Calculate isAnyTimingFilled using the updated state
        const isAnyTimingFilled = updatedTimings.some(
          (timing) =>
            (timing.startTime && !timing.endTime) ||
            (!timing.startTime && timing.endTime)
        );

        console.log("final", isAnyTimingFilled);
        setTimeInvalid(isAnyTimingFilled);

        return updatedTimings;
      });
      console.log("why?");
      return true;
    };

    const adjustTextareaHeight = (element) => {
      if (element) {
        element.style.height = "auto"; // Reset height to default
        element.style.height = `${Math.min(element.scrollHeight, 200)}px`; // Set new height with max limit of 200px
      }
    };

    const fetchAddons = async () => {
      try {
        setLoading(true);
        const addonResponse = await axios.get(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/cafe-addons/${cafeId}`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );
        const addons = addonResponse.data?.data?.result?.addons;
        console.log("addons---", addons);
        setAddOnSets(addons);
        setUpdatedAddonSets(addons);
      } catch (error) {
        console.log("Error----", error);
      }
    };

    const fetchOptions = async () => {
      try {
        console.log("fetch options---");
        setLoading(true);
        const optionsResponse = await axios.get(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/cafe-options/${cafeId}`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );
        const options = optionsResponse.data?.data?.result?.options;
        console.log("options---", options);
        setOptionsSets(options);
        setUpdatedOptions(options);
      } catch (error) {
        console.log("Error----", error);
      }
    };

    useEffect(() => {
      fetchAddons();
      fetchOptions();
      fetchNonMigratedRatingMenus();
      fetchAvailableDrinks();
    }, []);

    const [isEditing, setIsEditing] = useState(null); // Track which addonSet is being edited
    const [updatedAddonSets, setUpdatedAddonSets] = useState();

    const handleEditClick = (addonSetId) => {
      console.log("Addons set id--", addonSetId);
      setUpdatedAddonSets(addOnSets);
      setIsEditing(addonSetId);
    };

    const handleAddonSetNameChange = (setIndex, newName) => {
      const newAddonSets = [...updatedAddonSets];
      newAddonSets[setIndex].name = newName;
      setUpdatedAddonSets(newAddonSets);
    };

    const handleAddonNameChange = (setIndex, addonIndex, newName) => {
      const newAddonSets = [...updatedAddonSets];
      newAddonSets[setIndex].addOns[addonIndex].name = newName;
      setUpdatedAddonSets(newAddonSets);
    };

    const handleInputChange = (setId, addonIndex, newPrice) => {
      const newAddonSets = updatedAddonSets.map((set) =>
        set.id === setId || set._id === setId
          ? {
              ...set,
              addOns: set.addOns.map((addon, i) =>
                i === addonIndex ? { ...addon, price: Number(newPrice) } : addon
              ),
            }
          : set
      );
      setUpdatedAddonSets(newAddonSets);
    };

    const handleAddAddon = (setIndex) => {
      const newAddonSets = [...updatedAddonSets];
      newAddonSets[setIndex].addOns.push({
        _id: Date.now().toString(),
        name: "New Addon",
        price: 0,
      });
      setUpdatedAddonSets(newAddonSets);
    };

    const handleRemoveAddon = (setIndex, addonIndex) => {
      const newAddonSets = [...updatedAddonSets];
      newAddonSets[setIndex].addOns.splice(addonIndex, 1);
      setUpdatedAddonSets(newAddonSets);
    };

    const handleSave = async () => {
      const addonSetToUpdate = updatedAddonSets.find(
        (set) => (set.id || set._id) === isEditing
      );
      console.log("isEditting update---", isEditing);

      console.log("addonseto update---", addonSetToUpdate);
      try {
        const addonResponse = await axios.post(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/update-addons/${isEditing}`,
          {
            addonSetToUpdate,
          },
          {
            headers: { Authorization: token },
          }
        );
        if (addonResponse.status === 200) {
          console.log("Updated successfully:", addonResponse.data);
          setIsEditing(null);
        }
        fetchAddons();
      } catch (err) {
        console.error("Error updating add-ons:", err);
        setError("Failed to update. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const handleCancel = () => {
      setUpdatedAddonSets(addOnSets); // Reset values
      setIsEditing(null); // Exit edit mode
    };

    const handleDeleteAddon = async (addSetId) => {
      try {
        console.log("addon set id", addSetId);
        const addonResponse = await axios.delete(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/delete-addons/${addSetId}`,
          {
            headers: { Authorization: token },
          }
        );
        if (addonResponse.status === 200) {
          console.log("deleted successfully:", addonResponse.data);
        }
        fetchAddons();
      } catch (err) {
        console.error("Error updating add-ons:", err);
        setError("Failed to update. Please try again.");
      }
    };

    // ################ Options Section###################
    const [isEditingOption, setIsEditingOption] = useState(null); // Track which addonSet is being edited
    const [updatedOptions, setUpdatedOptions] = useState([]);
    const [nonMigratedDrinks, setNonMigratedDrinks] = useState([]);
    const [availableDrinks, setAvailableDrinks] = useState([]);

    const handleEditClickOption = (optionId) => {
      setIsEditingOption(optionId);
    };

    // Handle input change
    const handleInputChangeOption = (optionId, field, value) => {
      console.log("field value options=----", field, value);
      console.log("field value options=----", optionId);

      console.log("updated options=----", updatedOptions);
      setUpdatedOptions((prev) =>
        prev.map((option) =>
          option.id === optionId ? { ...option, [field]: value } : option
        )
      );
    };

    // Handle input change for value fields
    const handleInputChangeValue = (optionId, valueId, field, value) => {
      setUpdatedOptions((prev) =>
        prev.map((option) =>
          option.id === optionId
            ? {
                ...option,
                values: option.values.map((val) =>
                  val._id === valueId ? { ...val, [field]: value } : val
                ),
              }
            : option
        )
      );
    };

    const handleRemoveOptionValue = (optionId, valueId) => {
      setUpdatedOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === optionId
            ? {
                ...option,
                values: option.values.filter((val) => val._id !== valueId),
              }
            : option
        )
      );
    };

    const [newOptionValues, setNewOptionValues] = useState({});

    // Function to add a new value to an existing option set
    const handleAddNewValue = (optionId) => {
      const newValue = newOptionValues[optionId]?.trim();
      if (!newValue) return;

      setUpdatedOptions((prevOptions) =>
        prevOptions.map((opt) =>
          opt.id === optionId
            ? {
                ...opt,
                values: [...opt.values, { name: newValue }],
              }
            : opt
        )
      );

      // Clear input field after adding
      setNewOptionValues((prev) => ({
        ...prev,
        [optionId]: "",
      }));
    };
    console.log("update options 123---", updatedOptions);

    // Save changes
    const handleSaveOption = async (optionId) => {
      try {
        console.log("update options---", updatedOptions);

        const optionSetToUpdate = Object.values(updatedOptions).find(
          (set) => (set.id || set._id) === optionId
        );

        console.log("update fields---", optionSetToUpdate);
        const optionsResponse = await axios.post(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/update-options/${isEditingOption}`,
          {
            optionSetToUpdate,
          },
          {
            headers: { Authorization: token },
          }
        );

        setIsEditingOption(null);
        fetchOptions(); // Refresh options after update
      } catch (error) {
        console.error("Error updating option:", error);
      }
    };

    // -----------Non migrated ratings------------
    const fetchNonMigratedRatingMenus = async () => {
      try {
        const drinks = await axios.get(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/non-migrated-rating-menus/${cafeId}`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );
        console.log(
          "non migrated dirnks---",
          drinks?.data?.data?.result?.drinks
        );
        setNonMigratedDrinks(drinks?.data?.data?.result?.drinks);
      } catch (error) {
        console.log("Error----", error);
      }
    };

    const fetchAvailableDrinks = async () => {
      try {
        const drinks = await axios.get(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/available-drinks-to-migrate/${cafeId}`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );
        console.log(
          "non migrated dirnks---",
          drinks?.data?.data?.result?.drinks
        );
        setAvailableDrinks(drinks?.data?.data?.result?.drinks);
      } catch (error) {}
    };

    const [selectedDrinkId, setSelectedDrinkId] = useState();

    const handleSelectionChange = (selectedId) => {
      setSelectedDrinkId(selectedId);
    };

    const handleDeleteMigration = async (drinkId) => {
      try {
        confirmAlert({
          title: "Confirm Deletion",
          message:
            "Are you sure you want to delete this drink without migration?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const data = {
                  actionUrl: `cafe/delete-migration/${drinkId}`,
                  actionMethod: "delete",
                };
                dispatch(Entry(data))
                  .then((resp) => {
                    if (resp.payload.code === 200) {
                      toast.success("Rating migration deleted successfully");
                      fetchNonMigratedRatingMenus();
                      fetchAvailableDrinks();
                    } else {
                      toast.error("Error deleting migration");
                    }
                  })
                  .catch((error) => {
                    toast.error("Error deleting migration. Please try again.");
                  });
              },
            },
            {
              label: "No",
            },
          ],
        });
      } catch (error) {}
    };

    const handleMigration = async (drinkId, selectedDrinkId) => {
      try {
        console.log("drinkIdss---", drinkId, selectedDrinkId);
        if (!drinkId || !selectedDrinkId) {
          toast.error("Please select a valid drink before migrating.");
          return;
        }
        await axios.post(
          `${envValues.REACT_APP_API_ENDPOINT}/cafe/migrate-rating/${drinkId}`,
          {
            selectedDrinkId,
          },
          {
            headers: { Authorization: token },
          }
        );

        toast.success("Rating migration completed successfully");
        fetchNonMigratedRatingMenus();
        fetchAvailableDrinks();
      } catch (error) {
        toast.error("Rating migration failed");
      }
    };

    //-------------Commission Model--------------------
    const commissions = [
      { value: "Auto Enable", label: "Auto Enable" },
      { value: "Enable", label: "Enable" },
      { value: "Disable", label: "Disable" },
    ];

    console.log("commission value=---", commissionValue);
    const selectedCommission = watch("commission");
    console.log("Data commision--", selectedCommission);

    return (
      <div className={styles.popupbox}>
        <div className={styles.box}>
          {/* Flex container for header and button */}
          <div className="d-flex align-items-center gap-3">
            <button
              className={styles.closeicon}
              aria-label="Close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {image && (
              <Image src={image} alt="logo" className={styles.profilepic} />
            )}
            <h2 className={styles.heading}>
              {isEditMode ? "Edit Cafe Details" : "Cafe Details"}
            </h2>
            {!isEditMode && edit && (
              <button
                className={`${styles.formbtnedit} `}
                onClick={() => setIsEditMode(true)}
                aria-label="Edit"
              >
                <FontAwesomeIcon icon={faEdit} /> {/* Pen icon */}
                Edit
              </button>
            )}
          </div>

          <div className="container-fluid mt-5 editcafeform">
            {cafe ? (
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Cafe Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      readOnly={!isEditMode}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="description" className={styles.formlabel}>
                      Description
                    </label>
                    <textarea
                      id="description"
                      className={`${styles.inputbox}`}
                      placeholder="Enter Description"
                      {...register("description", { required: true })}
                      rows={1}
                      // readOnly={!isEditMode}
                      disabled={!isEditMode}
                      style={{ resize: "none", overflowY: "auto" }}
                      onInput={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = `${Math.min(
                          e.target.scrollHeight,
                          80
                        )}px`;
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Address"
                      fieldName="address"
                      placeHolder="Enter Cafe Address"
                      register={register}
                      errors={errors}
                      maximLength={100}
                      isRequired
                      mandatory
                      readOnly={!isEditMode}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <LocationSelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="location_name"
                      label="Location (For GPS)"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={locations}
                      errors={errors}
                      placeholder={text}
                      defaultValue={text}
                      disabled={!isEditMode}
                      mandatory
                      isRequired
                      setText
                      handleLocationChange={handleLocationChange}
                    />
                  </div>
                </div>
                {!isEditMode && (
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <TextInput
                        className={styles.inputbox}
                        label="Phone Number"
                        fieldName="phone"
                        placeHolder="Enter Phone Number"
                        register={register}
                        errors={errors}
                        isRequired
                        mandatory
                        readOnly={!isEditMode}
                      />
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <EmailInput
                        className={styles.inputbox}
                        label="Email"
                        fieldName="email"
                        register={register}
                        errors={errors}
                        placeHolder="Email"
                        readOnly={!isEditMode}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Facebook"
                      placeHolder="Facebook"
                      fieldName="facebook"
                      register={register}
                      errors={errors}
                      readOnly={!isEditMode}

                      // isRequired
                      // mandatory
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Instagram"
                      placeHolder="Instagram"
                      fieldName="instagram"
                      register={register}
                      errors={errors}
                      readOnly={!isEditMode}

                      // isRequired
                      // mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <SelectBox
                      className={styles.inputbox}
                      name="commission"
                      label="Commission model"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={commissions}
                      errors={errors}
                      placeholder="Select Commision Model"
                      mandatory
                      isRequired
                      defaultvalue={commissionValue}
                      Disabled={!isEditMode}
                    />
                  </div>
                  {selectedCommission === "Auto Enable" && (
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      {" "}
                      <TextInput
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Commission orders count"
                        placeHolder="Commission orders count"
                        fieldName="commissionCount"
                        register={register}
                        errors={errors}
                        readOnly={!isEditMode}

                        // isRequired
                        // mandatory
                      />
                    </div>
                  )}
                </div>

                {edit && (
                  <Row>
                    {cafe?.cafeStatus === "Pending" && (
                      <>
                        <Col lg={2}>
                          <div id={styles.empdiv}>
                            <Button
                              className={`${styles.title} btn-success`}
                              onClick={() => handleApprove(cafeId)}
                            >
                              Approve Cafe
                            </Button>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div id={styles.empdiv}>
                            <Button
                              className={`${styles.title} btn-danger`}
                              onClick={() => handleReject(cafeId)}
                            >
                              Reject Cafe
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}

                    <Col lg={2}>
                      <div
                        // className={styles[viewformStyle]}
                        id={styles.empdiv}
                      >
                        {suspend ? (
                          <Button
                            className={styles.title}
                            onClick={() => removeSuspension(cafeId)}
                          >
                            Remove Suspension
                          </Button>
                        ) : (
                          <Button
                            className={`${styles.title} btn-secondary`}
                            onClick={() => setShowModal(true)}
                          >
                            Suspend Cafe
                          </Button>
                        )}

                        <SuspendCafeModal
                          show={showModal}
                          handleClose={handleCloseModal}
                          cafeId={cafeId}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div
                        // className={styles[viewformStyle]}
                        id={styles.empdiv}
                      >
                        <Button
                          className={`${styles.title} btn-danger`}
                          onClick={() => {
                            handleDelete(cafeId);
                          }}
                        >
                          Delete Cafe
                        </Button>
                      </div>
                    </Col>
                    {details?.cafeStatus === "Pending" && <Row></Row>}
                    {!details?.isClaimed && !details.isClaimed && (
                      // <Row>
                      <Col lg={4}>
                        <div
                          // className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <Button
                            className={`${styles.title} btn-primary`}
                            disabled={details?.claimRequests?.length === 0}
                            onClick={() => navigate(`/claimrequests`)}
                          >
                            Claim Requests: {claimRequestCount}
                          </Button>
                        </div>
                      </Col>
                      // </Row>
                    )}
                  </Row>
                )}

                {isEditMode && (
                  <div
                    className={`col-md-4 col-lg-4 col-sm-6 ${styles.fileupload}`}
                    style={{ margin: "10px" }}
                  >
                    <MultipleFileUpload
                      section="cafe"
                      disabled={!isEditMode}
                      register={register}
                      errors={errors}
                    />
                    {/* <MultipleFileUpload section="menus" disabled={!isEditMode} /> */}
                    {error && <h6 className={styles.errormsg}>{error}</h6>}
                  </div>
                )}

                {isEditMode && timeInvalid && (
                  <span className={styles.errormsg}>
                    Please ensure all cafe timings are valid. Complete any
                    missing <strong>start time</strong> or{" "}
                    <strong>end time</strong>.
                  </span>
                )}

                <div className="col-md-8 col-lg-8 col-sm-6">
                  <h6 className={styles.headinghours}>Cafe Hours</h6>
                  {timings.map((timing, index) => (
                    <Container key={timing.dayOfWeek}>
                      <Row className="mb-3 align-items-center">
                        <Col md={3} className="text-center">
                          {timing.dayOfWeek.charAt(0).toUpperCase() +
                            timing.dayOfWeek.slice(1)}
                        </Col>

                        <Col md={4} className="text-center">
                          <TimePickerComponent
                            control={control}
                            name={`timings[${index}].startTime`}
                            timings={timings}
                            index={index}
                            type={"startTime"}
                            errors={errors}
                            onTimeChange={(value) =>
                              handleTimingsChange(index, "startTime", value)
                            }
                            readOnly={!isEditMode}
                          />
                        </Col>

                        <Col md={1} className="text-center">
                          {" "}
                          -{" "}
                        </Col>

                        <Col md={4} className="text-center">
                          <TimePickerComponent
                            control={control}
                            name={`timings[${index}].endTime`}
                            timings={timings}
                            index={index}
                            type={"endTime"}
                            errors={errors}
                            onTimeChange={(value) =>
                              handleTimingsChange(index, "endTime", value)
                            }
                            readOnly={!isEditMode}
                          />
                        </Col>
                      </Row>
                    </Container>
                  ))}
                </div>

                {/* Update Button at the Bottom */}
                {isEditMode && (
                  <div className="row mt-4">
                    <div className="col text-center">
                      <input
                        className={`${styles.formbtn} btn-sm`}
                        type="submit"
                        style={{ marginBottom: "100px" }}
                        disabled={submitting || timeInvalid}
                        value={submitting ? "Please wait.." : "Update"}
                      />
                    </div>
                  </div>
                )}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
              </div>
            )}

            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <h4 className={styles.headinghours}>AddOns</h4>
                {!cafe?.posConnected && edit && (
                  <button
                    onClick={() => setShowAddOnModal(true)}
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      border: "none",
                      padding: "8px 12px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    + Add Addon
                  </button>
                )}

                <AddOnModal
                  show={showAddOnModal}
                  handleClose={handleCloseAddOnModal}
                  cafeId={cafeId}
                  fetchAddons={fetchAddons}
                />

                <div
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {updatedAddonSets?.length > 0 ? (
                    updatedAddonSets?.map((addonSet, setIndex) => (
                      <div key={addonSet._id} style={{ marginBottom: "15px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {isEditing === (addonSet?.id || addonSet?._id) ? (
                            <input
                              type="text"
                              value={addonSet.name}
                              onChange={(e) =>
                                handleAddonSetNameChange(
                                  setIndex,
                                  e.target.value
                                )
                              }
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "3px",
                                padding: "5px",
                              }}
                            />
                          ) : (
                            <h5
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                              }}
                            >
                              {addonSet.name}
                            </h5>
                          )}

                          {cafe?.application === "Baristi" ||
                          addonSet?.application === "Baristi" ? (
                            isEditing === (addonSet?.id || addonSet?._id) ? (
                              <>
                                <button
                                  onClick={handleSave}
                                  style={{
                                    backgroundColor: "#28a745",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                    marginRight: "5px",
                                  }}
                                >
                                  Save
                                </button>
                                <button
                                  onClick={handleCancel}
                                  style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              !cafe?.posConnected &&
                              edit && (
                                <>
                                  <button
                                    onClick={() =>
                                      handleEditClick(
                                        addonSet?.id || addonSet?._id
                                      )
                                    }
                                    style={{
                                      backgroundColor: "#007bff",
                                      color: "#fff",
                                      border: "none",
                                      padding: "5px 10px",
                                      cursor: "pointer",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDeleteAddon(
                                        addonSet?.id || addonSet?._id
                                      )
                                    }
                                    style={{
                                      backgroundColor: "red",
                                      color: "#fff",
                                      border: "none",
                                      padding: "5px 10px",
                                      cursor: "pointer",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </>
                              )
                            )
                          ) : null}
                        </div>

                        {/* Addons List */}
                        <ul
                          style={{
                            listStyleType: "none",
                            paddingLeft: "10px",
                            margin: "0",
                          }}
                        >
                          {addonSet.addOns.map((addon, index) => (
                            <li
                              key={addon._id}
                              style={{
                                fontSize: "14px",
                                padding: "5px 0",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {isEditing === (addonSet?.id || addonSet?._id) ? (
                                <>
                                  <input
                                    type="text"
                                    value={addon.name}
                                    onChange={(e) =>
                                      handleAddonNameChange(
                                        setIndex,
                                        index,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      width: "120px",
                                      padding: "5px",
                                      marginRight: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                    }}
                                  />
                                  <input
                                    type="number"
                                    value={addon.price}
                                    onChange={(e) =>
                                      handleInputChange(
                                        addonSet.id || addonSet?._id,
                                        index,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      width: "70px",
                                      padding: "5px",
                                      marginLeft: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                    }}
                                  />
                                  <button
                                    onClick={() =>
                                      handleRemoveAddon(setIndex, index)
                                    }
                                    style={{
                                      backgroundColor: "#dc3545",
                                      color: "#fff",
                                      border: "none",
                                      padding: "5px",
                                      cursor: "pointer",
                                      borderRadius: "3px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Remove
                                  </button>
                                </>
                              ) : (
                                <>
                                  {addon.name} -{" "}
                                  <strong>${addon?.price}</strong>
                                </>
                              )}
                            </li>
                          ))}
                        </ul>

                        {/* Add New Addon */}
                        {isEditing === (addonSet?.id || addonSet?._id) &&
                          !cafe?.posConnected &&
                          edit && (
                            <button
                              onClick={() => handleAddAddon(setIndex)}
                              style={{
                                backgroundColor: "#28a745",
                                color: "#fff",
                                border: "none",
                                padding: "5px 10px",
                                cursor: "pointer",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              Add Addon
                            </button>
                          )}
                      </div>
                    ))
                  ) : (
                    <p style={{ fontSize: "14px", color: "#777" }}>
                      No AddOns available
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <h4 className={styles.headinghours}>Options</h4>{" "}
                {!cafe?.posConnected && edit && (
                  <button
                    onClick={() => setShowOptionsModal(true)}
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      border: "none",
                      padding: "8px 12px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    + Add Options
                  </button>
                )}
                <OptionsModal
                  show={showOptionsModal}
                  handleClose={handleCloseOptionsModal}
                  cafeId={cafeId}
                  fetchOptions={fetchOptions}
                />
                <div
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {optionsSets.length > 0 ? (
                    optionsSets.map((option) => {
                      // Find the updated option from state
                      const updatedOption =
                        updatedOptions.find((opt) => opt.id === option.id) ||
                        option;

                      return (
                        <div key={option.id} style={{ marginBottom: "15px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h5
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {isEditingOption === option.id ? (
                                <input
                                  type="text"
                                  value={updatedOption.name}
                                  onChange={(e) =>
                                    handleInputChangeOption(
                                      option.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    padding: "5px",
                                    border: "1px solid #ccc",
                                    borderRadius: "3px",
                                  }}
                                />
                              ) : (
                                updatedOption.name
                              )}
                            </h5>

                            {isEditingOption === option.id ? (
                              <>
                                <button
                                  onClick={() => handleSaveOption(option.id)}
                                  style={{
                                    backgroundColor: "#28a745",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                    marginRight: "5px",
                                  }}
                                >
                                  Save
                                </button>
                                <button
                                  onClick={() => setIsEditingOption(null)}
                                  style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              !cafe?.posConnected &&
                              edit && (
                                <button
                                  onClick={() =>
                                    handleEditClickOption(option.id)
                                  }
                                  style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Edit
                                </button>
                              )
                            )}
                          </div>

                          {/* Show Values inside each Option */}
                          {(updatedOption.values || []).length > 0 && (
                            <ul
                              style={{ marginTop: "10px", paddingLeft: "15px" }}
                            >
                              {(updatedOption.values || []).map(
                                (value, index) => {
                                  const updatedValue =
                                    updatedOption.values.find(
                                      (val) => val._id === value._id
                                    ) || value;

                                  return (
                                    <li
                                      key={value._id}
                                      style={{
                                        fontSize: "14px",
                                        color: "#333",
                                      }}
                                    >
                                      {isEditingOption === option.id ? (
                                        <>
                                          <input
                                            type="text"
                                            value={updatedValue.name}
                                            onChange={(e) =>
                                              handleInputChangeValue(
                                                option.id,
                                                value._id,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              padding: "5px",
                                              border: "1px solid #ccc",
                                              borderRadius: "3px",
                                            }}
                                          />
                                          {/* <button
                                          onClick={() =>
                                            handleRemoveOptionValue(
                                              option.id,
                                              value._id
                                            )
                                          }
                                        >
                                          Delete
                                        </button> */}
                                        </>
                                      ) : (
                                        updatedValue.name
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                          {isEditingOption === option.id && (
                            <div
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="New Option Name"
                                value={newOptionValues[option.id] || ""}
                                onChange={(e) =>
                                  setNewOptionValues((prev) => ({
                                    ...prev,
                                    [option.id]: e.target.value,
                                  }))
                                }
                                style={{
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  flex: "1",
                                }}
                              />
                              <button
                                onClick={() => handleAddNewValue(option.id)}
                                style={{
                                  backgroundColor: "#28a745",
                                  color: "#fff",
                                  border: "none",
                                  padding: "5px 10px",
                                  cursor: "pointer",
                                  borderRadius: "3px",
                                }}
                              >
                                + Add
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p style={{ fontSize: "14px", color: "#777" }}>
                      No options available
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

      

          <Row>
            <h3 className={styles.heading}>Menu</h3>
          </Row>

          <Row>
            <Col lg={12}>
              <CustomTable
                urlParam={`${REACT_APP_API_FETCH_MENUS}/${cafeId}?`}
                viewPopUp={true}
                paramId={cafeId}
                section="Menus"
                toggleButton={{ show: true, field: "active" }}
                deleteCondition={{
                  checkCondition: false,
                  apiUrl: "www.google.com",
                }}
                posConnected={cafe?.posConnected}
                edit={edit}
              />
            </Col>
          </Row>
          {nonMigratedDrinks?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
              }}
            >
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                {nonMigratedDrinks?.length > 0 && (
                  <div
                    style={{
                      padding: "15px",
                      maxWidth: "600px",
                      margin: "auto",
                      background: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "15px",
                      }}
                    >
                      Non Migrated Ratings
                    </h3>
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                      {nonMigratedDrinks.map((drink) => (
                        <li
                          key={drink._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            borderBottom: "1px solid #ddd",
                            background: "#fff",
                            borderRadius: "5px",
                            marginBottom: "8px",
                          }}
                        >
                          <span style={{ flex: 2, fontWeight: "500" }}>
                            {drink.name} -{" "}
                            <span style={{ color: "#555" }}>
                              Ratings: {drink?.totalRating}
                            </span>
                          </span>

                          <select
                            value={selectedDrinkId || ""}
                            onChange={(e) =>
                              handleSelectionChange(e.target.value)
                            }
                            style={{
                              padding: "6px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              flex: 1,
                              margin: "0 10px",
                            }}
                          >
                            <option value="" disabled>
                              Select a drink
                            </option>
                            {availableDrinks.map((option) => (
                              <option
                                key={option?._id || option?.id}
                                value={option?._id || option?.id}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={() =>
                              handleMigration(
                                drink._id || drink.id,
                                selectedDrinkId
                              )
                            }
                            disabled={!selectedDrinkId}
                            style={{
                              backgroundColor: selectedDrinkId
                                ? "green"
                                : "gray",
                              color: "white",
                              padding: "6px 12px",
                              border: "none",
                              cursor: selectedDrinkId
                                ? "pointer"
                                : "not-allowed",
                              borderRadius: "4px",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                          >
                            Migrate
                          </button>

                          <button
                            onClick={() =>
                              handleDeleteMigration(drink?._id || drink?.id)
                            }
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: "6px 12px",
                              border: "none",
                              cursor: "pointer",
                              borderRadius: "4px",
                              fontSize: "14px",
                            }}
                          >
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default EditCafePopup;
