import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TableContext } from "../../contexts/tableContext";
import { getCookies } from "../../hooks/useCookies";
import envValues from "../../enviornment";
import styles from "./MultipleFileUpload.module.scss";

const MultipleFileUpload = ({ 
  editFiles, 
  section, 
  disabled, 
  setMenuFiles, 
  fieldName, 
  register, 
  errors, 
  required 
}) => {
  const [edited, setEdited] = useState(editFiles);
  const [uploadedImg, setUploadedImg] = useState(null);
  const { setFiles } = useContext(TableContext);
  const fileInputRef = useRef(null);
  const token = `Bearer ${getCookies("Token")}`;
  const params = useParams();
  const { userId } = params;
  
  const pdfImg = "https://thesoftwarepro.com/wp-content/uploads/2019/12/microsoft-office-pdf-document-953x1024.jpg";
  const videoImg = "https://image.shutterstock.com/image-vector/play-button-icon-vector-illustration-260nw-1697833306.jpg";
   
  useEffect(() => {
    return () => {
      if (uploadedImg?.imgurl) {
        URL.revokeObjectURL(uploadedImg.imgurl);
      }
      setUploadedImg(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (section === 'menu') {
        setMenuFiles(null);
      } else {
        setFiles(null);
      }
    };
  }, []);
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    const allowedExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const extension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      toast.error("Only image files (JPG, JPEG, PNG, GIF, WEBP) are allowed.");
      return;
    }

    const isVideo = ["mp4", "mov"].includes(extension);
    const isPdf = extension === "pdf";
    
    const newImgData = {
      imgurl: URL.createObjectURL(file),
      extension: isVideo ? "video" : isPdf ? "pdf" : "image",
      file
    };

    setUploadedImg(newImgData);
    
    if (section === 'menu') {
      setMenuFiles([file]);
    } else {
      setFiles([file]);
    }
  };

  const registerProps = register(fieldName, {
    required: {
      value: required,
      message: `Image is required.`,
    },
    onChange: (e) => handleImageChange(e)
  });


  const handleCancelButton = () => {
    setUploadedImg(null);
    
    if (section === 'menu') {
      setMenuFiles(null);
    } else {
      setFiles(null);
    }
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveButton = async (index) => {
    try {
      const response = await axios.delete(
        `${envValues.REACT_APP_API_ENDPOINT}/${section}/${userId}/${index}`,
        {
          headers: { Authorization: token },
        }
      );
      setEdited(response.data.data.user.files);
      toast.success("File removed!");
    } catch (error) {
      toast.error("Failed to remove file");
    }
  };

  return (
    <div className="form-group">
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <div className="file-uploader-mask d-flex justify-content-center align-items-center" />
          <input
            ref={(e) => {
              fileInputRef.current = e;
              registerProps.ref(e);
            }}
            className="file-input"
            name={fieldName}
            id={`${section}Upload`}
            type="file"
            accept="image/*"
            onChange={(e) => {
              registerProps.onChange(e);
            }}
            onBlur={registerProps.onBlur}
            hidden
            disabled={disabled}
          />

          <label htmlFor={`${section}Upload`} className={styles.upload_btn}>
            Choose File
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      </div>

      {!disabled && (
        <div className="d-flex justify-content-center flex-wrap mt-3">
          {Array.isArray(edited) &&
            edited.map((uploadedImg, index) => (
              <div key={index} className={styles.close_icon_main}>
                <img
                  src={uploadedImg}
                  style={{ height: 100, width: 100, objectFit: "contain" }}
                  alt="UploadedImage"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      uploadedImg.extension === "video" ? videoImg : pdfImg;
                  }}
                  className="img-thumbnail img-fluid uploaded-img mr-5"
                />
                {/* <button
                  type="button"
                  className={styles.close_icon}
                  onClick={() => handleRemoveButton(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 19 19"
                    stroke="#60677e"
                    strokeWidth="2"
                    className="react-date-picker__clear-button__icon react-date-picker__button__icon"
                    style={{ marginTop: "-10px", marginLeft: "-3px" }}
                  >
                    <line x1="4" x2="15" y1="4" y2="15" />
                    <line x1="15" x2="4" y1="4" y2="15" />
                  </svg>
                </button> */}
              </div>
            ))}
        </div>
      )}

    {uploadedImg && (
      <div className={`d-flex justify-content-center flex-wrap ${styles.fileShow}`}>
          <div className={styles.uploaded_imgsection}>
            <img
              src={uploadedImg.imgurl}
              alt="UploadedImage"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  uploadedImg.extension === "video" ? videoImg : pdfImg;
              }}
              className="img-thumbnail img-fluid uploaded-img mr-2"
              id={styles.uploaded_img}
            />
            <button
              type="button"
              onClick={handleCancelButton}
              className={styles.imgcancel_btn}
            >
              Remove
            </button>
          </div>
      </div>
        )}
      {errors[fieldName] && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "14px",
            fontFamily: "SFUIDisplayRegular",
          }}
        >
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
};

MultipleFileUpload.propTypes = {
  editFiles: PropTypes.array,
  section: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setMenuFiles: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  required: PropTypes.bool
};

MultipleFileUpload.defaultProps = {
  editFiles: null,
  section: "user",
  disabled: false,
  setMenuFiles: () => {},
  fieldName: "files",
  errors: {},
  register: () => ({}),
  required: false
};

export default MultipleFileUpload;