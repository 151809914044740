/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import TableViewPopup from "../TableViewPopup/TableViewPopup";
import ViewVendorPopup from "../../pages/vendors/viewVendorPopup";
import ViewFeedbackPopup from "../../pages/feedback/viewFeedbackPopup";
import ViewPayoutPopup from "../../pages/payoutRequests/viewPayoutReqPopup";
import ViewDrinkTypePopup from "../../pages/drinktypes/viewDrinkTypePopup";
import ViewEnquiryPopup from "../../pages/contactus/viewContactusPopup";
import ViewCafePopup from "../../pages/cafes/viewCafePopup";
import styles from "./ActionButtons.module.scss";
import useToggle from "../../hooks/useToggle";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";
import { getCookies } from "../../hooks/useCookies";
import EditUserPopup from "../../pages/users/editUserPopUp";
import EditAdminUserPopup from "../../pages/adminusers/editUserPopUp";
import EditVendorPopup from "../../pages/vendors/editUserPopUp";
import EditCafePopup from "../../pages/cafes/editCafePopUp";
import EditDrinkTypePopup from "../../pages/drinktypes/editDrinktypePopup";
import MenuEdit from "../../pages/menus/editMenuPopUp";
import ViewMenuPopup from "../../pages/menus/viewMenuPopup";
import ViewClaimRequestPopup from "../../pages/claimRequests/viewClaimReqPopup";
import ClaimReqDetailsPopup from "../../pages/claimRequests/ClaimReqDetailsPopup";
import EditCouponPopUp from "../../pages/coupons/editCouponPopUp";
import ViewIssuePopup from "../../pages/issues/viewIssuePopup";

function ActionButtons({
  data,
  section,
  claimCafeId,
  viewPopUp,
  deleteCondition,
  setTableData,
  setIsUpdated,
  edit = false,
}) {
  const { actionbtnStyle } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const [canDelete, setCanDelete] = useState(true);
  const { setDeletedData } = useContext(TableContext);
  const [showTablePopUp, setShowTablePopUp] = useToggle();
  const { dashUpdate, setDashUpdate } = useContext(TableContext);

  // Track whether "view" or "edit" is selected
  const [action, setAction] = useState("view");

  // Function for "view" action
  const actionView = (param) => (event) => {
    event.preventDefault();
    setAction("view");
    const encodeText = param.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      navigate(`/${section}/viewdetails/${encodeText}`);
    }
  };

  // Function for "edit" action
  const actionEdit = (data) => {
    setAction("edit"); // Set action to "edit"
    const encodeText = data.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      navigate(`/${section}/editdetails/${encodeText}`);
    }
  };

  // Handle delete action
  const handleDelete = (params) => {
    const name = data.name ? data.name : data.type;
    const drinkNames = data.drinkNames;
    const deleteMessage =
      section === "menus"
        ? "Menu deleted successfully."
        : section === "vendor"
        ? "Vendor deleted successfully."
        : section === "user"
        ? "User deleted successfully."
        : section === "cafe"
        ? "Cafe deleted successfully."
        : section === "claimrequests"
        ? "Claim request deleted successfully."
        : section === "feedback"
        ? "Feedback deleted successfully."
        : section === "payoutrequests"
        ? "Payout request deleted successfully."
        : section === "drinktypes"
        ? "Drink type deleted successfully."
        : section === "coupons"
        ? "Coupon deleted successfully."
        : section === "enquiries"
        ? "Enquiry deleted successfully."
        : "Item deleted successfully.";

    let message = `Are you sure you want to delete ${name}?`;
    let drinkList;
    if (data.totalDrinks > 0) {
      drinkList =
        drinkNames.length > 0
          ? drinkNames.slice(0, 3).join(", ") +
            (data.totalDrinks > 3 ? ", and more" : "")
          : "no drink names available";
    }

    confirmAlert({
      title: canDelete ? "" : "Oops!!",
      message: (
        <div>
          {data.totalDrinks > 0 && (
            <p>There are {data.totalDrinks} drink(s) using this drink type.</p>
          )}
          {drinkList && <p>Drinks associated: {drinkList}.</p>}
          <p>Are you sure you want to delete {name}?</p>
        </div>
      ),
      buttons: canDelete
        ? [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                const deleteId = data._id ?? data.id;
                actionData.actionUrl = `${section}/${deleteId}`;
                actionData.actionMethod = "delete";
                dispatch(Entry(actionData)).then((resp) => {
                  if (resp.payload.code === 400) {
                    confirmAlert({
                      title: "Oops! Cannot Delete!",
                      message: resp.payload.message,
                      buttons: [{ label: "Ok" }],
                    });
                  } else {
                    toast.success(deleteMessage);
                    setDeletedData(`data last deleted is ${deleteId}`);
                    // setTableData((prevData) =>
                    //   prevData.filter((item) => item.id !== deleteId)
                    // );
                    if (!dashUpdate) {
                      setDashUpdate(true);
                    }
                    setIsUpdated(true);
                  }
                });
              },
            },
            {
              label: "No",
            },
          ]
        : [{ label: "Ok" }],
    });
  };

  // Check user permissions and delete condition
  useEffect(() => {
    const temp = section.replace(/\s+/g, "").toLowerCase();
    const permissions = getCookies("USERPERMISSION");
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
        });
      }
    });
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage("Sorry you cant delete this data");
    }
  }, []);

  console.log("Action permissions--", actionPermissions);

  return (
    <>
      <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {/* {actionPermissions.view &&
          section !== "user" &&
          section !== "adminuser" &&
          section !== "vendor" &&
          section !== "drinktypes" &&
          section !== "cafe" &&
          section !== "menus" &&
          section !== "claimrequests" &&
          section !== "coupons" && (
            <button
              className={`${styles[actionbtnStyle]} btn btn-sm`}
              id={styles.actionbtn}
              type="button"
              onClick={actionView(data)}
            >
              {" "}
              <i className="fa fa-eye" style={{ marginRight: "5px" }} />
              View
            </button>
          )} */}

        {section !== "payoutaccounts" &&
          (actionPermissions.edit || actionPermissions.view) && (
            <>
              {section !== "claimrequests" &&
              actionPermissions.edit &&
              actionPermissions.view ? (
              //  ||
              //   (section === "menus" && edit)
                <button
                  className={`${styles[actionbtnStyle]} btn btn-sm`}
                  id={styles.actionbtn}
                  type="button"
                  onClick={() => actionEdit(data)}
                >
                  <i className="fa fa-eye" style={{ marginRight: "5px" }} />
                  View/Edit
                </button>
              ) : (
                (actionPermissions.view || section === "claimrequests") && (
                  <button
                    className={`${styles[actionbtnStyle]} btn btn-sm`}
                    id={styles.actionbtn}
                    type="button"
                    onClick={() => actionEdit(data)}
                  >
                    <i className="fa fa-eye" style={{ marginRight: "5px" }} />
                    View
                  </button>
                )
              )}
            </>
          )}

        {section === "menus" && edit ? (
          <button
            className={`${styles[actionbtnStyle]} btn btn-sm`}
            id={styles.actionbtn}
            type="button"
            onClick={() => actionEdit(data)}
          >
            <i className="fa fa-eye" style={{ marginRight: "5px" }} />
            View/Edit
          </button>
        ) : (
          section === "menus" && (
            <button
              className={`${styles[actionbtnStyle]} btn btn-sm`}
              id={styles.actionbtn}
              type="button"
              onClick={() => actionEdit(data)}
            >
              <i className="fa fa-eye" style={{ marginRight: "5px" }} />
              View
            </button>
          )
        )}

        {actionPermissions.delete && (
          <button
            className={`${styles[actionbtnStyle]} btn btn-danger btn-sm`}
            id={styles.actionbtn1}
            type="button"
            onClick={() => {
              handleDelete(data);
            }}
          >
            {" "}
            <i className="fa fa-trash" />
          </button>
        )}
      </div>

      {/* Conditional Popup based on action and section */}
      {showTablePopUp &&
        (section === "user" ? (
          action === "edit" && actionPermissions?.edit ? (
            <EditUserPopup
              userId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            actionPermissions?.view && (
              <EditUserPopup
                userId={data.id}
                handleClose={setShowTablePopUp}
                setIsUpdated={setIsUpdated}
                edit={false}
              />
            )
          )
        ) : section === "vendor" ? (
          action === "edit" && actionPermissions?.edit ? (
            <EditVendorPopup
              vendorId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            actionPermissions?.view && (
              <EditVendorPopup
                vendorId={data.id}
                handleClose={setShowTablePopUp}
                setIsUpdated={setIsUpdated}
                edit={false}
              />
            )
          )
        ) : section === "adminuser" ? (
          action === "edit" && actionPermissions?.edit ? (
            <EditAdminUserPopup
              userId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            actionPermissions?.view && (
              <EditAdminUserPopup
                userId={data.id}
                handleClose={setShowTablePopUp}
                setIsUpdated={setIsUpdated}
                edit={false}
              />
            )
          )
        ) : section === "cafe" ? (
          action === "edit" && actionPermissions?.edit ? (
            <EditCafePopup
              setTableData={setTableData}
              cafeId={data._id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            actionPermissions?.view && (
              <EditCafePopup
                setTableData={setTableData}
                cafeId={data._id}
                handleClose={setShowTablePopUp}
                setIsUpdated={setIsUpdated}
                edit={false}
              />
            )
          )
        ) : section === "drinktypes" ? (
          action === "edit" && actionPermissions?.edit ? (
            <EditDrinkTypePopup
              drinkTypeId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            <EditDrinkTypePopup
              drinkTypeId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
              edit={false}
            />
          )
        ) : section === "coupons" && actionPermissions?.edit ? (
          <EditCouponPopUp
            data={data}
            couponId={data.id}
            handleClose={setShowTablePopUp}
            setIsUpdated={setIsUpdated}
          />
        ) : section === "menus" ? ( // Add Menu section here
          action === "edit" && edit ? (
            <MenuEdit
              menuId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
            />
          ) : (
            <MenuEdit
              menuId={data.id}
              handleClose={setShowTablePopUp}
              setIsUpdated={setIsUpdated}
              edit={false}
            />
          )
        ) : section === "feedback" && actionPermissions?.view ? (
          <ViewFeedbackPopup
            feedbackId={data.id}
            handleClose={setShowTablePopUp}
          />
        ) : section === "payouts" && actionPermissions?.view ? (
          <ViewPayoutPopup
            payoutId={data.id}
            handleClose={setShowTablePopUp}
            setIsUpdated={setIsUpdated}
          />
        ) : section === "enquiries" && actionPermissions?.view ? (
          <ViewEnquiryPopup
            contactUsId={data.id}
            handleClose={setShowTablePopUp}
          />
        ) : section === "claimrequests" && actionPermissions?.view ? (
          <ViewClaimRequestPopup
            claimRequestId={data.id}
            handleClose={setShowTablePopUp}
            setIsUpdated={setIsUpdated}
            permission={actionPermissions.edit}
          />
        ) : section === "claimrequestdetails" && actionPermissions?.view ? (
          <ClaimReqDetailsPopup
            data={data}
            claimCafeId={claimCafeId}
            handleClose={setShowTablePopUp}
            setIsUpdated={setIsUpdated}
          />
        ) : section === "issues" && actionPermissions?.view ? (
          <ViewIssuePopup
            data={data}
            issueId={data?.id || data?._id}
            handleClose={setShowTablePopUp}
            setIsUpdated={setIsUpdated}
          />
        ) : (
          <TableViewPopup
            dataId={data.id}
            handleClose={setShowTablePopUp}
            section={section}
          />
        ))}
    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
};

ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: "none" },
};

export default ActionButtons;
