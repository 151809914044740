/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import styles from "./createDrinkPopup.module.scss";  
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { useNavigate } from "react-router-dom";
import { Label } from "reactstrap";

const CreateDrinkPopup = React.memo(({ handleClose ,setIsUpdated }) => {

  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    // setDashboardHeader('Add Drink Details');

  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    
    setSubmitting(true);
    setError(null);
    if (!files) { 
      setSubmitting(false);
      setError('Please upload an image');
      return
    }
    const apiData = {
      type: data.type,
      taxcode: data.taxcode? data.taxcode : null,
      File: files,
    };
     
    data.actionUrl = 'drinktypes';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        // navigate(-1);
        toast.success('Drink Type creation successful');
        handleClose();
        setIsUpdated(true);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError("something went wrong!");
      } else {
        setError('Something went wrong!');
      }
    });
  }

  return (
    <div className={styles.popupbox}>
      <div className={styles.box} id={styles.box}>
        <button
          className={styles.closeicon}
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.heading}>Create Drink Type</h2>
        <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Drink Type"
                    fieldName="type"
                    placeHolder="Enter Drink Type"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
              </div>
              <div className="col-md-6 col-sm-6">
                  <TextInput
                    className={styles.inputbox}
                    label="Tax Code"
                    fieldName="taxcode"
                    placeHolder="Enter Tax Code"
                    register={register}
                    errors={errors}
                    // isRequired
                    // mandatory
                    maxLength="50"
                    defaultValue={" "}
                  />
                </div>
            
              </div>
            
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultipleFileUpload section={"drinktypes"} required ={true} fieldName="files" register={register} errors={errors}/>
                </div>
              </div>
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
              
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
      </div>
    </div>
  );
});

export default CreateDrinkPopup;
